var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container factoryconfirm" },
    [
      _c("div", { staticClass: "actions_part clearfix" }, [
        _c("div", { staticClass: "actions_wrap" }, [
          _c(
            "div",
            { staticClass: "display_ib mr10 mb10" },
            [
              _c("span", { staticClass: "tag" }, [_vm._v("订单编号：")]),
              _c("el-input", {
                staticClass: "input_single w200 mr10",
                attrs: { placeholder: "请输入" },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.searchHandle($event)
                  }
                },
                model: {
                  value: _vm.materialsCode,
                  callback: function($$v) {
                    _vm.materialsCode = $$v
                  },
                  expression: "materialsCode"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "display_ib mr10 mb10" },
            [
              _c("span", { staticClass: "tag" }, [_vm._v("物料编号：")]),
              _c("el-input", {
                staticClass: "input_single w200 mr10",
                attrs: { placeholder: "请输入" },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.searchHandle($event)
                  }
                },
                model: {
                  value: _vm.materialCode,
                  callback: function($$v) {
                    _vm.materialCode = $$v
                  },
                  expression: "materialCode"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "display_ib mr10 mb10" },
            [
              _c("span", { staticClass: "tag" }, [_vm._v("采购订单类型：")]),
              _c(
                "el-select",
                {
                  staticClass: "select_single w200 mr10",
                  attrs: { placeholder: "请选择" },
                  on: { change: _vm.searchHandle },
                  model: {
                    value: _vm.purchaseOrderType,
                    callback: function($$v) {
                      _vm.purchaseOrderType = $$v
                    },
                    expression: "purchaseOrderType"
                  }
                },
                [
                  _c("el-option", {
                    key: "",
                    attrs: { label: "全部", value: "" }
                  }),
                  _vm._l(_vm.orderTypeList, function(item) {
                    return _c("el-option", {
                      key: item.index,
                      attrs: { label: item.name, value: item.index }
                    })
                  })
                ],
                2
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "display_ib mr10 mb10" },
            [
              _c("span", { staticClass: "tag" }, [_vm._v("供应商：")]),
              _c(
                "el-select",
                {
                  staticClass: "select_single w200 mr10",
                  attrs: { filterable: "", placeholder: "请选择" },
                  on: { change: _vm.searchHandle },
                  model: {
                    value: _vm.supplierCode,
                    callback: function($$v) {
                      _vm.supplierCode = $$v
                    },
                    expression: "supplierCode"
                  }
                },
                [
                  _c("el-option", {
                    key: "",
                    attrs: { label: "全部", value: "" }
                  }),
                  _vm._l(_vm.supplierList, function(item) {
                    return _c("el-option", {
                      key: item.supplierId,
                      attrs: {
                        label: item.supplierCode,
                        value: item.supplierId
                      }
                    })
                  })
                ],
                2
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "display_ib mr10 mb10" },
            [
              _c("span", { staticClass: "tag" }, [_vm._v("确认状态：")]),
              _c(
                "el-select",
                {
                  staticClass: "select_single w200 mr10",
                  attrs: { placeholder: "请选择" },
                  on: { change: _vm.searchHandle },
                  model: {
                    value: _vm.orderConfirmCode,
                    callback: function($$v) {
                      _vm.orderConfirmCode = $$v
                    },
                    expression: "orderConfirmCode"
                  }
                },
                [
                  _c("el-option", {
                    key: "",
                    attrs: { label: "全部", value: "" }
                  }),
                  _vm._l(_vm.orderConfirmStatuslist, function(item) {
                    return _c("el-option", {
                      key: item.index,
                      attrs: { label: item.name, value: item.index }
                    })
                  })
                ],
                2
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "display_ib mr10 mb10" },
            [
              _c("span", { staticClass: "tag" }, [_vm._v("创建日期：")]),
              _c("el-date-picker", {
                attrs: {
                  "unlink-panels": "",
                  type: "daterange",
                  format: "yyyy-MM-dd",
                  "value-format": "yyyy-MM-dd",
                  "range-separator": "至",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期"
                },
                on: { change: _vm.dateValChange },
                model: {
                  value: _vm.dateVal,
                  callback: function($$v) {
                    _vm.dateVal = $$v
                  },
                  expression: "dateVal"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "display_ib mr10 mb10" },
            [
              _c("span", { staticClass: "tag" }, [_vm._v("申请人：")]),
              _c("el-input", {
                staticClass: "input_single w200 mr10",
                attrs: { placeholder: "请输入" },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.searchHandle($event)
                  }
                },
                model: {
                  value: _vm.applicantName,
                  callback: function($$v) {
                    _vm.applicantName = $$v
                  },
                  expression: "applicantName"
                }
              })
            ],
            1
          )
        ]),
        _c(
          "div",
          { staticClass: "actions_btn_wrap down t_right" },
          [
            _c("el-button", {
              attrs: {
                size: "small",
                type: "primary",
                icon: "el-icon-search",
                circle: "",
                title: "搜索"
              },
              on: { click: _vm.searchHandle },
              model: {
                value: _vm.productcode,
                callback: function($$v) {
                  _vm.productcode = $$v
                },
                expression: "productcode"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "table_part clearfix" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.listLoading,
                expression: "listLoading"
              }
            ],
            staticClass: "single_tab"
          },
          [
            _c(
              "el-table",
              {
                ref: "multipleTable",
                staticClass: "table_list",
                attrs: {
                  data: _vm.requestList,
                  "expand-row-keys": _vm.expands,
                  "row-key": "id",
                  border: "",
                  fit: "",
                  stripe: ""
                },
                on: { "expand-change": _vm.rowClick, "row-click": _vm.rowClick }
              },
              [
                _c("el-table-column", {
                  attrs: { type: "expand" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c(
                            "pl-table",
                            {
                              ref: "childMultipleTable_" + scope.row.id,
                              staticClass: "table_list_child",
                              staticStyle: { width: "100%", height: "100%" },
                              attrs: {
                                data: scope.row.poiList,
                                "max-height": 700,
                                "use-virtual": true,
                                "element-loading-text": "拼命加载中",
                                border: "",
                                fit: ""
                              }
                            },
                            [
                              _c("el-table-column", {
                                attrs: { label: "PO行号" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var row = ref.row
                                        return [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(row.purchaseOrderSn) +
                                              "\n                "
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  "show-overflow-tooltip": true,
                                  label: "物料编号",
                                  "min-width": "150",
                                  "class-name": "marpno_wuliao"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var row = ref.row
                                        return [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(row.materialCode) +
                                              "\n                "
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              }),
                              _c("el-table-column", {
                                attrs: { label: "工厂季" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var row = ref.row
                                        return [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(row.factorySeason) +
                                              "\n                "
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              }),
                              _c("el-table-column", {
                                attrs: { label: "供应商" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var row = ref.row
                                        return [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(row.supplierShortName) +
                                              "\n                "
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              }),
                              _c("el-table-column", {
                                attrs: { label: "工厂确认数量" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var row = ref.row
                                        return [
                                          row.purchaseMeasurementUnit == "米"
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    (row.confirmPackages
                                                      ? row.confirmPackages
                                                      : 0) +
                                                      "包 " +
                                                      (row.confirmQuantityPerPackage
                                                        ? row.confirmQuantityPerPackage
                                                        : 0) +
                                                      "米/包 " +
                                                      (row.confirmQuantity
                                                        ? row.confirmQuantity
                                                        : 0) +
                                                      "米"
                                                  )
                                                )
                                              ])
                                            : _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    (row.confirmQuantity
                                                      ? row.confirmQuantity
                                                      : 0) +
                                                      (row.purchaseMeasurementUnit
                                                        ? row.purchaseMeasurementUnit
                                                        : "条")
                                                  )
                                                )
                                              ])
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              }),
                              _c("el-table-column", {
                                attrs: { label: "确认书号" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var row = ref.row
                                        return [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(row.confirmNumber) +
                                              "\n                "
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              }),
                              _c("el-table-column", {
                                attrs: { label: "采购订单数量" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var row = ref.row
                                        return [
                                          row.purchaseMeasurementUnit == "米"
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    (row.offeredPackages
                                                      ? row.offeredPackages
                                                      : 0) +
                                                      "包 " +
                                                      (row.offeredQuantityPerPackage
                                                        ? row.offeredQuantityPerPackage
                                                        : 0) +
                                                      "米/包 " +
                                                      (row.offeredQuantity
                                                        ? row.offeredQuantity
                                                        : 0) +
                                                      "米"
                                                  )
                                                )
                                              ])
                                            : _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    (row.offeredQuantity
                                                      ? row.offeredQuantity
                                                      : 0) +
                                                      (row.purchaseMeasurementUnit
                                                        ? row.purchaseMeasurementUnit
                                                        : "条")
                                                  )
                                                )
                                              ])
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              }),
                              _c("el-table-column", {
                                attrs: { label: "工厂交期" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var row = ref.row
                                        return [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(row.offeredDate) +
                                              "\n                "
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              }),
                              _c("el-table-column", {
                                attrs: { label: "投产日期" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var row = ref.row
                                        return [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(row.confirmTime) +
                                              "\n                "
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              }),
                              _c("el-table-column", {
                                attrs: { label: "折扣信息" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var row = ref.row
                                        return [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(row.discountInfo) +
                                              "\n                "
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              }),
                              _c("el-table-column", {
                                attrs: { label: "采购备注" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var row = ref.row
                                        return [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(row.purchaseRemark) +
                                              "\n                "
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              }),
                              _c("el-table-column", {
                                attrs: { label: "操作", width: "90" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var row = ref.row
                                        return [
                                          _c("el-button", {
                                            staticClass: "el-icon-plus",
                                            attrs: {
                                              type: "primary",
                                              circle: "",
                                              size: "mini",
                                              title: "新增子项"
                                            },
                                            on: {
                                              click: function($event) {
                                                $event.stopPropagation()
                                                return _vm.addHandle(
                                                  _vm.$index,
                                                  row,
                                                  scope.row
                                                )
                                              }
                                            }
                                          })
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              })
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: { label: "订单编号" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        return [
                          _vm._v(
                            "\n            " +
                              _vm._s(row.purchaseOrderId) +
                              "\n          "
                          )
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: { label: "采购订单类型" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        return [
                          _vm._v(
                            "\n            " +
                              _vm._s(row.purchaseOrderTypeName) +
                              "\n          "
                          )
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: { label: "创建时间" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        return [
                          _vm._v(
                            "\n            " +
                              _vm._s(row.createTime) +
                              "\n          "
                          )
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: { label: "申请人" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        return [
                          _vm._v(
                            "\n            " +
                              _vm._s(row.applicantName) +
                              "\n          "
                          )
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: { label: "确认状态" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        return [
                          _vm._v(
                            "\n            " +
                              _vm._s(row.confirmStatusShow) +
                              "\n          "
                          )
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: { label: "操作" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        var $index = ref.$index
                        return [
                          _c(
                            "div",
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    size: "mini",
                                    title: "新增"
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return _vm.addfactirycode($index, row)
                                    }
                                  }
                                },
                                [_vm._v("整单确认")]
                              )
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ])
                })
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "pagination_wrap fr" },
          [
            _c("el-pagination", {
              attrs: {
                "current-page": _vm.currentPage,
                "page-sizes": [10, 50],
                "page-size": _vm.pageSize,
                total: _vm.allpage,
                layout: "sizes, total, prev, pager, next"
              },
              on: {
                "update:currentPage": function($event) {
                  _vm.currentPage = $event
                },
                "update:current-page": function($event) {
                  _vm.currentPage = $event
                },
                "size-change": _vm.handleSizeChange,
                "current-change": _vm.handleCurrentChange
              }
            })
          ],
          1
        )
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.dialogTitle,
            visible: _vm.dialogVisible,
            "close-on-click-modal": false,
            width: "83%"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            },
            close: _vm.closeDialog
          }
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.ifshow,
                  expression: "ifshow"
                }
              ],
              staticClass: "dialogtable"
            },
            [
              _c(
                "table",
                {
                  staticClass: "contenttab",
                  attrs: { border: "0", cellspacing: "0", cellpadding: "0" }
                },
                [
                  _c("thead", [
                    _c("th", [_vm._v("物料编号")]),
                    _c("th", [_vm._v("供应商")]),
                    _c("th", [_vm._v("确认书编号")]),
                    _c("th", [_vm._v("工厂确认数量")]),
                    _c("th", [_vm._v("投产日期")]),
                    _c("th", [_vm._v("工厂季")]),
                    _c("th", [_vm._v("折扣信息")]),
                    _c("th", [_vm._v("工厂交期")]),
                    _c("th")
                  ]),
                  _c(
                    "tbody",
                    _vm._l(_vm.tablelist, function(item, index) {
                      return _c("tr", { key: index }, [
                        _c("td", [
                          _c("span", [_vm._v(_vm._s(item.materialCode))])
                        ]),
                        _c("td", [_vm._v(_vm._s(item.supplierShortName))]),
                        _c("td", [
                          item.flag == false
                            ? _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: item.confirmNumber,
                                    expression: "item.confirmNumber"
                                  }
                                ],
                                staticClass: "sure",
                                attrs: { type: "text" },
                                domProps: { value: item.confirmNumber },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      item,
                                      "confirmNumber",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            : _c("span", [_vm._v(_vm._s(item.confirmNumber))])
                        ]),
                        _c("td", [
                          item.flag == false
                            ? _c("div", { staticClass: "inputtext" }, [
                                _c(
                                  "div",
                                  { attrs: { id: "" } },
                                  [
                                    _c("el-input-number", {
                                      attrs: {
                                        min: 1,
                                        max: 100,
                                        size: "mini",
                                        "controls-position": "right"
                                      },
                                      model: {
                                        value: item.confirmPackages,
                                        callback: function($$v) {
                                          _vm.$set(
                                            item,
                                            "confirmPackages",
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression: "item.confirmPackages"
                                      }
                                    }),
                                    _c("span", [_vm._v("包")])
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  [
                                    _c("el-input-number", {
                                      attrs: {
                                        precision: 2,
                                        min: 1,
                                        max: 100,
                                        size: "mini",
                                        "controls-position": "right"
                                      },
                                      model: {
                                        value: item.confirmQuantityPerPackage,
                                        callback: function($$v) {
                                          _vm.$set(
                                            item,
                                            "confirmQuantityPerPackage",
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression:
                                          "item.confirmQuantityPerPackage"
                                      }
                                    }),
                                    _c("span", [_vm._v("米/包")])
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  [
                                    _c("el-input-number", {
                                      attrs: {
                                        precision: 2,
                                        min: 1,
                                        max: 100,
                                        size: "mini",
                                        "controls-position": "right"
                                      },
                                      model: {
                                        value: item.confirmQuantity,
                                        callback: function($$v) {
                                          _vm.$set(
                                            item,
                                            "confirmQuantity",
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression: "item.confirmQuantity"
                                      }
                                    }),
                                    _c("span", [_vm._v("米")])
                                  ],
                                  1
                                )
                              ])
                            : _c("span", [
                                item.purchaseMeasurementUnit == "米"
                                  ? _c("div", { attrs: { id: "" } }, [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            item.confirmPackages
                                              ? item.confirmPackages
                                              : "0"
                                          ) +
                                          "包\n                  " +
                                          _vm._s(
                                            item.confirmQuantityPerPackage
                                              ? item.confirmQuantityPerPackage
                                              : "0"
                                          ) +
                                          "米/包\n                  " +
                                          _vm._s(
                                            item.confirmQuantity
                                              ? item.confirmQuantity
                                              : "0"
                                          ) +
                                          "米\n                "
                                      )
                                    ])
                                  : _c("div", [
                                      item.purchaseMeasurementUnit != null
                                        ? _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                item.confirmQuantity
                                                  ? item.confirmQuantity
                                                  : "0"
                                              ) +
                                                _vm._s(
                                                  item.purchaseMeasurementUnit
                                                )
                                            )
                                          ])
                                        : _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                item.confirmQuantity
                                                  ? item.confirmQuantity
                                                  : "0"
                                              ) + "条"
                                            )
                                          ])
                                    ])
                              ])
                        ]),
                        _c("td", [
                          item.flag == false
                            ? _c(
                                "div",
                                { staticClass: "time" },
                                [
                                  _c("el-date-picker", {
                                    attrs: {
                                      title: item.confirmTime,
                                      "picker-options": _vm.pickerOptions0,
                                      "unlink-panels": "",
                                      type: "date",
                                      format: "yyyy-MM-dd",
                                      "value-format": "yyyy-MM-dd",
                                      placeholder: "选择日期"
                                    },
                                    model: {
                                      value: item.confirmTime,
                                      callback: function($$v) {
                                        _vm.$set(item, "confirmTime", $$v)
                                      },
                                      expression: "item.confirmTime"
                                    }
                                  })
                                ],
                                1
                              )
                            : _c("span", [_vm._v(_vm._s(item.confirmTime))])
                        ]),
                        _c("td", [_vm._v(_vm._s(item.factorySeason))]),
                        _c("td", [_vm._v(_vm._s(item.discountInfo))]),
                        _c("td", [_vm._v(_vm._s(item.offeredDate))]),
                        _c("td", [
                          _c("i", {
                            staticClass: "el-icon-delete",
                            attrs: { type: "primary", title: "删除" },
                            on: {
                              click: function($event) {
                                return _vm.tabledel(item, index)
                              }
                            }
                          })
                        ])
                      ])
                    }),
                    0
                  )
                ]
              ),
              _vm.tablelist == ""
                ? _c(
                    "p",
                    {
                      staticStyle: { color: "#909399", "text-align": "center" }
                    },
                    [_vm._v("暂无数据")]
                  )
                : _vm._e()
            ]
          ),
          _c("div", { staticClass: "dialogadd" }, [
            _c("div", { staticClass: "dialog_title" }, [
              _c(
                "div",
                { staticClass: "button" },
                [
                  _c("el-button", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.ifshow,
                        expression: "ifshow"
                      }
                    ],
                    attrs: {
                      size: "small",
                      type: "primary",
                      icon: "el-icon-plus",
                      circle: "",
                      title: "添加子项"
                    },
                    on: {
                      click: function($event) {
                        return _vm.addchildmsg()
                      }
                    }
                  })
                ],
                1
              ),
              _c("span", [_vm._v("新增确认书内容")])
            ]),
            _c("div", { staticClass: "dialog_content" }, [
              _c(
                "table",
                {
                  staticClass: "contenttab",
                  attrs: { border: "0", cellspacing: "0", cellpadding: "0" }
                },
                [
                  _c("thead", [
                    _c(
                      "th",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.ifshow,
                            expression: "ifshow"
                          }
                        ]
                      },
                      [_vm._v("* 工厂确认数量")]
                    ),
                    _c("th", [_vm._v("* 投产日期")]),
                    _c("th", [
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.ifshow,
                              expression: "ifshow"
                            }
                          ]
                        },
                        [_vm._v("* ")]
                      ),
                      _vm._v("工厂交期")
                    ]),
                    _c("th", [_vm._v("* 确认书编号")]),
                    _c("th", [_c("span", [_vm._v("* ")]), _vm._v("工厂季")]),
                    _c("th", [_vm._v("折扣信息")]),
                    _c(
                      "th",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.ifshow,
                            expression: "ifshow"
                          }
                        ]
                      },
                      [_vm._v("操作")]
                    )
                  ]),
                  _c(
                    "tbody",
                    _vm._l(_vm.form, function(item, index) {
                      return _c("tr", { key: index }, [
                        _c(
                          "td",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.ifshow,
                                expression: "ifshow"
                              }
                            ]
                          },
                          [
                            item.purchaseMeasurementUnit == "米"
                              ? _c("div", { staticClass: "inputtext" }, [
                                  _c(
                                    "div",
                                    { attrs: { id: "" } },
                                    [
                                      _c("el-input-number", {
                                        attrs: {
                                          min: 1,
                                          size: "mini",
                                          "controls-position": "right",
                                          "step-strictly": ""
                                        },
                                        on: { change: _vm.handleChange },
                                        model: {
                                          value: item.confirmPackages,
                                          callback: function($$v) {
                                            _vm.$set(
                                              item,
                                              "confirmPackages",
                                              typeof $$v === "string"
                                                ? $$v.trim()
                                                : $$v
                                            )
                                          },
                                          expression: "item.confirmPackages"
                                        }
                                      }),
                                      _c("span", [_vm._v("包")])
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    [
                                      _c("el-input-number", {
                                        attrs: {
                                          precision: 2,
                                          min: 1,
                                          size: "mini",
                                          "controls-position": "right"
                                        },
                                        on: { change: _vm.handleChange },
                                        model: {
                                          value: item.confirmQuantityPerPackage,
                                          callback: function($$v) {
                                            _vm.$set(
                                              item,
                                              "confirmQuantityPerPackage",
                                              typeof $$v === "string"
                                                ? $$v.trim()
                                                : $$v
                                            )
                                          },
                                          expression:
                                            "item.confirmQuantityPerPackage"
                                        }
                                      }),
                                      _c("span", [_vm._v("米/包")])
                                    ],
                                    1
                                  ),
                                  _c("div", [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          display: "inline-block",
                                          "margin-top": "5px"
                                        },
                                        attrs: {
                                          title:
                                            item.confirmPackages *
                                            item.confirmQuantityPerPackage
                                              ? item.confirmPackages *
                                                item.confirmQuantityPerPackage
                                              : 0,
                                          value: (item.confirmQuantity =
                                            item.confirmPackages *
                                            item.confirmQuantityPerPackage)
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            item.confirmPackages *
                                              item.confirmQuantityPerPackage
                                              ? item.confirmPackages *
                                                  item.confirmQuantityPerPackage
                                              : 0
                                          ) + "米"
                                        )
                                      ]
                                    )
                                  ])
                                ])
                              : _c(
                                  "div",
                                  { staticClass: "inputtext" },
                                  [
                                    _c("el-input-number", {
                                      attrs: {
                                        precision:
                                          item.purchaseMeasurementUnit ==
                                            "米" ||
                                          item.purchaseMeasurementUnit == "公斤"
                                            ? 2
                                            : 0,
                                        min: 1,
                                        size: "mini",
                                        "controls-position": "right"
                                      },
                                      on: { change: _vm.handleChange },
                                      model: {
                                        value: item.confirmQuantity,
                                        callback: function($$v) {
                                          _vm.$set(
                                            item,
                                            "confirmQuantity",
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression: "item.confirmQuantity"
                                      }
                                    }),
                                    item.purchaseMeasurementUnit != null
                                      ? _c(
                                          "span",
                                          { staticClass: "pt10 inline" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                item.purchaseMeasurementUnit
                                              )
                                            )
                                          ]
                                        )
                                      : _c(
                                          "span",
                                          { staticClass: "pt10 inline" },
                                          [_vm._v("条")]
                                        )
                                  ],
                                  1
                                )
                          ]
                        ),
                        _c("td", [
                          _c(
                            "div",
                            { staticClass: "time" },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  title: item.confirmTime,
                                  "unlink-panels": "",
                                  type: "date",
                                  format: "yyyy-MM-dd",
                                  "value-format": "yyyy-MM-dd",
                                  placeholder: "选择日期"
                                },
                                model: {
                                  value: item.confirmTime,
                                  callback: function($$v) {
                                    _vm.$set(item, "confirmTime", $$v)
                                  },
                                  expression: "item.confirmTime"
                                }
                              })
                            ],
                            1
                          )
                        ]),
                        _c("td", [
                          _c(
                            "div",
                            { staticClass: "time" },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  title: item.offeredDate,
                                  "unlink-panels": "",
                                  type: "date",
                                  format: "yyyy-MM-dd",
                                  "value-format": "yyyy-MM-dd",
                                  placeholder: "选择日期"
                                },
                                model: {
                                  value: item.offeredDate,
                                  callback: function($$v) {
                                    _vm.$set(item, "offeredDate", $$v)
                                  },
                                  expression: "item.offeredDate"
                                }
                              })
                            ],
                            1
                          )
                        ]),
                        _c(
                          "td",
                          [
                            _c("el-input", {
                              staticClass: "input_single w150 mr10",
                              attrs: {
                                maxlength: "31",
                                placeholder: "请输入确认书号"
                              },
                              model: {
                                value: item.confirmNumber,
                                callback: function($$v) {
                                  _vm.$set(item, "confirmNumber", $$v)
                                },
                                expression: "item.confirmNumber"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "td",
                          [
                            _c(
                              "el-select",
                              {
                                staticClass: "select_single w150 mr10",
                                attrs: {
                                  disabled: item.ifFactorySeason,
                                  filterable: "",
                                  placeholder: "请选择请求类型"
                                },
                                model: {
                                  value: item.factorySeason,
                                  callback: function($$v) {
                                    _vm.$set(
                                      item,
                                      "factorySeason",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "item.factorySeason"
                                }
                              },
                              _vm._l(_vm.factorySeasonList, function(i) {
                                return _c("el-option", {
                                  key: i.name,
                                  attrs: { value: i.name, label: i.name }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "td",
                          [
                            _c("el-input", {
                              staticClass: "input_single w150 mr10",
                              attrs: {
                                maxlength: "60",
                                placeholder: "请输入折扣信息"
                              },
                              model: {
                                value: item.discountInfo,
                                callback: function($$v) {
                                  _vm.$set(item, "discountInfo", $$v)
                                },
                                expression: "item.discountInfo"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "td",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.ifshow,
                                expression: "ifshow"
                              }
                            ]
                          },
                          [
                            _c("el-button", {
                              attrs: {
                                type: "danger",
                                title: "删除",
                                icon: "el-icon-minus",
                                circle: ""
                              },
                              on: {
                                click: function($event) {
                                  return _vm.childdel(index, item)
                                }
                              }
                            })
                          ],
                          1
                        )
                      ])
                    }),
                    0
                  )
                ]
              )
            ]),
            _vm.form.length < 1
              ? _c(
                  "div",
                  {
                    staticClass: "formtable_null",
                    staticStyle: {
                      "text-align": "center",
                      "margin-top": "15px"
                    }
                  },
                  [
                    _c("img", {
                      staticStyle: { width: "200px", height: "100px" },
                      attrs: { src: require("../../../assets/image/add.png") }
                    }),
                    _c("p", [_vm._v("信息空空如也去添加")])
                  ]
                )
              : _vm._e()
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  directives: [
                    { name: "preventReClick", rawName: "v-preventReClick" }
                  ],
                  attrs: {
                    title: _vm.submittxt,
                    disabled: _vm.form == "",
                    type: "primary"
                  },
                  on: {
                    click: function($event) {
                      return _vm.draftsave()
                    }
                  }
                },
                [_vm._v("提交")]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: {
            visible: _vm.dialogdelVisible,
            "close-on-click-modal": false,
            title: "提示",
            width: "30%"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogdelVisible = $event
            }
          }
        },
        [
          _c("span", [_vm._v("确定删除该条确认书内容?")]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogdelVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    { name: "preventReClick", rawName: "v-preventReClick" }
                  ],
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.delVisibleclick()
                    }
                  }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: {
            visible: _vm.bulkChange,
            "close-on-click-modal": false,
            title: "提示",
            width: "30%"
          },
          on: {
            "update:visible": function($event) {
              _vm.bulkChange = $event
            }
          }
        },
        [
          _c("span", [
            _vm._v("该采购订单已经存在工厂确认信息，是否继续批量修改？")
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.bulkChange = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.confirmBulkChange()
                    }
                  }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "div",
        { staticClass: "import_popup_part" },
        [
          _c(
            "el-dialog",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.editPartLoading,
                  expression: "editPartLoading"
                },
                { name: "dialogDrag", rawName: "v-dialogDrag" }
              ],
              attrs: {
                "close-on-click-modal": false,
                "close-on-press-escape": false,
                visible: _vm.importDialogFormVisible,
                title: "导入货妥"
              },
              on: {
                "update:visible": function($event) {
                  _vm.importDialogFormVisible = $event
                }
              }
            },
            [
              _c("upload-excel-component", {
                attrs: {
                  "on-success": _vm.importExcelSuccess,
                  "before-upload": _vm.importExcelBeforeUpload
                }
              }),
              _c(
                "el-table",
                {
                  ref: "multipleTable",
                  staticClass: "table_list mt20",
                  attrs: {
                    data: _vm.cargoReadyTableData,
                    border: "",
                    fit: "",
                    stripe: ""
                  }
                },
                _vm._l(_vm.cargoReadyTableHeader, function(item) {
                  return _c("el-table-column", {
                    key: item,
                    attrs: { prop: item, label: item },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var row = ref.row
                            return [
                              _vm._v(
                                "\n            " +
                                  _vm._s(row[item]) +
                                  "\n          "
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  })
                }),
                1
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        { name: "preventReClick", rawName: "v-preventReClick" }
                      ],
                      attrs: {
                        disabled: _vm.exportCargoReadyDisabled,
                        type: "success"
                      },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.cargoReadyExcelExport($event)
                        }
                      }
                    },
                    [_vm._v("导出分析货妥")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "finalPaymentadd" }, [
    _c("p", { staticClass: "weight s18 textcolor" }, [
      _vm._v("尾款付款申请请求")
    ]),
    _c(
      "div",
      { staticClass: "addcontent" },
      [
        _c("span", { staticClass: "red mr5" }, [_vm._v("*")]),
        _c("span", { staticClass: "weight textcolor" }, [_vm._v("形式发票")]),
        _c(
          "el-upload",
          {
            staticClass: "upload-demo inline ml20",
            attrs: {
              action: _vm.AjaxUrl,
              headers: _vm.myHeaders,
              "show-file-list": false,
              "before-upload": _vm.beforeAvatarUpload,
              data: { type: "1", uuid: this.uuid },
              "on-change": _vm.handleChange,
              "on-success": _vm.handleAvatarSuccess
            }
          },
          [
            _c(
              "el-button",
              {
                staticClass: "btnclasfour btnclasthree",
                attrs: { slot: "trigger", size: "small" },
                slot: "trigger"
              },
              [
                _c("i", { staticClass: "el-icon-upload2" }),
                _vm._v("\n        上传文件\n      ")
              ]
            )
          ],
          1
        ),
        _c("span", { staticClass: "textcolor block mt10 s14" }, [
          _vm._v("支持扩展名:doc .docx .pdf .jpg .zip")
        ]),
        _c("span", { staticClass: "textcolor block mt10 s14" }, [
          _vm._v(
            "\n      已上传形式发票:\n      " +
              _vm._s(this.fileListname.realname) +
              "\n    "
          )
        ])
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "addcontent_content mt10" },
      [
        _vm._l(_vm.checkArr, function(item, index) {
          return _c("div", { key: index, staticClass: "finalAddlist mt20" }, [
            _c("ul", { staticClass: "weight textcolor s14 demo-form-inline" }, [
              _c("li", { staticClass: "w15p" }, [
                _vm._v("\n          采购订单：\n          "),
                _c(
                  "span",
                  { staticClass: "w70p ellipsis", attrs: { title: item.poId } },
                  [_vm._v(_vm._s(item.poId))]
                )
              ]),
              _c("li", { staticClass: "w15p" }, [
                _vm._v("\n          供应商：\n          "),
                _c(
                  "span",
                  {
                    staticClass: "w70p ellipsis",
                    attrs: { title: item.supplierShortName }
                  },
                  [_vm._v(_vm._s(item.supplierShortName))]
                )
              ]),
              _c("li", { staticClass: "w15p" }, [
                _vm._v("\n          调拨货值：\n          "),
                _c(
                  "span",
                  {
                    staticClass: "w70p ellipsis",
                    attrs: { title: item.allocationCargoValueTotal }
                  },
                  [_vm._v(_vm._s(item.allocationCargoValueTotal))]
                )
              ]),
              _c("li", { staticClass: "w15p" }, [
                _vm._v("\n          币种：\n          "),
                _c(
                  "span",
                  {
                    staticClass: "w70p ellipsis",
                    attrs: { title: item.currencyName }
                  },
                  [_vm._v(_vm._s(item.currencyName))]
                )
              ]),
              _c(
                "li",
                { staticClass: "w20p" },
                [
                  _c("span", { staticClass: "red mr5" }, [_vm._v("*")]),
                  _c("span", [_vm._v("付款比例")]),
                  _c("el-input", {
                    staticClass: "ml w100p",
                    attrs: { maxlength: "6", max: 100 },
                    on: {
                      change: function($event) {
                        return _vm.InputNumber("applyRatio")
                      }
                    },
                    nativeOn: {
                      keyup: function($event) {
                        return _vm.InputNumber("applyRatio")
                      }
                    },
                    model: {
                      value: item.applyRatio,
                      callback: function($$v) {
                        _vm.$set(
                          item,
                          "applyRatio",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "item.applyRatio"
                    }
                  }),
                  _c("span", { staticClass: "ml" }, [_vm._v("%")])
                ],
                1
              ),
              _c(
                "li",
                { staticClass: "w20p" },
                [
                  _c("span", [_vm._v("形式发票号")]),
                  _c("el-input", {
                    staticClass: "ml w100p",
                    attrs: { maxlength: "63" },
                    nativeOn: {
                      keyup: function($event) {
                        return _vm.invoiceInputNumber(item)
                      }
                    },
                    model: {
                      value: item.proformaInvoiceNum,
                      callback: function($$v) {
                        _vm.$set(
                          item,
                          "proformaInvoiceNum",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "item.proformaInvoiceNum"
                    }
                  })
                ],
                1
              ),
              _c(
                "li",
                { staticClass: "w16p" },
                [
                  _c(
                    "el-checkbox",
                    {
                      staticClass: "ml",
                      model: {
                        value: item.isFinalDelivery,
                        callback: function($$v) {
                          _vm.$set(item, "isFinalDelivery", $$v)
                        },
                        expression: "item.isFinalDelivery"
                      }
                    },
                    [_vm._v("最终发货")]
                  )
                ],
                1
              )
            ]),
            _c(
              "div",
              { staticClass: "hidden" },
              [
                _c("editTable", {
                  attrs: {
                    tableList: item.finalPayments,
                    tableLeaderFieldsList: _vm.leaderFieldsList,
                    tableTailFieldsList: _vm.tailFieldsList,
                    tableFieldsList: _vm.editFieldsList
                  },
                  on: {
                    deleteHandle: _vm.deleteEditItemHandle,
                    inputChange: _vm.inputChange,
                    datePickerChange: _vm.datePickerChange,
                    numberInputChange: _vm.numberInputChange
                  }
                })
              ],
              1
            )
          ])
        }),
        _c(
          "div",
          {
            staticClass: "dialog-footer",
            staticStyle: {
              float: "right",
              "margin-top": "45px",
              "margin-right": "20px"
            },
            attrs: { slot: "footer" },
            slot: "footer"
          },
          [
            _c(
              "el-button",
              {
                on: {
                  click: function($event) {
                    return _vm.back()
                  }
                }
              },
              [_vm._v("取消")]
            ),
            _c(
              "el-button",
              {
                directives: [
                  { name: "preventReClick", rawName: "v-preventReClick" }
                ],
                attrs: { type: "primary" },
                on: {
                  click: function($event) {
                    return _vm.draftsave("save")
                  }
                }
              },
              [_vm._v("保存")]
            ),
            _c(
              "el-button",
              {
                directives: [
                  { name: "preventReClick", rawName: "v-preventReClick" }
                ],
                attrs: { type: "primary" },
                on: {
                  click: function($event) {
                    return _vm.draftsave("commit")
                  }
                }
              },
              [_vm._v("提交审批")]
            )
          ],
          1
        )
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container finalPaymentRequest" },
    [
      _vm.ifaddsure == false
        ? _c("div", { staticClass: "commoncontent" }, [
            _c("div", { staticClass: "actions_part clearfix" }, [
              _c("div", { staticClass: "actions_wrap" }, [
                _c(
                  "div",
                  { staticClass: "display_ib mr10 mb10" },
                  [
                    _c("span", { staticClass: "tag" }, [
                      _vm._v("采购订单编号：")
                    ]),
                    _c("el-input", {
                      staticClass: "input_single w200 mr10",
                      attrs: { placeholder: "请输入" },
                      nativeOn: {
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.searchHandle($event)
                        }
                      },
                      model: {
                        value: _vm.purchaseOrderCode,
                        callback: function($$v) {
                          _vm.purchaseOrderCode = $$v
                        },
                        expression: "purchaseOrderCode"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "display_ib mr10 mb10" },
                  [
                    _c("span", { staticClass: "tag" }, [_vm._v("物料编号：")]),
                    _c("el-input", {
                      staticClass: "input_single w200 mr10",
                      attrs: { placeholder: "请输入" },
                      nativeOn: {
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.searchHandle($event)
                        }
                      },
                      model: {
                        value: _vm.materialCode,
                        callback: function($$v) {
                          _vm.materialCode = $$v
                        },
                        expression: "materialCode"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "display_ib mr10 mb10" },
                  [
                    _c("span", { staticClass: "tag" }, [_vm._v("供应商：")]),
                    _c(
                      "el-select",
                      {
                        staticClass: "select_single w200 mr10",
                        attrs: { filterable: "", placeholder: "请选择" },
                        on: { change: _vm.searchHandle },
                        model: {
                          value: _vm.supplierCode,
                          callback: function($$v) {
                            _vm.supplierCode = $$v
                          },
                          expression: "supplierCode"
                        }
                      },
                      [
                        _c("el-option", {
                          key: "",
                          attrs: { label: "全部", value: "" }
                        }),
                        _vm._l(_vm.supplierList, function(item) {
                          return _c("el-option", {
                            key: item.supplierId,
                            attrs: {
                              label: item.supplierCode,
                              value: item.supplierId
                            }
                          })
                        })
                      ],
                      2
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "display_ib mr10 mb10" },
                  [
                    _c("span", { staticClass: "tag" }, [_vm._v("付款状态：")]),
                    _c(
                      "el-select",
                      {
                        staticClass: "select_single w200 mr10",
                        attrs: { placeholder: "请选择" },
                        on: { change: _vm.searchHandle },
                        model: {
                          value: _vm.paidStatus,
                          callback: function($$v) {
                            _vm.paidStatus = $$v
                          },
                          expression: "paidStatus"
                        }
                      },
                      [
                        _c("el-option", {
                          key: "",
                          attrs: { label: "全部", value: "" }
                        }),
                        _vm._l(_vm.paymentList, function(item) {
                          return _c("el-option", {
                            key: item.index,
                            attrs: { label: item.name, value: item.index }
                          })
                        })
                      ],
                      2
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "display_ib mr10 mb10" },
                  [
                    _c("span", { staticClass: "tag" }, [_vm._v("创建日期：")]),
                    _c("el-date-picker", {
                      attrs: {
                        "unlink-panels": "",
                        type: "daterange",
                        format: "yyyy-MM-dd",
                        "value-format": "yyyy-MM-dd",
                        "range-separator": "至",
                        "start-placeholder": "开始日期",
                        "end-placeholder": "结束日期"
                      },
                      model: {
                        value: _vm.creatTime,
                        callback: function($$v) {
                          _vm.creatTime = $$v
                        },
                        expression: "creatTime"
                      }
                    })
                  ],
                  1
                )
              ]),
              _c(
                "div",
                { staticClass: "actions_btn_wrap down t_right" },
                [
                  _c("el-button", {
                    staticClass: "mr",
                    attrs: {
                      size: "small",
                      type: "primary",
                      icon: "el-icon-search",
                      circle: "",
                      title: "搜索"
                    },
                    on: { click: _vm.searchHandle }
                  }),
                  this.isApplication == false
                    ? _c(
                        "el-button",
                        {
                          staticClass: "mr",
                          attrs: { type: "primary" },
                          on: { click: _vm.createApplyFor }
                        },
                        [_vm._v("生成付款申请")]
                      )
                    : _vm._e()
                ],
                1
              )
            ]),
            _c(
              "div",
              { staticClass: "table_part clearfix" },
              [
                _c(
                  "el-tabs",
                  {
                    staticClass: "subnav_wrap hidden",
                    on: { "tab-click": _vm.handleClick },
                    model: {
                      value: _vm.activeName,
                      callback: function($$v) {
                        _vm.activeName = $$v
                      },
                      expression: "activeName"
                    }
                  },
                  _vm._l(_vm.panelist, function(item) {
                    return _c(
                      "el-tab-pane",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.tableLoading,
                            expression: "tableLoading"
                          }
                        ],
                        key: item.index,
                        attrs: { label: item.name, name: item.index }
                      },
                      [
                        item.index == "1"
                          ? _c(
                              "div",
                              [
                                _c(
                                  "el-table",
                                  {
                                    ref: "multipleTable",
                                    refInFor: true,
                                    staticClass: "table_list",
                                    attrs: {
                                      data: _vm.requestList,
                                      "row-key": "poId",
                                      "expand-row-keys": _vm.expands,
                                      border: "",
                                      fit: "",
                                      stripe: ""
                                    },
                                    on: {
                                      "row-click": _vm.rowClick,
                                      select: _vm.selectHandle,
                                      "expand-change": _vm.expandChange,
                                      "select-all": _vm.selectAllHandle
                                    }
                                  },
                                  [
                                    _c("el-table-column", {
                                      attrs: { type: "selection" }
                                    }),
                                    _c("el-table-column", {
                                      attrs: { type: "expand" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function(scope) {
                                              return [
                                                _c(
                                                  "el-table",
                                                  {
                                                    ref:
                                                      "childMultipleTable_" +
                                                      scope.row.poId,
                                                    refInFor: true,
                                                    staticClass: "table_list",
                                                    attrs: {
                                                      data:
                                                        scope.row.finalPayments,
                                                      border: "",
                                                      fit: ""
                                                    },
                                                    on: {
                                                      select: function(
                                                        selection,
                                                        row
                                                      ) {
                                                        _vm.childSelectHandle(
                                                          selection,
                                                          row,
                                                          scope.row
                                                        )
                                                      },
                                                      "select-all": function(
                                                        selection
                                                      ) {
                                                        _vm.childSelectAllHandle(
                                                          selection,
                                                          scope.row
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c("el-table-column", {
                                                      attrs: {
                                                        fixed: "",
                                                        type: "selection"
                                                      }
                                                    }),
                                                    _c("el-table-column", {
                                                      attrs: {
                                                        fixed: "",
                                                        label: "PO行号"
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "default",
                                                            fn: function(ref) {
                                                              var row = ref.row
                                                              return [
                                                                _vm._v(
                                                                  "\n                          " +
                                                                    _vm._s(
                                                                      row.purchaseOrderSn
                                                                    ) +
                                                                    "\n                        "
                                                                )
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        true
                                                      )
                                                    }),
                                                    _c("el-table-column", {
                                                      attrs: {
                                                        fixed: "",
                                                        label: "物料编号",
                                                        "min-width": "180",
                                                        "class-name":
                                                          "marpno_wuliao",
                                                        "show-overflow-tooltip": true
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "default",
                                                            fn: function(ref) {
                                                              var row = ref.row
                                                              return [
                                                                _vm._v(
                                                                  "\n                          " +
                                                                    _vm._s(
                                                                      row.materialCode
                                                                    ) +
                                                                    "\n                        "
                                                                )
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        true
                                                      )
                                                    }),
                                                    _c("el-table-column", {
                                                      attrs: {
                                                        label: "确认书号"
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "default",
                                                            fn: function(ref) {
                                                              var row = ref.row
                                                              return [
                                                                _vm._v(
                                                                  "\n                          " +
                                                                    _vm._s(
                                                                      row.confirmingCode
                                                                    ) +
                                                                    "\n                        "
                                                                )
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        true
                                                      )
                                                    }),
                                                    _c("el-table-column", {
                                                      attrs: {
                                                        label: "确认书数量"
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "default",
                                                            fn: function(ref) {
                                                              var row = ref.row
                                                              return [
                                                                row.purchaseMeasurementUnit ==
                                                                "米"
                                                                  ? _c("span", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          (row.confirmPackages
                                                                            ? row.confirmPackages
                                                                            : 0) +
                                                                            "包 " +
                                                                            (row.packSpecification
                                                                              ? row.packSpecification
                                                                              : 0) +
                                                                            "米/包 " +
                                                                            (row.confirmQuantity
                                                                              ? row.confirmQuantity
                                                                              : 0) +
                                                                            "米"
                                                                        )
                                                                      )
                                                                    ])
                                                                  : _c("span", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          (row.confirmPackages
                                                                            ? row.confirmPackages
                                                                            : 0) +
                                                                            (row.purchaseMeasurementUnit
                                                                              ? row.purchaseMeasurementUnit
                                                                              : "条")
                                                                        )
                                                                      )
                                                                    ])
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        true
                                                      )
                                                    }),
                                                    _c("el-table-column", {
                                                      attrs: {
                                                        label: "货妥数量"
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "default",
                                                            fn: function(ref) {
                                                              var row = ref.row
                                                              return [
                                                                row.purchaseMeasurementUnit ==
                                                                "米"
                                                                  ? _c("span", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          (row.readyBagQuantity
                                                                            ? row.readyBagQuantity
                                                                            : 0) +
                                                                            "包 " +
                                                                            (row.packSpecification
                                                                              ? row.packSpecification
                                                                              : 0) +
                                                                            "米/包 " +
                                                                            (row.readyMeter
                                                                              ? row.readyMeter
                                                                              : 0) +
                                                                            "米"
                                                                        )
                                                                      )
                                                                    ])
                                                                  : _c("span", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          (row.readyMeter
                                                                            ? row.readyMeter
                                                                            : 0) +
                                                                            (row.purchaseMeasurementUnit
                                                                              ? row.purchaseMeasurementUnit
                                                                              : "条")
                                                                        )
                                                                      )
                                                                    ])
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        true
                                                      )
                                                    }),
                                                    _c("el-table-column", {
                                                      attrs: {
                                                        label: "申请付款数量"
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "default",
                                                            fn: function(ref) {
                                                              var row = ref.row
                                                              return [
                                                                row.purchaseMeasurementUnit ==
                                                                "米"
                                                                  ? _c("span", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          (row.applyPaidBagQuantity
                                                                            ? row.applyPaidBagQuantity
                                                                            : 0) +
                                                                            "包 " +
                                                                            (row.applyPaidBagMeter
                                                                              ? row.applyPaidBagMeter
                                                                              : 0) +
                                                                            "米/包 " +
                                                                            (row.applyPaidMeter
                                                                              ? row.applyPaidMeter
                                                                              : 0) +
                                                                            "米"
                                                                        )
                                                                      )
                                                                    ])
                                                                  : _c("span", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          (row.applyPaidMeter
                                                                            ? row.applyPaidMeter
                                                                            : 0) +
                                                                            (row.purchaseMeasurementUnit
                                                                              ? row.purchaseMeasurementUnit
                                                                              : "条")
                                                                        )
                                                                      )
                                                                    ])
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        true
                                                      )
                                                    }),
                                                    _c("el-table-column", {
                                                      attrs: {
                                                        label: "已付数量"
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "default",
                                                            fn: function(ref) {
                                                              var row = ref.row
                                                              return [
                                                                row.purchaseMeasurementUnit ==
                                                                "米"
                                                                  ? _c("span", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          (row.paidBagQuantity
                                                                            ? row.paidBagQuantity
                                                                            : 0) +
                                                                            "包 " +
                                                                            (row.paidMeter
                                                                              ? row.paidMeter
                                                                              : 0) +
                                                                            "米"
                                                                        )
                                                                      )
                                                                    ])
                                                                  : _c("span", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          (row.paidMeter
                                                                            ? row.paidMeter
                                                                            : 0) +
                                                                            (row.purchaseMeasurementUnit
                                                                              ? row.purchaseMeasurementUnit
                                                                              : "条")
                                                                        )
                                                                      )
                                                                    ])
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        true
                                                      )
                                                    }),
                                                    _c("el-table-column", {
                                                      attrs: {
                                                        label: "待付数量"
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "default",
                                                            fn: function(ref) {
                                                              var row = ref.row
                                                              return [
                                                                row.purchaseMeasurementUnit ==
                                                                "米"
                                                                  ? _c("span", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          (row.waitPaidBagQuantity
                                                                            ? row.waitPaidBagQuantity
                                                                            : 0) +
                                                                            "包 " +
                                                                            (row.waitPaidMeter
                                                                              ? row.waitPaidMeter
                                                                              : 0) +
                                                                            "米"
                                                                        )
                                                                      )
                                                                    ])
                                                                  : _c("span", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          (row.waitPaidMeter
                                                                            ? row.waitPaidMeter
                                                                            : 0) +
                                                                            (row.purchaseMeasurementUnit
                                                                              ? row.purchaseMeasurementUnit
                                                                              : "条")
                                                                        )
                                                                      )
                                                                    ])
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        true
                                                      )
                                                    }),
                                                    _c("el-table-column", {
                                                      attrs: {
                                                        label: "最终发货"
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "default",
                                                            fn: function(ref) {
                                                              var row = ref.row
                                                              return [
                                                                row.isApplication ==
                                                                true
                                                                  ? _c(
                                                                      "i",
                                                                      {},
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            row.isFinalDelivery ==
                                                                              true
                                                                              ? "是"
                                                                              : "否"
                                                                          )
                                                                        )
                                                                      ]
                                                                    )
                                                                  : _vm._e()
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        true
                                                      )
                                                    }),
                                                    _c("el-table-column", {
                                                      attrs: {
                                                        label: "调拨单号"
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "default",
                                                            fn: function(ref) {
                                                              var row = ref.row
                                                              return [
                                                                _c("span", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      row.factoryAllocationCode
                                                                    )
                                                                  )
                                                                ])
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        true
                                                      )
                                                    })
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    }),
                                    _c("el-table-column", {
                                      attrs: { label: "订单编号" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function(ref) {
                                              var row = ref.row
                                              return [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(row.poId) +
                                                    "\n                  "
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    }),
                                    _c("el-table-column", {
                                      attrs: { label: "供应商" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function(ref) {
                                              var row = ref.row
                                              return [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      row.supplierShortName
                                                    ) +
                                                    "\n                    "
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    }),
                                    _c("el-table-column", {
                                      attrs: { label: "付款状态" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function(ref) {
                                              var row = ref.row
                                              return [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      _vm.actionsTypeHandle(
                                                        row.paidStatus
                                                      )
                                                    ) +
                                                    "\n                  "
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    }),
                                    _c("el-table-column", {
                                      attrs: { label: "订单创建时间" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function(ref) {
                                              var row = ref.row
                                              return [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(row.poCreateTime) +
                                                    "\n                  "
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _c(
                              "div",
                              [
                                _c(
                                  "el-table",
                                  {
                                    ref: _vm.multipleTable,
                                    refInFor: true,
                                    staticClass: "table_list",
                                    attrs: {
                                      data: _vm.requestList,
                                      "row-key": "finalPaymentCode",
                                      "expand-row-keys": _vm.expands,
                                      border: "",
                                      fit: "",
                                      stripe: ""
                                    },
                                    on: {
                                      "row-click": _vm.rowClickApplyfor,
                                      "expand-change": _vm.rowClickApplyfor
                                    }
                                  },
                                  [
                                    _c("el-table-column", {
                                      attrs: { type: "expand" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function(scope) {
                                              return [
                                                _c(
                                                  "el-table",
                                                  {
                                                    ref:
                                                      "childMultipleTable_" +
                                                      scope.row.id,
                                                    refInFor: true,
                                                    staticClass: "table_list",
                                                    attrs: {
                                                      data:
                                                        scope.row.finalPayments,
                                                      border: "",
                                                      fit: ""
                                                    }
                                                  },
                                                  [
                                                    _c("el-table-column", {
                                                      attrs: {
                                                        fixed: "",
                                                        label: "PO行号"
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "default",
                                                            fn: function(ref) {
                                                              var row = ref.row
                                                              return [
                                                                _vm._v(
                                                                  "\n                          " +
                                                                    _vm._s(
                                                                      row.purchaseOrderSn
                                                                    ) +
                                                                    "\n                        "
                                                                )
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        true
                                                      )
                                                    }),
                                                    _c("el-table-column", {
                                                      attrs: {
                                                        fixed: "",
                                                        label: "订单编号"
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "default",
                                                            fn: function(ref) {
                                                              var row = ref.row
                                                              return [
                                                                _vm._v(
                                                                  "\n                          " +
                                                                    _vm._s(
                                                                      row.poId
                                                                    ) +
                                                                    "\n                        "
                                                                )
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        true
                                                      )
                                                    }),
                                                    _c("el-table-column", {
                                                      attrs: {
                                                        label: "物料编号",
                                                        "min-width": "180",
                                                        "class-name":
                                                          "marpno_wuliao",
                                                        "show-overflow-tooltip": true
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "default",
                                                            fn: function(ref) {
                                                              var row = ref.row
                                                              return [
                                                                _vm._v(
                                                                  "\n                          " +
                                                                    _vm._s(
                                                                      row.materialCode
                                                                    ) +
                                                                    "\n                        "
                                                                )
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        true
                                                      )
                                                    }),
                                                    _c("el-table-column", {
                                                      attrs: {
                                                        label: "供应商"
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "default",
                                                            fn: function(ref) {
                                                              var row = ref.row
                                                              return [
                                                                _vm._v(
                                                                  "\n                          " +
                                                                    _vm._s(
                                                                      row.supplierShortName
                                                                    ) +
                                                                    "\n                          "
                                                                )
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        true
                                                      )
                                                    }),
                                                    _c("el-table-column", {
                                                      attrs: {
                                                        label: "确认书号"
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "default",
                                                            fn: function(ref) {
                                                              var row = ref.row
                                                              return [
                                                                _vm._v(
                                                                  "\n                          " +
                                                                    _vm._s(
                                                                      row.confirmingCode
                                                                    ) +
                                                                    "\n                        "
                                                                )
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        true
                                                      )
                                                    }),
                                                    _c("el-table-column", {
                                                      attrs: {
                                                        label: "确认书数量"
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "default",
                                                            fn: function(ref) {
                                                              var row = ref.row
                                                              return [
                                                                row.purchaseMeasurementUnit ==
                                                                "米"
                                                                  ? _c("span", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          (row.confirmPackages
                                                                            ? row.confirmPackages
                                                                            : 0) +
                                                                            "包 " +
                                                                            (row.packSpecification
                                                                              ? row.packSpecification
                                                                              : 0) +
                                                                            "米/包 " +
                                                                            (row.confirmQuantity
                                                                              ? row.confirmQuantity
                                                                              : 0) +
                                                                            "米"
                                                                        )
                                                                      )
                                                                    ])
                                                                  : _c("span", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          (row.confirmQuantity
                                                                            ? row.confirmQuantity
                                                                            : 0) +
                                                                            (row.purchaseMeasurementUnit
                                                                              ? row.purchaseMeasurementUnit
                                                                              : "条")
                                                                        )
                                                                      )
                                                                    ])
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        true
                                                      )
                                                    }),
                                                    _c("el-table-column", {
                                                      attrs: {
                                                        label: "货妥数量"
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "default",
                                                            fn: function(ref) {
                                                              var row = ref.row
                                                              return [
                                                                row.purchaseMeasurementUnit ==
                                                                "米"
                                                                  ? _c("span", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          (row.readyBagQuantity
                                                                            ? row.readyBagQuantity
                                                                            : 0) +
                                                                            "包 " +
                                                                            (row.packSpecification
                                                                              ? row.packSpecification
                                                                              : 0) +
                                                                            "米/包 " +
                                                                            (row.readyMeter
                                                                              ? row.readyMeter
                                                                              : 0) +
                                                                            "米"
                                                                        )
                                                                      )
                                                                    ])
                                                                  : _c("span", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          (row.readyMeter
                                                                            ? row.readyMeter
                                                                            : 0) +
                                                                            (row.purchaseMeasurementUnit
                                                                              ? row.purchaseMeasurementUnit
                                                                              : "条")
                                                                        )
                                                                      )
                                                                    ])
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        true
                                                      )
                                                    }),
                                                    _c("el-table-column", {
                                                      attrs: {
                                                        label: "申请付款数量"
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "default",
                                                            fn: function(ref) {
                                                              var row = ref.row
                                                              return [
                                                                row.purchaseMeasurementUnit ==
                                                                "米"
                                                                  ? _c("span", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          (row.applyPaidBagQuantity
                                                                            ? row.applyPaidBagQuantity
                                                                            : 0) +
                                                                            "包 " +
                                                                            (row.applyPaidBagMeter
                                                                              ? row.applyPaidBagMeter
                                                                              : 0) +
                                                                            "米/包 " +
                                                                            (row.applyPaidMeter
                                                                              ? row.applyPaidMeter
                                                                              : 0) +
                                                                            "米"
                                                                        )
                                                                      )
                                                                    ])
                                                                  : _c("span", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          (row.applyPaidMeter
                                                                            ? row.applyPaidMeter
                                                                            : 0) +
                                                                            (row.purchaseMeasurementUnit
                                                                              ? row.purchaseMeasurementUnit
                                                                              : "条")
                                                                        )
                                                                      )
                                                                    ])
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        true
                                                      )
                                                    }),
                                                    _c("el-table-column", {
                                                      attrs: {
                                                        label: "已付数量"
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "default",
                                                            fn: function(ref) {
                                                              var row = ref.row
                                                              return [
                                                                row.purchaseMeasurementUnit ==
                                                                "米"
                                                                  ? _c("span", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          (row.paidBagQuantity
                                                                            ? row.paidBagQuantity
                                                                            : 0) +
                                                                            "包 " +
                                                                            (row.paidMeter
                                                                              ? row.paidMeter
                                                                              : 0) +
                                                                            "米"
                                                                        )
                                                                      )
                                                                    ])
                                                                  : _c("span", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          (row.paidMeter
                                                                            ? row.paidMeter
                                                                            : 0) +
                                                                            (row.purchaseMeasurementUnit
                                                                              ? row.purchaseMeasurementUnit
                                                                              : "条")
                                                                        )
                                                                      )
                                                                    ])
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        true
                                                      )
                                                    }),
                                                    _c("el-table-column", {
                                                      attrs: {
                                                        label: "待付数量"
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "default",
                                                            fn: function(ref) {
                                                              var row = ref.row
                                                              return [
                                                                row.purchaseMeasurementUnit ==
                                                                "米"
                                                                  ? _c("span", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          (row.waitPaidBagQuantity
                                                                            ? row.waitPaidBagQuantity
                                                                            : 0) +
                                                                            "包 " +
                                                                            (row.waitPaidMeter
                                                                              ? row.waitPaidMeter
                                                                              : 0) +
                                                                            "米"
                                                                        )
                                                                      )
                                                                    ])
                                                                  : _c("span", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          (row.waitPaidMeter
                                                                            ? row.waitPaidMeter
                                                                            : 0) +
                                                                            (row.purchaseMeasurementUnit
                                                                              ? row.purchaseMeasurementUnit
                                                                              : "条")
                                                                        )
                                                                      )
                                                                    ])
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        true
                                                      )
                                                    }),
                                                    _c("el-table-column", {
                                                      attrs: {
                                                        label: "最终发货"
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "default",
                                                            fn: function(ref) {
                                                              var row = ref.row
                                                              return [
                                                                row.isApplication ==
                                                                true
                                                                  ? _c(
                                                                      "i",
                                                                      {},
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            row.isFinalDelivery ==
                                                                              true
                                                                              ? "是"
                                                                              : "否"
                                                                          )
                                                                        )
                                                                      ]
                                                                    )
                                                                  : _vm._e()
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        true
                                                      )
                                                    })
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    }),
                                    _c("el-table-column", {
                                      attrs: { label: "尾款付款单号" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function(ref) {
                                              var row = ref.row
                                              return [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      row.finalPaymentCode
                                                    ) +
                                                    "\n                  "
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    }),
                                    _c("el-table-column", {
                                      attrs: { label: "形式发票号" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function(ref) {
                                              var row = ref.row
                                              return [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      row.proformaInvoiceNum
                                                    ) +
                                                    "\n                  "
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    }),
                                    _c("el-table-column", {
                                      attrs: { label: "付款状态" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function(ref) {
                                              var row = ref.row
                                              return [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(row.paidStatusName) +
                                                    "\n                  "
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    }),
                                    _c("el-table-column", {
                                      attrs: { label: "申请付款时间" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function(ref) {
                                              var row = ref.row
                                              return [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(row.applicantTime) +
                                                    "\n                  "
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    }),
                                    _c("el-table-column", {
                                      attrs: { label: "申请人" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function(ref) {
                                              var row = ref.row
                                              return [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(row.applicantName) +
                                                    "\n                  "
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    }),
                                    _c("el-table-column", {
                                      attrs: { label: "凭证状态" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function(ref) {
                                              var row = ref.row
                                              var $index = ref.$index
                                              return [
                                                _c(
                                                  "el-popover",
                                                  {
                                                    attrs: {
                                                      placement: "right",
                                                      width: "300",
                                                      trigger: "hover",
                                                      "open-delay": "1000"
                                                    },
                                                    on: {
                                                      show: function($event) {
                                                        return _vm.checkApprovalProcess(
                                                          row,
                                                          $index
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c("div", [
                                                      _c(
                                                        "i",
                                                        {
                                                          staticClass: "block"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                          审批人：\n                          "
                                                          ),
                                                          _c("em", [
                                                            _vm._v(
                                                              _vm._s(
                                                                row.fullName
                                                              )
                                                            )
                                                          ])
                                                        ]
                                                      ),
                                                      _c(
                                                        "i",
                                                        {
                                                          staticClass:
                                                            "block mt10 mb10"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                          审批时间：\n                          "
                                                          ),
                                                          _c("em", [
                                                            _vm._v(
                                                              _vm._s(
                                                                row.approvalTime
                                                              )
                                                            )
                                                          ])
                                                        ]
                                                      ),
                                                      _c(
                                                        "i",
                                                        {
                                                          staticClass: "block"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                          审批意见：\n                          "
                                                          ),
                                                          _c("em", [
                                                            _vm._v(
                                                              _vm._s(
                                                                row.content
                                                              )
                                                            )
                                                          ])
                                                        ]
                                                      )
                                                    ]),
                                                    _c(
                                                      "el-tag",
                                                      {
                                                        staticClass: "pointer",
                                                        attrs: {
                                                          slot: "reference",
                                                          type: _vm._f(
                                                            "statusFilter"
                                                          )(row.auditStatus)
                                                        },
                                                        slot: "reference"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            row.auditStatusName
                                                          )
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    }),
                                    _c("el-table-column", {
                                      attrs: { label: "操作" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function(ref) {
                                              var row = ref.row
                                              var $index = ref.$index
                                              return [
                                                row.auditStatus == "Draft" ||
                                                row.auditStatus == "Rejected"
                                                  ? _c(
                                                      "div",
                                                      [
                                                        _c("el-button", {
                                                          staticClass:
                                                            "el-icon-edit",
                                                          attrs: {
                                                            type: "primary",
                                                            circle: "",
                                                            size: "mini",
                                                            title: "编辑"
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                              return _vm.factoryupd(
                                                                $index,
                                                                row
                                                              )
                                                            }
                                                          }
                                                        }),
                                                        _c("el-button", {
                                                          staticClass:
                                                            "el-icon-check",
                                                          attrs: {
                                                            type: "success",
                                                            circle: "",
                                                            size: "mini",
                                                            title: "提交审批"
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                              return _vm.draftoutsave(
                                                                $index,
                                                                row
                                                              )
                                                            }
                                                          }
                                                        }),
                                                        _c("el-button", {
                                                          staticClass:
                                                            "el-icon-delete",
                                                          attrs: {
                                                            type: "danger",
                                                            circle: "",
                                                            size: "mini",
                                                            title: "删除"
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                              return _vm.factorydel(
                                                                $index,
                                                                row
                                                              )
                                                            }
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                row.auditStatus == "Terminated"
                                                  ? _c(
                                                      "div",
                                                      [
                                                        _c("el-button", {
                                                          staticClass:
                                                            "el-icon-delete",
                                                          attrs: {
                                                            type: "danger",
                                                            circle: "",
                                                            size: "mini",
                                                            title: "删除"
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                              return _vm.factorydel(
                                                                $index,
                                                                row
                                                              )
                                                            }
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e()
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                      ]
                    )
                  }),
                  1
                ),
                _c(
                  "div",
                  { staticClass: "pagination_wrap fr" },
                  [
                    _c("el-pagination", {
                      attrs: {
                        "current-page": _vm.currentPage,
                        "page-sizes": [10, 50],
                        "page-size": _vm.pageSize,
                        layout: "sizes, total, prev, pager, next",
                        total: _vm.total
                      },
                      on: {
                        "size-change": _vm.handleSizeChange,
                        "current-change": _vm.handleCurrentChange,
                        "update:currentPage": function($event) {
                          _vm.currentPage = $event
                        },
                        "update:current-page": function($event) {
                          _vm.currentPage = $event
                        }
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ])
        : _vm._e(),
      _vm.ifaddsure == true
        ? _c(
            "div",
            { staticClass: "paymentRequest" },
            [
              _c("finalPaymentadd", {
                ref: "RefChilde",
                attrs: {
                  checkArr: _vm.checkArr,
                  operationTag: _vm.operationTag
                },
                on: { cancel: _vm.cancel }
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: {
            title: "提示",
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            visible: _vm.dialogdelVisible,
            width: "30%"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogdelVisible = $event
            }
          }
        },
        [
          _c("span", [_vm._v(_vm._s(this.marked))]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogdelVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.saveloading },
                  on: {
                    click: function($event) {
                      return _vm.delVisibleclick()
                    }
                  }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
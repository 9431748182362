import http from "../http";
import axios from "axios";

/**
 * 生产管理——面料生产
 * 分页查询成品生产申请项
 */
export const fabricProduceList = (currentPage, pageSize, data) => {
  return http({
    method: "post",
    url: process.env.VUE_APP_SCM_URL + `scm/v1/fetchproductorderRequestAndItems/search?currentPage=${currentPage}&pageSize=${pageSize}`,
    data
  });
};

/**
 * 分页查询面料生产订单申请单以及订单子项-草稿页面
 */
export const fabricProduceDraftList = (currentPage, pageSize, data) => {
  return http({
    method: "post",
    url: process.env.VUE_APP_SCM_URL + `scm/v1/fetchproductorderRequestAndItems/draft?currentPage=${currentPage}&pageSize=${pageSize}`,
    data
  });
};

/**
 * 删除成品生产申请单和申请项
 */
export const deleteFabricProduce = (id) => {
  return http({
    method: "delete",
    url: process.env.VUE_APP_SCM_URL + `scm/v1/productorderrequestsdeletebyId/${id}`
  });
};

/**
 * 修改面料生产订单申请单
 */
export const editProduceList = (data) => {
  return http({
    method: "post",
    url: process.env.VUE_APP_SCM_URL + `scm/v1/productorderrequestupdate`,
    data
  });
};

/**
 * 根据id查询成品生产申请单
 */
export const searchFabricProduce = (id) => {
  return http({
    method: "get",
    url: process.env.VUE_APP_SCM_URL + `scm/v1/peoductorderrequestanditems/${id}`
  });
};

/**
 * 删除面料生产订单申请项以及所有原材料集合
 */
export const deleteProduceRequest = (sampleUuid) => {
  return http({
    method: "delete",
    url: process.env.VUE_APP_SCM_URL + `scm/v1/productorderrequestitemsdeletebysampleuuid/${sampleUuid}`
  });
};

/**
 * 删除成品生产申请项
 */
export const deleteProduceRequestItem = (id) => {
  return http({
    method: "delete",
    url: process.env.VUE_APP_SCM_URL + `scm/v1/productorderrequestitemsdelete/${id}`
  });
};

/**
 * 新增面料生产订单申请单及子项
 */
export const submitProduceList = (data) => {
  return http({
    method: "post",
    headers: { "X-Custom-Header": "Loading" },
    url: process.env.VUE_APP_SCM_URL + `scm/v1/productorderrequestsadd`,
    data
  });
};

/**
 * 生产类型
 */
export const productionTypeList = (currentPage, pageSize, data) => {
  // return http({
  //   method: "post",
  //   url: process.env.VUE_APP_INFRASTRUCTURE_URL + `/infrastructure/v1/dictitems/search?currentPage=${currentPage}&pageSize=${pageSize}`,
  //   data
  // });
  let res = {}
  res.data = [{ "dictItemName": "客订-面料", "dictItemValue": "001" }, { "dictItemName": "备货-面料", "dictItemValue": "002" }]
  res.code = '0'
  return res
};

/**
 * 销售单号查询客户名
 */
// export const searchUsername = (data) => {
//   return http({
//     method: "post",
//     url: process.env.VUE_APP_OMS_URL + `saleorder/oms/saleorders/saleorderanditem`,
//     data
//   });
// };
const CommonUnit = {individual :'个',Box:'箱',Kg:'公斤',onePieces:'件',Cover:'套',Piece:'条',Book:'本',Meter:'米',Package:'包'}
export const getSoItem = (params) => {
  let data = {
    orderBy: [],
    query: [
      { fieldName: 'soNo', opr: 'eq', value: params.saleOrderId },
      { fieldName: 'objectNo', opr: 'eq', value: params.saleOrderId },
      { fieldName: 'invoiceNo', opr: 'eq', value: params.saleOrderId },
    ],
    subTotal: [],
    groupBy: []
  };
  let result = http({
    method: 'post',
    url: `${process.env.VUE_APP_OMS_URL}soItem/v2/index?currentPage=1&pageSize=9999`,
    data
  });
  result._then = result.then
  result.then = (cb) => {
    result._then((res) => {
      let r = res.data.records
      r = r.map(item => {
        item.productCode = item.productNo // 产品编号
        item.materialCode = item.materialNo // 物料编号
        item.purchaseMeasurementUnit = CommonUnit[item.unit] // 单位字段
        return item;
      });
      cb(r);
    });
  }
  return result;
}
export const getSo = (params) => {
  let data = { orderBy: [], query: [{ fieldName: 'invoiceNo', opr: 'eq', value: params.saleOrderId }], subTotal: [], groupBy: [] }
  let result = http({
    method: 'post',
    url: `${process.env.VUE_APP_OMS_URL}so/v2/index?currentPage=1&pageSize=20`,
    data
  });
  result._then = result.then;
  result.then = (cb) => {
    result._then((res) => {
      let r = res.data.records;
      r = r.map(item => {
        item.customerName = item.businessCustomerName;
        item.customerId = item.businessCustomerNo;
        return item;
      })[0];
      cb(res);
    });
  }
  return result;
}
export const searchUsername =  async (params) => {
  let res = await getSo(params);
  let result = {
    data: res.data.records.length > 0 ? res.data.records[0] : [],
    code: res.code,
    message: res.message,
  };
  result.data.saleOrderItemList = await getSoItem(params);
  result.data.saleOrderItemList.purchaseMeasurementUnit = "单位"
  console.log('result',result);
  return result;  
};
/**
 * 物料可用库存
 */
export const availableStockData = (data) => {
  return http({
    method: "post",
    // url: process.env.VUE_APP_INVENTORY_URL + `recordinventory/v1/transactiondetails/availablestock`,
    url: process.env.VUE_APP_SCM_URL + `allocation/v1/availableinventory`,
    data
  });
};
// export const availableStockData = (data) => {
//   return http({
//     method: "post",
//     url: process.env.VUE_APP_INVENTORY_URL + `recordinventory/v1/transactiondetails/queryProducedQuantity`,
//     data
//   });
// };
<template>
  <div class="container finalPaymentRequest">
    <!--<p>尾款申请</p>-->
    <div class="commoncontent" v-if="ifaddsure==false">
      <div class="actions_part clearfix">
        <div class="actions_wrap">
          <div class="display_ib mr10 mb10">
            <span class="tag">采购订单编号：</span>
            <el-input class="input_single w200 mr10" v-model="purchaseOrderCode" placeholder="请输入" @keyup.enter.native="searchHandle"></el-input>
          </div>
          <div class="display_ib mr10 mb10">
            <span class="tag">物料编号：</span>
            <el-input class="input_single w200 mr10" v-model="materialCode" placeholder="请输入" @keyup.enter.native="searchHandle"></el-input>
          </div>
          <div class="display_ib mr10 mb10">
            <span class="tag">供应商：</span>
            <!-- <el-input class="input_single w200 mr10" v-model="supplierName" placeholder="请输入"></el-input> -->
            <el-select class="select_single w200 mr10"
              @change="searchHandle"
              v-model="supplierCode"
              filterable
              placeholder="请选择">
              <el-option key="" label="全部" value=""></el-option>
              <el-option
                v-for="item in supplierList"
                :key="item.supplierId"
                :label="item.supplierCode"
                :value="item.supplierId">
              </el-option>
            </el-select>
          </div>
          <div class="display_ib mr10 mb10">
            <span class="tag">付款状态：</span>
            <el-select
              class="select_single w200 mr10"
               @change="searchHandle"
              v-model="paidStatus"
              placeholder="请选择">
              <el-option key label="全部" value></el-option>
              <el-option
                v-for="item in paymentList"
                :key="item.index"
                :label="item.name"
                :value="item.index"
              ></el-option>
            </el-select>
          </div>
          <div class="display_ib mr10 mb10">
            <span class="tag">创建日期：</span>
            <el-date-picker
              v-model="creatTime"
              unlink-panels
              type="daterange"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            ></el-date-picker>
          </div>
        </div>
        <div class="actions_btn_wrap down t_right">
          <el-button
            size="small"
            type="primary"
            class="mr"
            icon="el-icon-search"
            circle
            title="搜索"
            @click="searchHandle"
          ></el-button>
          <el-button
            v-if="this.isApplication==false"
            type="primary"
            class="mr"
            @click="createApplyFor"
          >生成付款申请</el-button>
        </div>
      </div>
      <div class="table_part clearfix">
          <el-tabs class="subnav_wrap hidden" v-model="activeName" @tab-click="handleClick">
            <el-tab-pane
              v-for="item in panelist"
              :key="item.index"
              :label="item.name"
              :name="item.index"
              v-loading="tableLoading">
              <div v-if="item.index == '1'">
                <el-table
                  class="table_list"
                  ref="multipleTable"
                  :data="requestList"
                  row-key="poId"
                  :expand-row-keys="expands"
                  @row-click="rowClick"
                  border
                  fit
                  stripe
                  @select="selectHandle"
                  @expand-change="expandChange"
                  @select-all="selectAllHandle">
                  <el-table-column type="selection" />
                  <el-table-column type="expand">
                    <template slot-scope="scope">
                      <el-table
                        class="table_list"
                        :ref="'childMultipleTable_' + scope.row.poId"
                        :data="scope.row.finalPayments"
                        border
                        fit
                        @select="(selection, row) => { childSelectHandle(selection, row, scope.row) }" 
                        @select-all="(selection) => { childSelectAllHandle(selection, scope.row) }">
                        <el-table-column fixed type="selection" />
                        <el-table-column fixed label="PO行号">
                          <template slot-scope="{ row }">
                            {{ row.purchaseOrderSn }}
                          </template>
                        </el-table-column>
                        <el-table-column fixed label="物料编号" min-width="180" class-name="marpno_wuliao" :show-overflow-tooltip="true">
                          <template slot-scope="{ row }">
                            {{ row.materialCode }}
                          </template>
                        </el-table-column>
                        <!-- <el-table-column label="供应商">
                          <template slot-scope="{ row }">
                            {{ row.supplierShortName }}
                          </template>
                        </el-table-column> -->
                        <el-table-column label="确认书号">
                          <template slot-scope="{ row }">
                            {{ row.confirmingCode }}
                          </template>
                        </el-table-column>
                        <el-table-column label="确认书数量">
                          <template slot-scope="{ row }">
                            <span v-if="row.purchaseMeasurementUnit == '米'">{{ (row.confirmPackages? row.confirmPackages: 0) + '包 ' + (row.packSpecification? row.packSpecification: 0) + '米/包 ' + (row.confirmQuantity ? row.confirmQuantity : 0) + '米' }}</span>
                            <span v-else>{{ (row.confirmPackages ? row.confirmPackages : 0) + (row.purchaseMeasurementUnit ? row.purchaseMeasurementUnit : '条') }}</span>
                          </template>
                        </el-table-column>
                        <el-table-column label="货妥数量">
                          <template slot-scope="{ row }">
                            <span v-if="row.purchaseMeasurementUnit == '米'">{{ (row.readyBagQuantity? row.readyBagQuantity: 0) + '包 ' + (row.packSpecification? row.packSpecification: 0) + '米/包 ' + (row.readyMeter ? row.readyMeter : 0) + '米' }}</span>
                            <span v-else>{{ (row.readyMeter ? row.readyMeter : 0) + (row.purchaseMeasurementUnit ? row.purchaseMeasurementUnit : '条') }}</span>
                          </template>
                        </el-table-column>
                        <el-table-column label="申请付款数量">
                          <template slot-scope="{ row }">
                            <span v-if="row.purchaseMeasurementUnit == '米'">{{ (row.applyPaidBagQuantity? row.applyPaidBagQuantity: 0) + '包 ' + (row.applyPaidBagMeter? row.applyPaidBagMeter: 0) + '米/包 ' + (row.applyPaidMeter ? row.applyPaidMeter : 0) + '米' }}</span>
                            <span v-else>{{ (row.applyPaidMeter ? row.applyPaidMeter : 0) + (row.purchaseMeasurementUnit ? row.purchaseMeasurementUnit : '条') }}</span>
                          </template>
                        </el-table-column>
                        <el-table-column label="已付数量">
                          <template slot-scope="{ row }">
                            <span v-if="row.purchaseMeasurementUnit == '米'">{{ (row.paidBagQuantity? row.paidBagQuantity: 0) + '包 ' + (row.paidMeter ? row.paidMeter : 0) + '米' }}</span>
                            <span v-else>{{ (row.paidMeter ? row.paidMeter : 0) + (row.purchaseMeasurementUnit ? row.purchaseMeasurementUnit : '条') }}</span>
                          </template>
                        </el-table-column>
                        <el-table-column label="待付数量" >
                          <template slot-scope="{ row }">
                            <span v-if="row.purchaseMeasurementUnit == '米'">{{ (row.waitPaidBagQuantity? row.waitPaidBagQuantity: 0) + '包 ' + (row.waitPaidMeter? row.waitPaidMeter: 0) + '米' }}</span>
                            <span v-else>{{ (row.waitPaidMeter? row.waitPaidMeter: 0) + (row.purchaseMeasurementUnit ? row.purchaseMeasurementUnit : '条') }}</span>
                          </template>
                        </el-table-column>   
                        <el-table-column label="最终发货" >
                          <template slot-scope="{ row }">
                            <i style v-if="row.isApplication==true">{{row.isFinalDelivery==true?"是":"否"}}</i>
                          </template>
                        </el-table-column> 
                        <el-table-column label="调拨单号">
                          <template slot-scope="{ row }">
                             <span>{{row.factoryAllocationCode}}</span>
                          </template>
                        </el-table-column>
                        <!-- <el-table-column label="操作" >
                          <template slot-scope="{ row }">
                            <el-button class="el-icon-delete" type="danger" circle size="mini" title="删除" @click.stop="factorychilddel(row,$index)"></el-button>
                          </template>
                        </el-table-column> -->
                      </el-table>
                    </template>
                  </el-table-column>
                 
                  <el-table-column label="订单编号">
                    <template slot-scope="{ row }">
                      {{ row.poId }}
                    </template>
                  </el-table-column>
                  <el-table-column label="供应商">
                    <template slot-scope="{ row }">
                      {{ row.supplierShortName }}
                      <!-- {{ row.supplierName }} -->
                    </template>
                  </el-table-column>
                  <el-table-column label="付款状态">
                    <template slot-scope="{ row }">
                      {{actionsTypeHandle(row.paidStatus)}}
                    </template>
                  </el-table-column>
                   <el-table-column label="订单创建时间">
                    <template slot-scope="{ row }">
                      {{ row.poCreateTime }}
                    </template>
                  </el-table-column>
                  <!-- <el-table-column label="操作">
                    <template slot-scope="{ row, $index }">
                      <el-button class="el-icon-delete" type="danger" circle size="mini" title="删除" @click.stop="factorydel($index,row)"></el-button>
                    </template>
                  </el-table-column> -->
                </el-table>
              </div>

              <div v-else>
                <el-table
                  class="table_list"
                  :ref="multipleTable"
                  :data="requestList"
                  row-key="finalPaymentCode"
                  :expand-row-keys="expands"
                  @row-click="rowClickApplyfor"
                  @expand-change="rowClickApplyfor"
                  border
                  fit
                  stripe>
                  <el-table-column type="expand">
                    <template slot-scope="scope">
                      <el-table
                        class="table_list"
                        :ref="'childMultipleTable_' + scope.row.id"
                        :data="scope.row.finalPayments"
                        border
                        fit>
                        <el-table-column fixed label="PO行号">
                          <template slot-scope="{ row }">
                            {{ row.purchaseOrderSn }}
                          </template>
                        </el-table-column>
                        <el-table-column fixed label="订单编号">
                          <template slot-scope="{ row }">
                            {{ row.poId}}
                          </template>
                        </el-table-column>
                        <el-table-column label="物料编号" min-width="180" class-name="marpno_wuliao" :show-overflow-tooltip="true">
                          <template slot-scope="{ row }">
                            {{ row.materialCode }}
                          </template>
                        </el-table-column>
                        <el-table-column  label="供应商">
                          <template slot-scope="{ row }">
                            {{ row.supplierShortName }}
                            <!-- {{ row.supplierName }} -->
                          </template>
                        </el-table-column>
                        <el-table-column  label="确认书号">
                          <template slot-scope="{ row }">
                            {{ row.confirmingCode}}
                          </template>
                        </el-table-column>
                        <el-table-column label="确认书数量">
                          <template slot-scope="{ row }">
                            <span v-if="row.purchaseMeasurementUnit == '米'">{{ (row.confirmPackages ? row.confirmPackages : 0) + '包 ' + (row.packSpecification ? row.packSpecification : 0) + '米/包 ' + (row.confirmQuantity ? row.confirmQuantity : 0) + '米' }}</span>
                            <span v-else>{{ (row.confirmQuantity ? row.confirmQuantity : 0) + (row.purchaseMeasurementUnit ? row.purchaseMeasurementUnit : '条') }}</span>
                          </template>
                        </el-table-column>
                        <el-table-column label="货妥数量" >
                          <template slot-scope="{ row }">
                            <span v-if="row.purchaseMeasurementUnit == '米'">{{ (row.readyBagQuantity ? row.readyBagQuantity : 0) + '包 ' + (row.packSpecification? row.packSpecification: 0) + '米/包 ' + (row.readyMeter ? row.readyMeter : 0) + '米' }}</span>
                            <span v-else>{{ (row.readyMeter ? row.readyMeter : 0) + (row.purchaseMeasurementUnit ? row.purchaseMeasurementUnit : '条') }}</span>
                          </template>
                        </el-table-column>
                        <el-table-column label="申请付款数量" >
                          <template slot-scope="{ row }">
                            <span v-if="row.purchaseMeasurementUnit == '米'">{{ (row.applyPaidBagQuantity? row.applyPaidBagQuantity: 0) + '包 ' + (row.applyPaidBagMeter ? row.applyPaidBagMeter : 0) + '米/包 ' + (row.applyPaidMeter ? row.applyPaidMeter : 0) + '米' }}</span>
                            <span v-else>{{ (row.applyPaidMeter ? row.applyPaidMeter : 0) + (row.purchaseMeasurementUnit ? row.purchaseMeasurementUnit : '条') }}</span>
                          </template>
                        </el-table-column>
                        <el-table-column  label="已付数量">
                          <template slot-scope="{ row }">
                          <span v-if="row.purchaseMeasurementUnit == '米'">{{ (row.paidBagQuantity? row.paidBagQuantity: 0) + '包 ' + (row.paidMeter ? row.paidMeter : 0) + '米' }}</span>
                            <span v-else>{{ (row.paidMeter? row.paidMeter: 0) + (row.purchaseMeasurementUnit ? row.purchaseMeasurementUnit : '条') }}</span>
                          </template>
                        </el-table-column>
                        <el-table-column  label="待付数量">
                          <template slot-scope="{ row }">
                            <span v-if="row.purchaseMeasurementUnit == '米'">{{ (row.waitPaidBagQuantity? row.waitPaidBagQuantity: 0) + '包 ' + (row.waitPaidMeter ? row.waitPaidMeter : 0) + '米' }}</span>
                            <span v-else>{{ (row.waitPaidMeter ? row.waitPaidMeter : 0) + (row.purchaseMeasurementUnit ? row.purchaseMeasurementUnit : '条') }}</span>
                          </template>
                        </el-table-column>
                        <el-table-column label="最终发货" >
                          <template slot-scope="{ row }">
                            <i style v-if="row.isApplication==true">{{row.isFinalDelivery==true?"是":"否"}}</i>
                          </template>
                        </el-table-column>
                      </el-table>
                    </template>
                  </el-table-column>
                  <el-table-column label="尾款付款单号">
                    <template slot-scope="{ row }">
                      {{ row.finalPaymentCode }}
                    </template>
                  </el-table-column>
                  <el-table-column label="形式发票号">
                    <template slot-scope="{ row }">
                      {{ row.proformaInvoiceNum}}
                    </template>
                  </el-table-column>
                  <el-table-column label="付款状态" >
                    <template slot-scope="{ row }">
                      {{ row.paidStatusName}}
                    </template>
                  </el-table-column>
                  <el-table-column label="申请付款时间">
                    <template slot-scope="{ row }">
                      {{ row.applicantTime}}
                    </template>
                  </el-table-column>
                  <el-table-column label="申请人" >
                    <template slot-scope="{ row }">
                      {{ row.applicantName }}
                    </template>
                  </el-table-column>
                   <el-table-column label="凭证状态">
                    <template slot-scope="{ row, $index }">
                      <el-popover
                        placement="right"
                        width="300"
                        trigger="hover"
                        open-delay="1000"
                        @show="checkApprovalProcess(row, $index)">
                        <div>
                          <i class="block">
                            审批人：
                            <em>{{ row.fullName }}</em>
                          </i>
                          <i class="block mt10 mb10">
                            审批时间：
                            <em>{{ row.approvalTime }}</em>
                          </i>
                          <i class="block">
                            审批意见：
                            <em>{{ row.content }}</em>
                          </i>
                        </div>
                        <el-tag slot="reference" class="pointer" :type="row.auditStatus | statusFilter">{{ row.auditStatusName }}</el-tag>
                      </el-popover>
                    </template>
                  </el-table-column>
                  <el-table-column label="操作">
                    <template slot-scope="{ row, $index }">
                      <div v-if="row.auditStatus == 'Draft' || row.auditStatus == 'Rejected'">
                        <el-button class="el-icon-edit" type="primary" circle size="mini" title="编辑" @click.stop="factoryupd($index,row)"></el-button>
                        <el-button class="el-icon-check" type="success" circle size="mini" title="提交审批" @click.stop="draftoutsave($index,row)"></el-button>
                        <el-button class="el-icon-delete" type="danger" circle size="mini" title="删除" @click.stop="factorydel($index, row)"></el-button>
                      </div>
                      <div v-if="row.auditStatus == 'Terminated'">
                        <el-button class="el-icon-delete" type="danger" circle size="mini" title="删除" @click.stop="factorydel($index,row)"></el-button>
                      </div>
                    </template>
                  </el-table-column>
                  
                </el-table>
              </div>

            </el-tab-pane>
          </el-tabs>

          <div class="pagination_wrap fr">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page.sync="currentPage"
              :page-sizes="[10, 50]"
              :page-size="pageSize"
              layout="sizes, total, prev, pager, next"
              :total="total"
            ></el-pagination>
          </div>
        </div>

    </div>

    <div class="paymentRequest" v-if="ifaddsure==true">
      <!--<p>尾款付款申请请求</p>-->
      <finalPaymentadd ref="RefChilde" :checkArr="checkArr" :operationTag="operationTag" v-on:cancel="cancel"></finalPaymentadd>
    </div>

    <!--删除-->
    <el-dialog
      title="提示"
      v-dialogDrag
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :visible.sync="dialogdelVisible"
      width="30%"
    >
      <span>{{this.marked}}</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogdelVisible = false">取 消</el-button>
        <el-button type="primary" @click="delVisibleclick()" :loading="saveloading">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { purchasegetuuid, finalPaymentSerch, finalPaymentchilddel, finalPaymentdel, finalDraftoutSave,finalUpddata,finalPaymentsave} from "@/api/module/finalPaymentApi.js";
import { finalPaymentMethod } from "@/api/module/dictionariesApi.js";
import { supplierDictList, approvalProcessInfo } from "@/api/publicApi.js";
import { timeFormat, dateToStr, cutOutNum,getTimeRange} from "@/utils/index.js";
import { currencyTypeHandle, statusTypeHandle } from '@/utils/public.js';
import Table from "@/layouts/components/common/Table.vue";
import finalPaymentadd from "../finalPaymentadd/Index";
export default {
  name: "finalPaymentRequest",
  components: { 
    finalPaymentadd,
    Table
  },
  data() {
    return {
      popupTitle:"物料编号修改",
       // NEW TBALE组件列表
      multipleSelection: [],
      childMultipleSelection: [],
      listLoading: false,
      selectUuidList: [],
      checkedIdList: [],
      checkedUuidList: [],
      expands:[],
      tableLoading: true,
      materialCode: "",
      purchaseOrderCode: "",
      supplierCode: "",
      supplierName: "",
      paidStatus: "",
      creatTime: "",
      starttime: "", //开始时间
      endtime: "", //结束时间
      total: 0,
      pageSize: 10, //每页显示条数
      currentPage: 1, //页数
      activeName: "1",
      finalPaymentStatus: "",
      deltag: "",
      marked: "",
      finalPaymentCode: "",
      operationTag: "",
      finalAuditStatus:'',
      checkList: [],
      checkAllList: [],
      checkArr: [],
      CheckedAllArr: [],
      delData: [],
      submitError:true,
      saveloading:false,
      ifaddsure: false,
      checkAll: false,
      isIndeterminate: true,
      isCheckedAll: false, //全选判断标识
      checkAllFlag: false,
      dialogdelVisible: false,
      isApplication: false, //是否发起付款
      paymentList: [],
      supplierList: [],
      typeList: [
        {
          index: 0,
          name: "代付款"
        },
        {
          index: 1,
          name: "已付款"
        },
        {
          index: 2,
          name: "付款中"
        }
      ],
      panelist: [
        {
          index: "1",
          status: "",
          name: "尾款付款发起"
        },
        {
          index: "2",
          status: "",
          name: "全部尾款付款申请"
        },
        {
          index: "3",
          status: "Draft",
          name: "草稿"
        },
        {
          index: "4",
          status: "Approving",
          name: "审批中"
        },
        {
          index: "5",
          status: "Approved",
          name: "已通过"
        },
        {
          index: "6",
          status: "Rejected",
          name: "已驳回"
        },
        // {
        //   index: "7",
        //   status: "Terminated",
        //   name: "已终止"
        // }
      ],
      orderFieldsList: [
        {
          name: '订单编号',
          class: 'w8p',
        },
        {
          name: '物料编号',
          class: 'w8p',
        },
        {
          name: '供应商',
          class: 'w8p',
        },
        {
          name: '确认书号',
          class: 'w7p',
        },
        {
          name: '确认书数量',
          class: 'w15p',
        },
        {
          name: '货妥数量',
          class: 'w15p',
        },
        {
          name: '申请付款数量',
          class: 'w15p',
        },
        {
          name: '已付数量',
          class: 'w8p',
        },
        {
          name: '待付数量',
          class: 'w8p'
        },
        {
          name:'最终发货',
          field: '',
          class: 'w8p',
        }
      ],
      parentOrderFieldsList: [  // Table父列表表头
        {
          name: '申请付款时间',
          class: 'w8p'
        },
        {
          name: '申请人',
          class: 'w4p'
        },
        {
          name: '尾款付款单号',
          class: 'w12p'
        },
        {
          name: '形式发票号',
          class: 'w6p'
        },
        {
          name: '付款状态',
          class: 'w6p'
        },
        {
          name: '凭证状态',
          class: 'w12p'
        }
      ],
      requestList: [],
      parentData: {
        status: '',
        data: [
          {
            name: '申请付款时间',
            field: 'applicantTime',
            class: 'w8p',
            isShowTit: true
          },
          {
            name: '申请人',
            field: 'applicantName',
            class: 'w4p',
            isShowTit: true
          },
          {
            name: '尾款付款单号',
            field: 'finalPaymentCode',
            class: 'w12p',
            isShowTit: true
          },
          {
            name: '形式发票号',
            field: 'proformaInvoiceNum',
            class: 'w6p',
            isShowTit: true
          },
          {
            name: '付款状态',
            field: 'paidStatusName',
            class: 'w6p',
            isShowTit: true
          },
          {
            name: '审批状态',
            field: '',
            class: 'w12p',
            isShowTit: false,
            isPane:true
          },
          {
            name: '',
            field: '',
            class: 'fr mr15',
            isShowTit: false,
            isUpDown: true
          },
          {
            name: '',
            field: '',
            class: 'fr',
            isShowTit: false,
            isAction: true
          }
        ]
      },
      childData: {
        name: 'finalPayments',
        data: [
          {
            name: '',
            field: 'poId',
            class: 'w8p'
          },
          {
            name: '',
            field: 'materialCode',
            class: 'w8p'
          },
          {
            name: '',
            field: 'supplierName',
            class: 'w8p'
          },
          {
            name: '',
            field: 'confirmingCode',
            class: 'w7p'
          },
          {
            name: '',
            field_1: 'confirmPackages',
            field_2: 'packSpecification',
            field_3: 'confirmQuantity',
            field: 'confirmQuantity',
            field_unit: 'purchaseMeasurementUnit',
            class: 'w15p',
            isQuantityField: true
          },
          {
            name: '',
            field_1: 'readyBagQuantity',
            field_2: 'packSpecification',
            field_3: 'readyMeter',
            field: 'readyMeter',
            field_unit: 'purchaseMeasurementUnit',
            class: 'w15p',
            isQuantityField: true
          },
          {
            name: '',
            field_1: 'applyPaidBagQuantity',
            field_2: 'applyPaidBagMeter',
            field_3: 'applyPaidMeter',
            field: 'applyPaidMeter',
            field_unit: 'purchaseMeasurementUnit',
            class: 'w15p',
            isQuantityField: true
          },
          {
            name: '',
            field_1: 'paidBagQuantity',
            field_2: 'paidMeter',
            field: 'paidMeter',
            field_unit: 'purchaseMeasurementUnit',
            class: 'w8p',
            isPurchase: true
          },
          {
            name: '',
            field_1: 'waitPaidBagQuantity',
            field_2: 'waitPaidMeter',
            field: 'waitPaidMeter',
            field_unit: 'purchaseMeasurementUnit',
            class: 'w8p',
            isPurchase: true
          },
          {
            name: '',
            field: '',
            isApplication:true,
            class: 'w8p'
          },
        ]
      },
    };
  },
  mounted() {
    this.finalPaymentStatus = "";
    this.getSupplierDictList();
    // this.finalPaymentList();
    this.getPaymentstatus();
    this.fun_date();
  },
  methods: {
    checkApprovalProcess(item, index) {
      console.log(item, index)
      approvalProcessInfo(item.finalPaymentCode).then(res => {
        console.log('审批流程：', res);
        if (res.code === '0') {
          let data = res.data[res.data.length - 1];
          if (data == undefined) {
            item.fullName = '';
            item.approvalTime = '';
            item.content = '';
          } else {
            item.fullName = data.fullName;
            item.approvalTime = data.approvalTime;
            item.content = data.content;
          }
          this.$set(this.requestList, index, item);
          this.$forceUpdate();
        }
      });
    },
    
    // 固定展示为三月前的时间范围
    fun_date() {
      this.creatTime=getTimeRange(-90);
      this.searchHandle();
    },
    rowClick(row, event, column){
      //单击事件(尾款发起)
      console.log('rowClick',row,event,column);
      Array.prototype.remove = function (val) {
        let index = this.indexOf(val);
        if (index > -1) {
          this.splice(index, 1);
        }
      };
      if (this.expands.indexOf(row.poId) < 0) {
        this.expands.push(row.poId);
      } else {
        this.expands.remove(row.poId);
      }
      //是否选中
      if(row.selectcheck){
        for (let i = 0; i < row.finalPayments.length; i++) {
            let cItem = row.finalPayments[i];
            if(cItem.childSelectcheck==true){
              console.log('进入rowClick判断')
                this.$nextTick(() => {
                  if (this.$refs['childMultipleTable_' + row.poId] && this.$refs['childMultipleTable_' + row.poId].length) {
                    this.$refs['childMultipleTable_' + row.poId][0].toggleRowSelection(cItem, true);
                  }
                });
            } 
        }
      }
    },
    rowClickApplyfor(row, event, column){
       //单击事件
      console.log('rowClickApplyfor',row,event,column);
      Array.prototype.remove = function (val) {
        // console.log(val)
          let index = this.indexOf(val);
          if (index > -1) {
              this.splice(index, 1);
          }
      };
      if (this.expands.indexOf(row.finalPaymentCode) < 0) {
          this.expands.push(row.finalPaymentCode);
      }else {
          this.expands.remove(row.finalPaymentCode);
      }
    },
    checkStatusProcessing(item,row,ifCheck,status){
          console.log('选中状态处理',item,row)
          if(ifCheck){
            for (let j = 0; j < row.length; j++) {
              let cItem = row[j];
              cItem.childSelectcheck=true;
              this.childMultipleSelection.push(cItem);
              this.$nextTick(() => {
                if (this.$refs['childMultipleTable_' + item.poId] && this.$refs['childMultipleTable_' + item.poId].length) {
                  this.$refs['childMultipleTable_' + item.poId][0].toggleRowSelection(cItem, true);
                }
              });
            }
          }else {
             for (let i = 0; i < row.length; i++) {
                let cItem =row[i];
                cItem.childSelectcheck=true;
                this.$nextTick(() => {
                  if (this.$refs['childMultipleTable_' + item.poId] && this.$refs['childMultipleTable_' + item.poId].length) {
                    this.$refs['childMultipleTable_' + item.poId][0].toggleRowSelection(cItem, false);
                  }
                });
                if(status=='selectHandle'){
                  this.childMultipleSelection = this.childMultipleSelection.filter(cmsItem => {
                    return cmsItem.uuid !== cItem.uuid;
                  });
                }
             }
          }
    },
    selectAllHandle(selection) {
      // 父项全选事件
      console.log('触发全选事件：', selection);
       this.checkArr=selection;
      this.selectUuidList = [];
      this.childMultipleSelection = [];
      this.multipleSelection = selection;
      
      if (selection && selection.length) {
        for (let i = 0; i < selection.length; i++) {
          let item = selection[i];
          item.selectcheck=true;
          this.selectUuidList.push(item.poId);
          this.checkStatusProcessing(item,item.finalPayments,true,'selectAllHandle');//选中状态处理
        }
      } else {
        for (let i = 0; i < this.requestList.length; i++) {
          let item = this.requestList[i];
          item.selectcheck=false;
          this.checkStatusProcessing(item,item.finalPayments,false,'selectAllHandle');//选中状态处理
        }
      }
    },
    selectHandle(selection, row) {
      console.log('当用户手动勾选数据行的 Checkbox 时触发事件：', selection, row);
      let _row = row;
      this.selectUuidList = [];
      this.multipleSelection = selection;
      this.checkArr=selection;
      if (selection && selection.length) {
        // await this.$nextTick();
        row.selectcheck=true;
        for (var i = 0; i < selection.length; i++) {
          let item = selection[i];
          this.selectUuidList.push(item.poId);
        }
        if (this.selectUuidList.indexOf(_row.poId) > -1) {
          // 当前项选中状态
          this.checkStatusProcessing(_row,_row.finalPayments,true,'selectHandle');
        } else {
          this.checkStatusProcessing(_row,_row.finalPayments,false,'selectHandle');
        }
      } else {
        // 未选中 需删除该父项下所有子项
        row.selectcheck=false;
        console.log(this.$refs['childMultipleTable_' + _row.poId],this.multipleSelection)
        if(this.$refs['childMultipleTable_' + _row.poId]){
           this.$refs['childMultipleTable_' + _row.poId][0].clearSelection();
        }
        for (let i = 0; i < row.finalPayments.length; i++) {
          let item = row.finalPayments[i];
          if (this.arrContainObjHandle(this.childMultipleSelection, item) != -1) {
            let index = this.childMultipleSelection.findIndex(x => x.uuid == item.uuid);
            this.childMultipleSelection.splice(index, 1);
          }
        }
      }
    },
     expandChange(row, rowList) {
      console.log('树形展开项：', row, rowList);
      this.rowClick(row);
    },
    async childSelectHandle(selection, row, parentRow) {
      // 选择子项
      console.log('选择子项：', selection, row, parentRow);
      let uuidList = [];
      for (var i = 0; i < selection.length; i++) {
        let item = selection[i];
        uuidList.push(item.uuid);
      }
      if (uuidList.indexOf(row.uuid) > -1) {
        // 当前项选中状态
        if (this.arrContainObjHandle(this.childMultipleSelection, row) === -1) {
          this.childMultipleSelection.push(row);
        }
      } else {
        // 取消选中
        if (this.arrContainObjHandle(this.childMultipleSelection, row) != -1) {
          let index = this.childMultipleSelection.findIndex(x => x.uuid == row.uuid);
          this.childMultipleSelection.splice(index, 1);
        }
      }
      await this.$nextTick();
      if (selection.length === parentRow.finalPayments.length) {
        // 子项选择个数与当前列表子项个数一致触发当前父项数据全选
        this.$nextTick(() => {
          if (this.$refs.multipleTable && this.$refs.multipleTable.length) {
            this.$refs.multipleTable[0].toggleRowSelection(parentRow, true);
          }
        });
      }
      this.$refs.multipleTable[0].toggleRowSelection(parentRow, false);
      this.checkArr=this.childMultipleSelection;     
      console.log('345555555555子勾选项空间的：', this.childMultipleSelection,this.checkArr);
    },
    async childSelectAllHandle(selection, parentRow) {
      // 子项全选
      console.log('子项全选操作：', selection, parentRow);
      await this.$nextTick();
      if (selection && selection.length) {
        this.$nextTick(() => {
          if (this.$refs.multipleTable && this.$refs.multipleTable.length) {
            this.$refs.multipleTable[0].toggleRowSelection(parentRow, true);
          }
        });
        for (let i = 0; i < selection.length; i++) {
          let item = selection[i];
          if (this.arrContainObjHandle(this.childMultipleSelection, item) === -1) {
            this.childMultipleSelection.push(item);
          }
        }
      } else {
        for (let i = 0; i < parentRow.finalPayments.length; i++) {
          let item = parentRow.finalPayments[i];
          if (this.arrContainObjHandle(this.childMultipleSelection, item) != -1) {
            let index = this.childMultipleSelection.findIndex(x => x.uuid == item.uuid);
            this.childMultipleSelection.splice(index, 1);
          }
        }
        this.$refs.multipleTable[0].toggleRowSelection(parentRow, false);
      }
    },
    arrContainObjHandle(arr, obj) {
      var index = arr.findIndex(item => item.uuid == obj.uuid);
      return index;
    },
    //付款状态
    getPaymentstatus() {
      // finalPaymentMethod().then(response => {
      //   if (response.code === "0") {
      //     this.paymentList = response.data;
      //   }
      // });
      this.paymentList=finalPaymentMethod().data
    },
    //列表分页接口
    finalPaymentList() {
      let data = {
        materialCode: this.materialCode,
        auditStatus: this.finalPaymentStatus,
        endTime: this.endtime,
        isApplication: this.isApplication,
        paidStatus: this.paidStatus,
        poId: this.purchaseOrderCode,
        startTime: this.starttime,
        // supplierName: this.supplierName,
        supplierCode: this.supplierCode
      };
      finalPaymentSerch(this.currentPage, this.pageSize, data).then(res => {
        console.log('尾款申请列表：', res);
        if (res.code == "0") {
          this.total = res.data.total;
          this.requestList = res.data.records;
          this.requestList.forEach((item, index) => {
            item.paidStatusName = this.actionsTypeHandle(item.paidStatus);
            item.isApplication = this.isApplication;
           item.auditStatusName= statusTypeHandle(item.auditStatus);
            switch (item.auditStatus) {
              case 'Draft':
                item.actions = {
                  hasEdit: true,
                  hasSubmit: true,
                  hasDelete: true
                };
                break;
              case 'Rejected':
                item.actions = {
                  hasEdit: true,
                  hasSubmit: true,
                  hasDelete: true
                };
                break;
              case 'Terminated':
                item.actions = {
                  hasDelete: true
                };
                break;
              default:
                item.actions = {};
                break;
            }
            if (item.poCreateTime != null) {
              item.poCreateTime = timeFormat(item.poCreateTime);
              item.poCreateTime = item.poCreateTime.substring(0, 10);
            }
            if (item.applicantTime != null) {
              item.applicantTime = timeFormat(item.applicantTime);
              item.applicantTime = item.applicantTime.substring(0, 10);
            }
            item.finalPayments.forEach((i, index) => {
              item.auditStatus = i.auditStatus;
            });
            if (item.isApplication == false) {
              item.auditStatus = "";
            }
            item.currency ? item.currencyName = currencyTypeHandle(item.currency) : item.currencyName = '';
            item.finalPayments.forEach((val, index) => {
              val.currency ? val.currencyName = currencyTypeHandle(val.currency) : val.currencyName = '';
            })
          });
          this.tableLoading = false;
        } else {
          this.tableLoading = false;
        }
      });
      setTimeout(() => {
        this.tableLoading = false;
      }, 10000);
    },
    //付款状态
    actionsTypeHandle(status) {
      if (status != null) {
        for (var i = 0; i < this.paymentList.length; i++) {
          let item = this.paymentList[i];
          if (item.index == status) {
            return item.name;
          }
        }
      }
    },
    approveTypeHandle(status) {
      if (status != null) {
        for (var i = 0; i < this.panelist.length; i++) {
          let item = this.panelist[i];
          if (item.status == status) {
            return item.name;
          }
        }
      }
    },
    showlist(index, item) {
      item.ifshowlist = !item.ifshowlist;
      this.$forceUpdate();
    },
    arrayProcessing(){
     //进行数组处理
      let processingArray=JSON.parse(JSON.stringify(this.childMultipleSelection));
      var echararr = [];
      //整合数组拆分
      for (var i = 0; i < processingArray.length; i++) {
          let val=processingArray[i];
          var arrindex=echararr.findIndex((item) => {return item.poId === val.poId})//获取下标
          if(arrindex==-1){//不存在盘符数据的添加盘符数据
              echararr.push({
                  "poId":val.poId,
                  "supplierShortName":val.supplierShortName,
                  "allocationCargoValueTotal":val.allocationCargoValue,
                  "currencyName":val.currencyName,
                  "applyRatio":val.applyRatio,
                  "proformaInvoiceNum":val.proformaInvoiceNum,
                  "isFinalDelivery":false,//最终发货默认false
                  "finalPayments": [val]
              });
          }else{//有盘符则往盘符数据中添加
              echararr[arrindex].finalPayments.push(val)
          }
      }
      echararr.map(arr => {
        let totalPrice = 0
        arr.allocationCargoValueTotal=arr.finalPayments.reduce((totalPrice, item) => totalPrice + item.allocationCargoValue, 0)
      })
      return echararr
    },
    createApplyFor() {
      //生成付款申请
      console.log('生成付款申请',this.checkArr,this.childMultipleSelection)
      this.checkArr=this.arrayProcessing();
      console.log('this.checkArr',this.checkArr);    
      if (this.checkArr.length == 0) {
        this.$message({
          message: "请选择要生成的尾款付款请求",
          type: "error"
        });
      }else{
      	//判断供应商不同不能生成付款申请
      	if(this.checkArr.length==1){
      		this.operationTag = "add";
          this.ifaddsure = true;
      	}else{     		
      		for(var i=0;i<this.checkArr.length;i++){
		      	let item=this.checkArr[i];
		      	for(var j=i+1; j<this.checkArr.length; j++){
			      	 let jitem=this.checkArr[j];
			            if(item.supplierShortName!=jitem.supplierShortName){
			            	 this.$message({
								          message: "不同的供应商不能生成同一个付款申请单!!",
								          type: "error"
								        });
			              return false;
			            }else{
			            	this.operationTag = "add";
	                  this.ifaddsure = true;
			            }
			      }       
		      }       		
      	}
      }

    },
    searchHandle() {
      //搜索
      if (this.creatTime != null && this.creatTime != "") {
        this.starttime = this.creatTime[0];
        this.endtime = this.creatTime[1];
      } else {
        this.starttime = "";
        this.endtime = "";
      }
      this.finalPaymentList();
    },
    //全选
    handleCheckAllChange(event) {
      let arr = [];
      console.log(!event);
      if (!event) {
        arr = [];
      } else {
        arr = this.requestList;
      }
      this.checkArr = event ? arr : [];
      console.log(this.checkArr);
      console.log(this.isCheckedAll);
      console.log(this.checkAllFlag);
    },
    //数组去重
    unique(array) {
      var temp = []; //一个新的临时数组
      for (var i = 0; i < array.length; i++) {
        if (temp.indexOf(array[i]) == -1) {
          temp.push(array[i]);
        }
      }
      return temp;
    },
    //单选
    handleChecked() {
      this.isCheckedAll = this.checkArr.length == this.requestList.length;
      this.checkAllFlag = this.checkArr.length == this.requestList.length;
      console.log(this.isCheckedAll);
      console.log(this.checkAllFlag);
      console.log(this.checkArr);
    },
    //取消
    cancel(submitType) {
      console.log(submitType);
      this.ifaddsure = false;
      this.checkAllFlag = false;
      this.isCheckedAll = false;
      this.checkArr = [];
      this.childMultipleSelection=[];
      if (submitType == 1) {
        this.finalPaymentStatus = "Draft";
        this.isApplication = true;
        this.activeName = "3";
      } else if (submitType == 2) {
        this.finalPaymentStatus = "Approving";
        this.isApplication = true;
        this.activeName = "4";
      } else if (submitType == 3) {
        this.finalPaymentStatus = "Rejected";
        this.isApplication = true;
        this.activeName = "6";
      } else if (submitType == 4) {
        this.finalPaymentStatus = "Approving";
        this.isApplication = true;
        this.activeName = "4";
      }
      this.finalPaymentList();
    },
    //提交审批
    draftoutsave(item, index) {
      var item=index;
      var index=item;
      console.log(item);
      this.marked = "确定提交审批？";
      this.dialogdelVisible = true;
      this.deltag = "draftsave";
      this.finalPaymentCode = item.finalPaymentCode;
      this.finalAuditStatus=item.auditStatus;
    
      finalUpddata(item.finalPaymentCode,item.auditStatus).then(response => {
        if (response.code === "0") {
          console.log(response.data)
          // for(var i=0;i<response.data.length;i++){
          //   let data=response.data[i];
          //   data.finalNameList=data.finalPayments;
          // }
          this.checkArr=response.data;
          console.log(this.checkArr)
          this.$forceUpdate();
        }
      });

    },
    //子项删除
    factorychilddel(item, index) {
      this.marked = "确定删除？";
      this.dialogdelVisible = true;
      this.deltag = "childDel";
      this.delid = item.id;
    },
    //父项删除
    factorydel(index, item) {
      // var item=index;
      // var index=item;
      console.log(index,item);
      this.marked = "确定删除？";
      this.dialogdelVisible = true;
      this.deltag = "parentDel";
      let arr = [];
      item.finalPayments.forEach(i => {
        arr.push(i.id);
      });
      this.delData = arr;
      console.log(arr);
    },
    //弹框点击确认
    delVisibleclick() {
      console.log(this.deltag);
      if (this.deltag == "childDel") {
        console.log("子项删除");
        finalPaymentchilddel(this.delid).then(response => {
          if (response.code === "0") {
            this.dialogdelVisible = false;
            this.$message({
              message: "删除成功",
              type: "success"
            });
            this.finalPaymentList();
          }
        });
      } else if (this.deltag == "parentDel") {
        finalPaymentdel(this.delData).then(response => {
          if (response.code === "0") {
            this.dialogdelVisible = false;
            this.$message({
              message: "删除成功",
              type: "success"
            });
            this.finalPaymentList();
          }
        });
      } else if (this.deltag == "draftsave") {
        // this.saveloading=true;
        // finalDraftoutSave(this.finalPaymentCode).then(response => {
        //   if (response.code === "0") {
        //     this.saveloading=false;
        //     this.dialogdelVisible = false;
        //     this.$message({
        //       message: "提交审批成功",
        //       type: "success"
        //     });
        //     this.finalPaymentList();
        //   }else{
        //     this.saveloading=false;
        //     this.dialogdelVisible = false;
        //   }
        // });
        console.log('外侧提交')
        this.saveloading=true;
        this.toExamine("loadingcommit");
        if (this.checkArr[0].finalPayments[0].auditStatus == "Rejected") {
          this.submitType = 4;
        } else {
          this.submitType = 2;
        }
        console.log(this.checkArr);
        console.log(this.submitError)
        if (this.submitError!= false) {
          console.log('通过')
          this.save("loadingcommit");
        }
 
      }
    },
    //保存
    save(tag) {
      finalPaymentsave(this.submitType, this.checkArr).then(response => {
        if (response.code === "0") {
            this.saveloading = false;
            this.$message({
              message: "提交审批成功",
              type: "success"
            });
            this.dialogdelVisible = false;
           this.cancel(this.submitType);
        }else{
          this.saveloading=false;
          this.dialogdelVisible = false;
        }
      });
    },
    toExamine(tag){
      this.checkArr.forEach((item, index) => {
        item.applicant = this.$store.getters.getUserName;
        item.applicantDepartment = this.$store.getters.getdepartmentId;
        item.applicantName = this.$store.state.realName;
        item.applicantDepartmentName = this.$store.state.departments[0].name;
        console.log(item.applyRatio);
        if(item.applyRatio == ""||item.applyRatio == null) {
          this.$message({
            message: "付款比例不能为空！",
            type: "error"
          });
          this.saveloading=false;
          this.submitError = false;
          return false;
        }
        if(!item.proformaInvoiceUuid){
          this.$message({
            message: "请上传形式发票！",
            type: "error"
          });
          this.saveloading=false;
          this.submitError = false;
          return false;
        }
      });
     
    },
    //编辑
    factoryupd(item, index) {
      var item=index;
      var index=item;
      console.log(item)
      this.ifaddsure = true;
      // this.checkArr = [item];
      this.operationTag = "upd";
      console.log(item);
      finalUpddata(item.finalPaymentCode,item.auditStatus).then(response => {
        if (response.code === "0") {
          console.log(response.data)
          // for(var i=0;i<response.data.length;i++){
          //   let data=response.data[i];
          //   data.finalNameList=data.finalPayments;
          // }
          this.checkArr=response.data;
          console.log(this.checkArr)
          this.$forceUpdate();
        }
      });
    },
    handleClick(tab, event) {
      this.tableLoading = true;
      this.checkArr=[];
      switch (tab.name) {
        case "1":
          this.finalPaymentStatus = "";
          this.isApplication = false;
          this.finalPaymentList();
          break;
        case "2":
          this.finalPaymentStatus = "";
          this.isApplication = true;
          this.finalPaymentList();
          break;
        case "3":
          this.finalPaymentStatus = "Draft";
          this.isApplication = true;
          this.finalPaymentList();
          break;
        case "4":
          this.finalPaymentStatus = "Approving";
          this.isApplication = true;
          this.finalPaymentList();
          break;
        case "5":
          this.finalPaymentStatus = "Approved";
          this.isApplication = true;
          this.finalPaymentList();
          break;
        case "6":
          this.finalPaymentStatus = "Rejected";
          this.isApplication = true;
          this.finalPaymentList();
          break;
        case "7":
          this.finalPaymentStatus = "Terminated";
          this.isApplication = true;
          this.finalPaymentList();
          break;
        default:
          break;
      }
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.finalPaymentList();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.finalPaymentList();S
    },
    getSupplierDictList() {
      supplierDictList().then(res => {
        console.log('供应商列表：', res);
        if (res.code === '0') {
          this.supplierList = res.data;
        }
      });
    }
  },
  filters: {
    numFilter(value) {
      cutOutNum(value);
    },
    statusFilter(status) {
      const statusMap = {
        Draft: '',
        Approving: 'warning',
        Approved: 'success',
        Rejected: 'danger',
        Terminated: 'info',
        Close: 'danger'
      };
      return statusMap[status];
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../../styles/index.scss";
* {
  box-sizing: border-box;
}
.center {
  text-align: center;
}
.ml {
  margin-left: 10px;
}
.mr {
  margin-right: 10px;
}
.red {
  color: #f56c6c;
}
 .hiddenClass{
  	display: inline-block;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;
  }
// .finalPaymentRequest {
//   border-radius: 6px;
//   padding: 10px 0px;
//   background: #ffffff;
//   padding-bottom: 100px;
// }

.commoncontent {
  padding: 0;
}
ul.parentlist {
  width: 100%;
}
.container_table {
  .allparentlist {
    .childlist {
      width: 98%;
      margin-left: 2%;
    }
    .childcontentlist {
      width: 98%;
      margin-left: 2%;
    }
  }
  .parent_header_wrap {
    li {
      padding: 12px 10px;
    }
  }
  ul {
    li {
      outline: none;
    }
    &.childcontentlist {
      height: 60px;
      line-height: 40px;
    }
    &.childlist {
      li {
        cursor: pointer;
      }
    }
  }
}
</style>
<style>
.finalPaymentRequest .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #606266;
}
.finalPaymentRequest label.el-checkbox {
  width: auto !important;
}
.finalPaymentRequest .container_table .el-tabs .el-tab-pane {
  min-height: 550px;
}
.paymentRequest{
  min-height: 550px;
}
</style>

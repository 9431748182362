var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container finishedproduction" }, [
    _c(
      "div",
      { staticClass: "actions_part clearfix" },
      [
        _c("el-input", {
          staticClass: "input_single w200 mr10",
          attrs: { placeholder: "采购订单编号" },
          model: {
            value: _vm.orderCode,
            callback: function($$v) {
              _vm.orderCode = $$v
            },
            expression: "orderCode"
          }
        }),
        _c(
          "div",
          { staticClass: "down fr" },
          [
            _c("el-button", {
              attrs: {
                size: "small",
                type: "primary",
                icon: "el-icon-search",
                circle: "",
                title: "搜索"
              },
              on: { click: _vm.searchHandle }
            }),
            _c("el-button", {
              attrs: {
                size: "medium",
                type: "primary",
                icon: "el-icon-upload",
                circle: "",
                title: "上传"
              },
              on: { click: _vm.uploadHandle }
            }),
            _c("el-button", {
              attrs: {
                size: "small",
                type: "primary",
                icon: "el-icon-download",
                circle: "",
                title: "下载"
              },
              on: { click: _vm.downloadHandle }
            })
          ],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "table_part clearfix" },
      [
        _c(
          "el-tabs",
          {
            staticClass: "subnav_wrap",
            on: { "tab-click": _vm.switchTabHandle },
            model: {
              value: _vm.activeName,
              callback: function($$v) {
                _vm.activeName = $$v
              },
              expression: "activeName"
            }
          },
          [
            _c(
              "el-tab-pane",
              { attrs: { label: "全部成品生产订单", name: "All" } },
              [
                _c(
                  "ul",
                  { staticClass: "childlist" },
                  _vm._l(_vm.allFieldList, function(item, index) {
                    return _c("li", { key: index, class: item.class }, [
                      _vm._v(_vm._s(item.name))
                    ])
                  }),
                  0
                ),
                _vm._l(_vm.orderList, function(item, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "allparentlist" },
                    [
                      _c(
                        "ul",
                        {
                          staticClass: "parentlist",
                          on: {
                            click: function($event) {
                              return _vm.showChildList(index, item)
                            }
                          }
                        },
                        [
                          _c("li", [
                            _c("span", [_vm._v(_vm._s(item.createTime))])
                          ]),
                          _c("li", [
                            _c("i", [
                              _vm._v(
                                "\n                  订单编号:\n                  "
                              ),
                              _c("em", [_vm._v(_vm._s(item.purchaseOrderId))])
                            ])
                          ]),
                          _c("li", [
                            _c("i", [
                              _vm._v(
                                "\n                  请求状态:\n                  "
                              ),
                              _c("em", [_vm._v(_vm._s(item.orderStatus))])
                            ])
                          ]),
                          _c(
                            "li",
                            {
                              staticStyle: {
                                float: "right",
                                "margin-right": "15px"
                              },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.showChildList(index, item)
                                }
                              }
                            },
                            [
                              item.isShowList
                                ? _c("i", { staticClass: "el-icon-caret-top" })
                                : _c("i", {
                                    staticClass: "el-icon-caret-bottom"
                                  })
                            ]
                          )
                        ]
                      ),
                      _vm._l(item.poiList, function(childItem, index) {
                        return _c(
                          "ul",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: item.isShowList,
                                expression: "item.isShowList"
                              }
                            ],
                            key: index,
                            staticClass: "childcontentlist"
                          },
                          [
                            _c("li", { staticClass: "w10p" }, [
                              _vm._v("原材料物料编号")
                            ]),
                            _c("li", { staticClass: "w10p" }, [
                              _vm._v("成品物料号")
                            ]),
                            _c("li", { staticClass: "w10p" }, [
                              _vm._v("供应商名称")
                            ]),
                            _c("li", { staticClass: "w10p" }, [
                              _vm._v("成品采购价格")
                            ]),
                            _c("li", { staticClass: "w12p" }, [
                              _vm._v("成品数量")
                            ]),
                            _c("li", { staticClass: "w8p" }, [
                              _vm._v("原材料消耗数量")
                            ]),
                            _c("li", { staticClass: "w10p" }, [
                              _vm._v("工厂交期")
                            ]),
                            _c("li", { staticClass: "w10p" }, [
                              _vm._v("原材料确认书号")
                            ]),
                            _c("li", { staticClass: "w10p" }, [
                              _vm._v("原材料投产日期")
                            ]),
                            _c("li", { staticClass: "w10p" }, [
                              _vm._v("原材料价格")
                            ])
                          ]
                        )
                      })
                    ],
                    2
                  )
                })
              ],
              2
            ),
            _c(
              "el-tab-pane",
              { attrs: { label: "草稿", name: "Draft" } },
              [
                _c(
                  "ul",
                  { staticClass: "childlist" },
                  _vm._l(_vm.allFieldList, function(item, index) {
                    return _c("li", { key: index, class: item.class }, [
                      _vm._v(_vm._s(item.name))
                    ])
                  }),
                  0
                ),
                _vm._l(_vm.orderList, function(item, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "allparentlist" },
                    [
                      _c("ul", { staticClass: "parentlist" }, [
                        _c("li", [
                          _c("span", [_vm._v(_vm._s(item.createTime))])
                        ]),
                        _c("li", [
                          _c("i", [
                            _vm._v(
                              "\n                  订单编号:\n                  "
                            ),
                            _c("em", [_vm._v(_vm._s(item.purchaseOrderId))])
                          ])
                        ]),
                        _c("li", [
                          _c("i", [
                            _vm._v(
                              "\n                  请求状态:\n                  "
                            ),
                            _c("em", [_vm._v(_vm._s(item.orderStatus))])
                          ])
                        ]),
                        _c(
                          "li",
                          {
                            staticStyle: {
                              float: "right",
                              "margin-right": "15px"
                            },
                            on: {
                              click: function($event) {
                                return _vm.showChildList(index, item)
                              }
                            }
                          },
                          [
                            item.isShowList
                              ? _c("i", { staticClass: "el-icon-caret-top" })
                              : _c("i", { staticClass: "el-icon-caret-bottom" })
                          ]
                        ),
                        _c("li", { staticClass: "fr" }, [
                          _c("i", {
                            staticClass: "el-icon-delete",
                            attrs: { type: "primary", title: "删除" },
                            on: {
                              click: function($event) {
                                return _vm.deleteOrderHandle(index, item)
                              }
                            }
                          }),
                          _c("i", {
                            staticClass: "el-icon-edit",
                            attrs: { type: "primary", title: "修改" },
                            on: {
                              click: function($event) {
                                return _vm.editOrderHandle(index, item)
                              }
                            }
                          }),
                          _c("i", {
                            staticClass: "el-icon-view",
                            attrs: { type: "primary", title: "查看" },
                            on: {
                              click: function($event) {
                                return _vm.checkOrderHandle(index, item)
                              }
                            }
                          })
                        ])
                      ]),
                      _vm._l(item.poiList, function(childItem, index) {
                        return _c(
                          "ul",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: item.isShowList,
                                expression: "item.isShowList"
                              }
                            ],
                            key: index,
                            staticClass: "childcontentlist"
                          },
                          [
                            _c("li", { staticClass: "w10p" }, [
                              _vm._v(_vm._s(childItem.materialCode))
                            ]),
                            _c("li", { staticClass: "w10p" }, [
                              _vm._v(_vm._s(childItem.factorySeason))
                            ]),
                            _c("li", { staticClass: "w10p" }, [
                              _vm._v(_vm._s(childItem.supplierName))
                            ]),
                            _c("li", { staticClass: "w10p" }, [
                              _vm._v(_vm._s(childItem.offeredPrice))
                            ]),
                            _c("li", { staticClass: "w12p" }, [
                              _vm._v(
                                _vm._s(childItem.offered_packages + "包 ") +
                                  _vm._s(
                                    childItem.offered_quantity_per_package +
                                      "米/包 "
                                  ) +
                                  _vm._s(childItem.offeredQuantity + "米")
                              )
                            ]),
                            _c("li", { staticClass: "w8p" }, [
                              _vm._v(_vm._s(childItem.offeredDate))
                            ]),
                            _c("li", { staticClass: "w10p" }, [
                              _vm._v(_vm._s(childItem.materialCode))
                            ]),
                            _c("li", { staticClass: "w10p" }, [
                              _vm._v(_vm._s(childItem.materialCode))
                            ]),
                            _c("li", { staticClass: "w10p" }, [
                              _vm._v(_vm._s(childItem.materialCode))
                            ]),
                            _c("li", { staticClass: "w10p" }, [
                              _vm._v(_vm._s(childItem.remarks))
                            ])
                          ]
                        )
                      })
                    ],
                    2
                  )
                })
              ],
              2
            ),
            _c(
              "el-tab-pane",
              { attrs: { label: "审批中", name: "Approving" } },
              [
                _c(
                  "ul",
                  { staticClass: "childlist" },
                  _vm._l(_vm.allFieldList, function(item, index) {
                    return _c("li", { key: index, class: item.class }, [
                      _vm._v(_vm._s(item.name))
                    ])
                  }),
                  0
                ),
                _vm._l(_vm.orderList, function(item, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "allparentlist" },
                    [
                      _c("ul", { staticClass: "parentlist" }, [
                        _c("li", [
                          _c("span", [_vm._v(_vm._s(item.createTime))])
                        ]),
                        _c("li", [
                          _c("i", [
                            _vm._v(
                              "\n                  订单编号:\n                  "
                            ),
                            _c("em", [_vm._v(_vm._s(item.purchaseOrderId))])
                          ])
                        ]),
                        _c("li", [
                          _c("i", [
                            _vm._v(
                              "\n                  请求状态:\n                  "
                            ),
                            _c("em", [_vm._v(_vm._s(item.orderStatus))])
                          ])
                        ]),
                        _c(
                          "li",
                          {
                            staticStyle: {
                              float: "right",
                              "margin-right": "15px"
                            },
                            on: {
                              click: function($event) {
                                return _vm.showChildList(index, item)
                              }
                            }
                          },
                          [
                            item.isShowList
                              ? _c("i", { staticClass: "el-icon-caret-top" })
                              : _c("i", { staticClass: "el-icon-caret-bottom" })
                          ]
                        ),
                        _c("li", { staticClass: "fr" }, [
                          _c("i", {
                            staticClass: "el-icon-view",
                            attrs: { type: "primary", title: "查看" },
                            on: {
                              click: function($event) {
                                return _vm.checkOrderHandle(index, item)
                              }
                            }
                          })
                        ])
                      ]),
                      _vm._l(item.poiList, function(childItem, index) {
                        return _c(
                          "ul",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: item.isShowList,
                                expression: "item.isShowList"
                              }
                            ],
                            key: index,
                            staticClass: "childcontentlist"
                          },
                          [
                            _c("li", { staticClass: "w10p" }, [
                              _vm._v(_vm._s(childItem.materialCode))
                            ]),
                            _c("li", { staticClass: "w10p" }, [
                              _vm._v(_vm._s(childItem.factorySeason))
                            ]),
                            _c("li", { staticClass: "w10p" }, [
                              _vm._v(_vm._s(childItem.supplierName))
                            ]),
                            _c("li", { staticClass: "w10p" }, [
                              _vm._v(_vm._s(childItem.offeredPrice))
                            ]),
                            _c("li", { staticClass: "w12p" }, [
                              _vm._v(
                                _vm._s(childItem.offered_packages + "包 ") +
                                  _vm._s(
                                    childItem.offered_quantity_per_package +
                                      "米/包 "
                                  ) +
                                  _vm._s(childItem.offeredQuantity + "米")
                              )
                            ]),
                            _c("li", { staticClass: "w8p" }, [
                              _vm._v(_vm._s(childItem.offeredDate))
                            ]),
                            _c("li", { staticClass: "w10p" }, [
                              _vm._v(_vm._s(childItem.materialCode))
                            ]),
                            _c("li", { staticClass: "w10p" }, [
                              _vm._v(_vm._s(childItem.materialCode))
                            ]),
                            _c("li", { staticClass: "w10p" }, [
                              _vm._v(_vm._s(childItem.materialCode))
                            ]),
                            _c("li", { staticClass: "w10p" }, [
                              _vm._v(_vm._s(childItem.remarks))
                            ])
                          ]
                        )
                      })
                    ],
                    2
                  )
                })
              ],
              2
            ),
            _c(
              "el-tab-pane",
              { attrs: { label: "已审批", name: "Official" } },
              [
                _c(
                  "ul",
                  { staticClass: "childlist" },
                  _vm._l(_vm.allFieldList, function(item, index) {
                    return _c("li", { key: index, class: item.class }, [
                      _vm._v(_vm._s(item.name))
                    ])
                  }),
                  0
                ),
                _vm._l(_vm.orderList, function(item, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "allparentlist" },
                    [
                      _c("ul", { staticClass: "parentlist" }, [
                        _c("li", [
                          _c("span", [_vm._v(_vm._s(item.createTime))])
                        ]),
                        _c("li", [
                          _c("i", [
                            _vm._v(
                              "\n                  订单编号:\n                  "
                            ),
                            _c("em", [_vm._v(_vm._s(item.purchaseOrderId))])
                          ])
                        ]),
                        _c("li", [
                          _c("i", [
                            _vm._v(
                              "\n                  请求状态:\n                  "
                            ),
                            _c("em", [_vm._v(_vm._s(item.orderStatus))])
                          ])
                        ]),
                        _c(
                          "li",
                          {
                            staticStyle: {
                              float: "right",
                              "margin-right": "15px"
                            },
                            on: {
                              click: function($event) {
                                return _vm.showChildList(index, item)
                              }
                            }
                          },
                          [
                            item.isShowList
                              ? _c("i", { staticClass: "el-icon-caret-top" })
                              : _c("i", { staticClass: "el-icon-caret-bottom" })
                          ]
                        ),
                        _c("li", { staticClass: "fr" }, [
                          _c("i", {
                            staticClass: "el-icon-view",
                            attrs: { type: "primary", title: "查看" },
                            on: {
                              click: function($event) {
                                return _vm.checkOrderHandle(index, item)
                              }
                            }
                          })
                        ])
                      ]),
                      _vm._l(item.poiList, function(childItem, index) {
                        return _c(
                          "ul",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: item.isShowList,
                                expression: "item.isShowList"
                              }
                            ],
                            key: index,
                            staticClass: "childcontentlist"
                          },
                          [
                            _c("li", { staticClass: "w10p" }, [
                              _vm._v(_vm._s(childItem.materialCode))
                            ]),
                            _c("li", { staticClass: "w10p" }, [
                              _vm._v(_vm._s(childItem.factorySeason))
                            ]),
                            _c("li", { staticClass: "w10p" }, [
                              _vm._v(_vm._s(childItem.supplierName))
                            ]),
                            _c("li", { staticClass: "w10p" }, [
                              _vm._v(_vm._s(childItem.offeredPrice))
                            ]),
                            _c("li", { staticClass: "w12p" }, [
                              _vm._v(
                                _vm._s(childItem.offered_packages + "包 ") +
                                  _vm._s(
                                    childItem.offered_quantity_per_package +
                                      "米/包 "
                                  ) +
                                  _vm._s(childItem.offeredQuantity + "米")
                              )
                            ]),
                            _c("li", { staticClass: "w8p" }, [
                              _vm._v(_vm._s(childItem.offeredDate))
                            ]),
                            _c("li", { staticClass: "w10p" }, [
                              _vm._v(_vm._s(childItem.materialCode))
                            ]),
                            _c("li", { staticClass: "w10p" }, [
                              _vm._v(_vm._s(childItem.materialCode))
                            ]),
                            _c("li", { staticClass: "w10p" }, [
                              _vm._v(_vm._s(childItem.materialCode))
                            ]),
                            _c("li", { staticClass: "w10p" }, [
                              _vm._v(_vm._s(childItem.remarks))
                            ])
                          ]
                        )
                      })
                    ],
                    2
                  )
                })
              ],
              2
            )
          ],
          1
        ),
        this.orderList == ""
          ? _c("p", { staticClass: "empty_tip" }, [_vm._v("暂无数据")])
          : _vm._e(),
        _c(
          "div",
          { staticClass: "pagination_wrap fr" },
          [
            _c("el-pagination", {
              attrs: {
                "current-page": _vm.currentPage,
                "page-sizes": [10, 50],
                "page-size": _vm.pageSize,
                layout: "sizes, total, prev, pager, next",
                total: this.total
              },
              on: {
                "size-change": _vm.handleSizeChange,
                "current-change": _vm.handleCurrentChange,
                "update:currentPage": function($event) {
                  _vm.currentPage = $event
                },
                "update:current-page": function($event) {
                  _vm.currentPage = $event
                }
              }
            })
          ],
          1
        )
      ],
      1
    ),
    _c("div", { staticClass: "check_popup_part" })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
export function scrollHandle(row,childlist,pageSizechild,childSelectcheck){
    for (let i = 0; i < childlist.length; i++) {
        let item = childlist[i];
        this.$nextTick(() => {
          item.dom = this.$refs['childMultipleTable_' + row.id].bodyWrapper;
          console.log('item.dom',item.dom);
          item.dom.addEventListener('scroll', () => {
            // 滚动距离
            let scrollTop = Math.floor(item.dom.scrollTop);
            // 变量windowHeight是可视区的高度
            // console.log('---scrollTop---',scrollTop)
            let windowHeight = item.dom.clientHeight || item.dom.clientHeight
            // 变量scrollHeight是滚动条的总高度
            //  console.log('---windowHeight---',windowHeight)
            let scrollHeight = item.dom.scrollHeight || item.dom.scrollHeight
            //  console.log('---scrollHeight---',scrollHeight)

            if (scrollTop + windowHeight >=scrollHeight) {
            // 获取到的不是全部数据 当滚动到底部 继续获取新的数据
              if(row.currentPageChid<row.childTotalPage){
                console.log('publicjs下一页');
                  //下一页
                  row.currentPageChid++;
                  let copylist=row.scrollList;
                  let scrollPrilist=getMoreLog(row.currentPageChid,copylist,item.dom,pageSizechild);
                  row.priList=row.priList.concat(scrollPrilist);
                  
                  //记录选中状态
                  for (let c = 0; c < childlist.length; c++) {
                    let cItem = childlist[c];
                    //判断是否为全选
                    if(childSelectcheck==true){
                      recordCheckStatusHandle(row,cItem);
                    }else{
                      //是否有选中状态
                      if(row.scrollCheckStatusList){
                        for(let s=0;s<row.scrollCheckStatusList.length;s++){
                          let sItem=row.scrollCheckStatusList[s];
                          if(cItem.uuid==sItem.uuid){
                            console.log('为选中状态的更新');
                            recordCheckStatusHandle(row,cItem);
                          }
                        }
                      } 
                    }
                  }
                  this.$forceUpdate();
              }else{
                if (row.currentPageChid == row.childTotalPage)return ;
              }
            }
          })

        });
    }
}
function getMoreLog(currentPageChid,copylist,dom,pageSizechild) {
    dom.scrollTop = dom.scrollTop - 100;
    let begin = (currentPageChid - 1) * pageSizechild;
    let end = currentPageChid * pageSizechild;
    return copylist.slice(begin,end);
}

function  recordCheckStatusHandle(row,cItem){
    //更新选中状态
    this.$nextTick(() => {
        if (this.$refs['childMultipleTable_' + row.id] && !Array.isArray(this.$refs['childMultipleTable_' + row.id])) {
        this.$refs['childMultipleTable_' + row.id].toggleRowSelection(cItem, true);
        }
    });
}



var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container purchaseOrder" },
    [
      !_vm.isShowEdit
        ? _c("div", { staticClass: "part" }, [
            _c("div", { staticClass: "actions_part clearfix" }, [
              _c("div", { staticClass: "actions_wrap" }, [
                _c(
                  "div",
                  { staticClass: "display_ib mr10 mb10" },
                  [
                    _c("span", { staticClass: "tag" }, [
                      _vm._v("采购订单编号：")
                    ]),
                    _c("el-input", {
                      staticClass: "input_single w200 mr10",
                      attrs: { placeholder: "请输入" },
                      nativeOn: {
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.searchHandle($event)
                        }
                      },
                      model: {
                        value: _vm.purchaseOrderId,
                        callback: function($$v) {
                          _vm.purchaseOrderId = $$v
                        },
                        expression: "purchaseOrderId"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "display_ib mr10 mb10" },
                  [
                    _c("span", { staticClass: "tag" }, [
                      _vm._v("采购申请编号：")
                    ]),
                    _c("el-input", {
                      staticClass: "input_single w200 mr10",
                      attrs: { placeholder: "请输入" },
                      nativeOn: {
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.searchHandle($event)
                        }
                      },
                      model: {
                        value: _vm.poIdOrPrCode,
                        callback: function($$v) {
                          _vm.poIdOrPrCode = $$v
                        },
                        expression: "poIdOrPrCode"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "display_ib mr10 mb10" },
                  [
                    _c("span", { staticClass: "tag" }, [_vm._v("物料编号：")]),
                    _c("el-input", {
                      staticClass: "input_single w200 mr10",
                      attrs: { placeholder: "请输入" },
                      nativeOn: {
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.searchHandle($event)
                        }
                      },
                      model: {
                        value: _vm.materialCode,
                        callback: function($$v) {
                          _vm.materialCode = $$v
                        },
                        expression: "materialCode"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "display_ib mr10 mb10" },
                  [
                    _c("span", { staticClass: "tag" }, [
                      _vm._v("采购订单类型：")
                    ]),
                    _c(
                      "el-select",
                      {
                        staticClass: "select_single w200 mr10",
                        attrs: { placeholder: "请选择" },
                        on: { change: _vm.searchHandle },
                        model: {
                          value: _vm.purchaseOrderType,
                          callback: function($$v) {
                            _vm.purchaseOrderType = $$v
                          },
                          expression: "purchaseOrderType"
                        }
                      },
                      [
                        _c("el-option", {
                          key: "",
                          attrs: { label: "全部", value: "" }
                        }),
                        _vm._l(_vm.orderTypeList, function(item) {
                          return _c("el-option", {
                            key: item.index,
                            attrs: { label: item.name, value: item.index }
                          })
                        })
                      ],
                      2
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "display_ib mr10 mb10" },
                  [
                    _c("span", { staticClass: "tag" }, [_vm._v("供应商：")]),
                    _c(
                      "el-select",
                      {
                        staticClass: "select_single w200 mr10",
                        attrs: { filterable: "", placeholder: "请选择" },
                        on: { change: _vm.searchHandle },
                        model: {
                          value: _vm.supplierCode,
                          callback: function($$v) {
                            _vm.supplierCode = $$v
                          },
                          expression: "supplierCode"
                        }
                      },
                      [
                        _c("el-option", {
                          key: "",
                          attrs: { label: "全部", value: "" }
                        }),
                        _vm._l(_vm.supplierList, function(item) {
                          return _c("el-option", {
                            key: item.supplierId,
                            attrs: {
                              label: item.supplierCode,
                              value: item.supplierId
                            }
                          })
                        })
                      ],
                      2
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "display_ib mr10 mb10" },
                  [
                    _c("span", { staticClass: "tag" }, [_vm._v("创建日期：")]),
                    _c("el-date-picker", {
                      attrs: {
                        "unlink-panels": "",
                        type: "daterange",
                        format: "yyyy-MM-dd",
                        "value-format": "yyyy-MM-dd",
                        "range-separator": "至",
                        "start-placeholder": "开始日期",
                        "end-placeholder": "结束日期"
                      },
                      on: { change: _vm.dateValChange },
                      model: {
                        value: _vm.dateVal,
                        callback: function($$v) {
                          _vm.dateVal = $$v
                        },
                        expression: "dateVal"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "display_ib mr10 mb10" },
                  [
                    _c("span", { staticClass: "tag" }, [_vm._v("申请人：")]),
                    _c("el-input", {
                      staticClass: "input_single w200 mr10",
                      attrs: { placeholder: "请输入" },
                      nativeOn: {
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.searchHandle($event)
                        }
                      },
                      model: {
                        value: _vm.applicantName,
                        callback: function($$v) {
                          _vm.applicantName = $$v
                        },
                        expression: "applicantName"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "display_ib mr10 mb10" },
                  [
                    _c("span", { staticClass: "tag" }, [
                      _vm._v("销售订单编号：")
                    ]),
                    _c("el-input", {
                      staticClass: "input_single w200 mr10",
                      attrs: { placeholder: "请输入" },
                      nativeOn: {
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.searchHandle($event)
                        }
                      },
                      model: {
                        value: _vm.saleOrderId,
                        callback: function($$v) {
                          _vm.saleOrderId = $$v
                        },
                        expression: "saleOrderId"
                      }
                    })
                  ],
                  1
                )
              ]),
              _c(
                "div",
                { staticClass: "actions_btn_wrap down t_right" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.excelDownload }
                    },
                    [_vm._v("导出Excel")]
                  ),
                  _c("el-button", {
                    attrs: {
                      size: "small",
                      type: "primary",
                      icon: "el-icon-search",
                      circle: "",
                      title: "搜索"
                    },
                    on: { click: _vm.searchHandle }
                  })
                ],
                1
              )
            ]),
            _c(
              "div",
              { staticClass: "table_part clearfix" },
              [
                _c(
                  "el-tabs",
                  {
                    staticClass: "subnav_wrap",
                    on: { "tab-click": _vm.switchTabHandle },
                    model: {
                      value: _vm.activeName,
                      callback: function($$v) {
                        _vm.activeName = $$v
                      },
                      expression: "activeName"
                    }
                  },
                  _vm._l(_vm.subNavList, function(item) {
                    return _c(
                      "el-tab-pane",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.tableLoading,
                            expression: "tableLoading"
                          }
                        ],
                        key: item.value,
                        attrs: { label: item.label, name: item.value }
                      },
                      [
                        _c(
                          "el-table",
                          {
                            ref: "multipleTable",
                            refInFor: true,
                            staticClass: "table_list",
                            attrs: {
                              data: _vm.orderList,
                              "expand-row-keys": _vm.expands,
                              "row-key": "id",
                              border: "",
                              fit: "",
                              stripe: ""
                            },
                            on: {
                              "row-click": _vm.rowClick,
                              "expand-change": _vm.rowClick
                            }
                          },
                          [
                            _c("el-table-column", {
                              attrs: { type: "expand" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(scope) {
                                      return [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "actions_wrap mb20 clearfix"
                                          },
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                staticClass: "fr",
                                                attrs: { type: "primary" },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.childExcelDownload(
                                                      scope.row
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v("导出Excel")]
                                            ),
                                            _c(
                                              "el-button",
                                              {
                                                staticClass: "fr mr10",
                                                attrs: { type: "primary" },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.mergeChildExcelDownload(
                                                      scope.row
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v("合并导出Excel")]
                                            ),
                                            scope.row.orderStatus == "1" ||
                                            scope.row.orderStatus == "5"
                                              ? _c(
                                                  "el-button",
                                                  {
                                                    staticClass: "fr",
                                                    attrs: { type: "primary" },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.editHandle(
                                                          scope.$index,
                                                          scope.row
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [_vm._v("更新导入Excel")]
                                                )
                                              : _vm._e()
                                          ],
                                          1
                                        ),
                                        _c(
                                          "pl-table",
                                          {
                                            ref:
                                              "childMultipleTable_" +
                                              scope.row.id,
                                            refInFor: true,
                                            staticClass: "table_list",
                                            staticStyle: {
                                              width: "100%",
                                              height: "100%"
                                            },
                                            attrs: {
                                              data: scope.row.poiList,
                                              "max-height": 700,
                                              "use-virtual": true,
                                              "row-height": 90,
                                              border: "",
                                              fit: ""
                                            }
                                          },
                                          [
                                            _c("el-table-column", {
                                              attrs: {
                                                fixed: "",
                                                label: "行号"
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var row = ref.row
                                                      return [
                                                        _c(
                                                          "p",
                                                          {
                                                            class: {
                                                              isThrough:
                                                                row.cancelStatus
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                row.purchaseOrderSn
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            }),
                                            _c("pl-table-column", {
                                              attrs: {
                                                "show-overflow-tooltip": true,
                                                fixed: "",
                                                label: "物料编号",
                                                "min-width": "180",
                                                "class-name": "marpno_wuliao"
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var row = ref.row
                                                      return [
                                                        _c(
                                                          "p",
                                                          {
                                                            class: {
                                                              isThrough:
                                                                row.cancelStatus
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  row.materialCode
                                                                )
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            }),
                                            _c("pl-table-column", {
                                              attrs: {
                                                label: "采购价格",
                                                "min-width": "100"
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var row = ref.row
                                                      return [
                                                        _c(
                                                          "p",
                                                          {
                                                            class: {
                                                              isThrough:
                                                                row.cancelStatus
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                row.offeredPrice
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            }),
                                            _c("pl-table-column", {
                                              attrs: {
                                                "show-overflow-tooltip": true,
                                                label: "请求数量",
                                                "min-width": "100",
                                                "class-name": "marpno_wuliao"
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var row = ref.row
                                                      return [
                                                        _c(
                                                          "p",
                                                          {
                                                            class: {
                                                              isThrough:
                                                                row.cancelStatus
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                (row.requiredQuantity
                                                                  ? row.requiredQuantity
                                                                  : 0) +
                                                                  (row.purchaseMeasurementUnit
                                                                    ? row.purchaseMeasurementUnit
                                                                    : "条")
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            }),
                                            _c("pl-table-column", {
                                              attrs: {
                                                "show-overflow-tooltip": true,
                                                label: "采购数量",
                                                "min-width": "180",
                                                "class-name": "marpno_wuliao"
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var row = ref.row
                                                      return [
                                                        row.purchaseMeasurementUnit ==
                                                        "米"
                                                          ? _c("span", [
                                                              _c(
                                                                "p",
                                                                {
                                                                  class: {
                                                                    isThrough:
                                                                      row.cancelStatus
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      (row.offeredPackages
                                                                        ? row.offeredPackages
                                                                        : 0) +
                                                                        "包 " +
                                                                        (row.offeredQuantityPerPackage
                                                                          ? row.offeredQuantityPerPackage
                                                                          : 0) +
                                                                        "米/包 " +
                                                                        (row.offeredQuantity
                                                                          ? row.offeredQuantity
                                                                          : 0) +
                                                                        "米"
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ])
                                                          : _c("span", [
                                                              _c(
                                                                "p",
                                                                {
                                                                  class: {
                                                                    isThrough:
                                                                      row.cancelStatus
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      (row.offeredQuantity
                                                                        ? row.offeredQuantity
                                                                        : 0) +
                                                                        (row.purchaseMeasurementUnit
                                                                          ? row.purchaseMeasurementUnit
                                                                          : "条")
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ])
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            }),
                                            _c("pl-table-column", {
                                              attrs: {
                                                "show-overflow-tooltip": true,
                                                label: "期望交期",
                                                "min-width": "180",
                                                "class-name": "marpno_wuliao"
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var row = ref.row
                                                      return [
                                                        _c(
                                                          "p",
                                                          {
                                                            class: {
                                                              isThrough:
                                                                row.cancelStatus
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                row.requiredDate
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            }),
                                            _c("pl-table-column", {
                                              attrs: {
                                                "show-overflow-tooltip": true,
                                                label: "工厂交期",
                                                "min-width": "180",
                                                "class-name": "marpno_wuliao"
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var row = ref.row
                                                      return [
                                                        _c(
                                                          "p",
                                                          {
                                                            class: {
                                                              isThrough:
                                                                row.cancelStatus
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                row.offeredDate
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            }),
                                            scope.row.orderStatus == "1"
                                              ? _c("pl-table-column", {
                                                  attrs: {
                                                    label: "物料工厂季",
                                                    "min-width": "180"
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function(ref) {
                                                          var row = ref.row
                                                          return [
                                                            _c(
                                                              "p",
                                                              {
                                                                class: {
                                                                  isThrough:
                                                                    row.cancelStatus
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    row.materialFactorySeason
                                                                  )
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    true
                                                  )
                                                })
                                              : _vm._e(),
                                            _c("pl-table-column", {
                                              attrs: {
                                                label: "快货",
                                                "min-width": "100"
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var row = ref.row
                                                      return [
                                                        _c(
                                                          "p",
                                                          {
                                                            class: {
                                                              isThrough:
                                                                row.cancelStatus
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                row.mobileGoods
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            }),
                                            _c("pl-table-column", {
                                              attrs: {
                                                label: "EV",
                                                "min-width": "100"
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var row = ref.row
                                                      return [
                                                        _c(
                                                          "p",
                                                          {
                                                            class: {
                                                              isThrough:
                                                                row.cancelStatus
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                row.belongToEv
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            }),
                                            _c("pl-table-column", {
                                              attrs: {
                                                label: "采购备注",
                                                "min-width": "180"
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var row = ref.row
                                                      return [
                                                        _c(
                                                          "p",
                                                          {
                                                            class: {
                                                              isThrough:
                                                                row.cancelStatus
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                row.purchaseRemark
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            }),
                                            _c("pl-table-column", {
                                              attrs: {
                                                label: "备注",
                                                "min-width": "200"
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var row = ref.row
                                                      return [
                                                        _c(
                                                          "p",
                                                          {
                                                            class: {
                                                              isThrough:
                                                                row.cancelStatus
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(row.remark)
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            }),
                                            _c("pl-table-column", {
                                              attrs: {
                                                label: "特殊要求",
                                                "min-width": "180"
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var row = ref.row
                                                      return [
                                                        _c(
                                                          "p",
                                                          {
                                                            class: {
                                                              isThrough:
                                                                row.cancelStatus
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                row.specialRequire
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            }),
                                            _c("pl-table-column", {
                                              attrs: {
                                                label: "期望LT",
                                                "min-width": "180"
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var row = ref.row
                                                      return [
                                                        _c(
                                                          "p",
                                                          {
                                                            class: {
                                                              isThrough:
                                                                row.cancelStatus
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                row.offerLt
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            }),
                                            _c("pl-table-column", {
                                              attrs: {
                                                label: "MTM",
                                                "min-width": "180"
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var row = ref.row
                                                      return [
                                                        _c(
                                                          "p",
                                                          {
                                                            class: {
                                                              isThrough:
                                                                row.cancelStatus
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                row.listOfCuttingLength
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            }),
                                            _c("pl-table-column", {
                                              attrs: {
                                                label: "销售订单编号",
                                                "min-width": "180"
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var row = ref.row
                                                      return [
                                                        _c(
                                                          "p",
                                                          {
                                                            class: {
                                                              isThrough:
                                                                row.cancelStatus
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                row.saleOrderId
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            }),
                                            _c("pl-table-column", {
                                              attrs: {
                                                label: "请求类型",
                                                "min-width": "180"
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var row = ref.row
                                                      return [
                                                        _c(
                                                          "p",
                                                          {
                                                            class: {
                                                              isThrough:
                                                                row.cancelStatus
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                row.prTypeName
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            }),
                                            _c("pl-table-column", {
                                              attrs: {
                                                label: "客户名称",
                                                "min-width": "180"
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var row = ref.row
                                                      return [
                                                        _c(
                                                          "p",
                                                          {
                                                            class: {
                                                              isThrough:
                                                                row.cancelStatus
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                row.customerName
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            }),
                                            _c("pl-table-column", {
                                              attrs: {
                                                label: "原确认书编号",
                                                "min-width": "180"
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var row = ref.row
                                                      return [
                                                        _c(
                                                          "p",
                                                          {
                                                            class: {
                                                              isThrough:
                                                                row.cancelStatus
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                row.originalConfirmNum
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            }),
                                            scope.row.orderStatus == "3" ||
                                            scope.row.orderStatus == "4" ||
                                            scope.row.orderStatus == "5"
                                              ? _c("pl-table-column", {
                                                  attrs: {
                                                    label: "操作",
                                                    fixed: "right"
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function(ref) {
                                                          var row = ref.row
                                                          var $index =
                                                            ref.$index
                                                          return [
                                                            _c("el-button", {
                                                              staticClass:
                                                                "el-icon-document-delete",
                                                              attrs: {
                                                                disabled:
                                                                  row.cancelStatus ===
                                                                  true,
                                                                type: "danger",
                                                                circle: "",
                                                                size: "mini",
                                                                title: "撤销"
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  $event.stopPropagation()
                                                                  return _vm.revokeHandle(
                                                                    $index,
                                                                    row
                                                                  )
                                                                }
                                                              }
                                                            })
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    true
                                                  )
                                                })
                                              : _vm._e(),
                                            scope.row.orderStatus == "1"
                                              ? _c("pl-table-column", {
                                                  attrs: {
                                                    label: "操作",
                                                    fixed: "right",
                                                    width: "100"
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function(ref) {
                                                          var row = ref.row
                                                          var $index =
                                                            ref.$index
                                                          return [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticStyle: {
                                                                  display:
                                                                    "inline-flex"
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "el-button",
                                                                  {
                                                                    staticClass:
                                                                      "el-icon-delete",
                                                                    attrs: {
                                                                      disabled:
                                                                        row.cancelStatus ===
                                                                        true,
                                                                      type:
                                                                        "danger",
                                                                      circle:
                                                                        "",
                                                                      size:
                                                                        "mini",
                                                                      title:
                                                                        "删除"
                                                                    },
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        $event.stopPropagation()
                                                                        return _vm.editDeleteHandle(
                                                                          $index,
                                                                          row
                                                                        )
                                                                      }
                                                                    }
                                                                  }
                                                                ),
                                                                _c(
                                                                  "el-button",
                                                                  {
                                                                    staticClass:
                                                                      "el-icon-document-delete",
                                                                    attrs: {
                                                                      disabled:
                                                                        row.cancelStatus ===
                                                                        true,
                                                                      type:
                                                                        "danger",
                                                                      circle:
                                                                        "",
                                                                      size:
                                                                        "mini",
                                                                      title:
                                                                        "撤销"
                                                                    },
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        $event.stopPropagation()
                                                                        return _vm.revokeHandle(
                                                                          $index,
                                                                          row
                                                                        )
                                                                      }
                                                                    }
                                                                  }
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    true
                                                  )
                                                })
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            }),
                            _c("el-table-column", {
                              attrs: { label: "订单编号" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var row = ref.row
                                      return [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(row.purchaseOrderId) +
                                            "\n                "
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            }),
                            _c("el-table-column", {
                              attrs: { label: "采购订单类型" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var row = ref.row
                                      return [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(row.purchaseOrderTypeName) +
                                            "\n                "
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            }),
                            _c("el-table-column", {
                              attrs: { label: "供应商" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var row = ref.row
                                      return [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(row.supplierShortName) +
                                            "\n                "
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            }),
                            _c("el-table-column", {
                              attrs: { label: "币种" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var row = ref.row
                                      return [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(row.currencyName) +
                                            "\n                "
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            }),
                            _c("el-table-column", {
                              attrs: { label: "付款方式" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var row = ref.row
                                      return [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(row.paymentMethodNames) +
                                            "\n                "
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            }),
                            _c("el-table-column", {
                              attrs: { label: "创建时间" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var row = ref.row
                                      return [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(row.createTime) +
                                            "\n                "
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            }),
                            _c("el-table-column", {
                              attrs: { label: "申请人" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var row = ref.row
                                      return [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(row.applicantName) +
                                            "\n                "
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            }),
                            _c("el-table-column", {
                              attrs: { label: "凭证状态" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var row = ref.row
                                      var $index = ref.$index
                                      return [
                                        _c(
                                          "el-popover",
                                          {
                                            attrs: {
                                              placement: "right",
                                              width: "300",
                                              trigger: "hover",
                                              "open-delay": "1000"
                                            },
                                            on: {
                                              show: function($event) {
                                                return _vm.checkApprovalProcess(
                                                  row,
                                                  $index
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("div", [
                                              _c(
                                                "i",
                                                { staticClass: "block" },
                                                [
                                                  _vm._v(
                                                    "\n                        审批人：\n                        "
                                                  ),
                                                  _c("em", [
                                                    _vm._v(_vm._s(row.fullName))
                                                  ])
                                                ]
                                              ),
                                              _c(
                                                "i",
                                                {
                                                  staticClass: "block mt10 mb10"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                        审批时间：\n                        "
                                                  ),
                                                  _c("em", [
                                                    _vm._v(
                                                      _vm._s(row.approvalTime)
                                                    )
                                                  ])
                                                ]
                                              ),
                                              _c(
                                                "i",
                                                { staticClass: "block" },
                                                [
                                                  _vm._v(
                                                    "\n                        审批意见：\n                        "
                                                  ),
                                                  _c("em", [
                                                    _vm._v(_vm._s(row.content))
                                                  ])
                                                ]
                                              )
                                            ]),
                                            _c(
                                              "el-tag",
                                              {
                                                staticClass: "pointer",
                                                attrs: {
                                                  slot: "reference",
                                                  type: _vm._f("statusFilter")(
                                                    row.orderStatus
                                                  )
                                                },
                                                slot: "reference"
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(row.orderStatusName)
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            }),
                            _c("el-table-column", {
                              attrs: { label: "操作", width: "150" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var row = ref.row
                                      var $index = ref.$index
                                      return [
                                        row.orderStatus == "1" ||
                                        row.orderStatus == "5"
                                          ? _c(
                                              "div",
                                              [
                                                _c("el-button", {
                                                  staticClass: "el-icon-edit",
                                                  attrs: {
                                                    type: "primary",
                                                    circle: "",
                                                    size: "mini",
                                                    title: "编辑"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      $event.stopPropagation()
                                                      return _vm.editHandle(
                                                        $index,
                                                        row
                                                      )
                                                    }
                                                  }
                                                }),
                                                _c("el-button", {
                                                  staticClass: "el-icon-check",
                                                  attrs: {
                                                    type: "success",
                                                    circle: "",
                                                    size: "mini",
                                                    title: "提交审批"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      $event.stopPropagation()
                                                      return _vm.submitHandle(
                                                        $index,
                                                        row
                                                      )
                                                    }
                                                  }
                                                }),
                                                _c("el-button", {
                                                  staticClass: "el-icon-delete",
                                                  attrs: {
                                                    type: "danger",
                                                    circle: "",
                                                    size: "mini",
                                                    title: "删除"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      $event.stopPropagation()
                                                      return _vm.deleteHandle(
                                                        $index,
                                                        row
                                                      )
                                                    }
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        row.orderStatus == "2"
                                          ? _c(
                                              "div",
                                              [
                                                _c("el-button", {
                                                  staticClass:
                                                    "el-icon-document",
                                                  attrs: {
                                                    type: "primary",
                                                    circle: "",
                                                    size: "mini",
                                                    title: "查看"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      $event.stopPropagation()
                                                      return _vm.checkHandle(
                                                        $index,
                                                        row
                                                      )
                                                    }
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        row.orderStatus == "3"
                                          ? _c(
                                              "div",
                                              [
                                                _c("el-button", {
                                                  staticClass:
                                                    "el-icon-document",
                                                  attrs: {
                                                    type: "primary",
                                                    circle: "",
                                                    size: "mini",
                                                    title: "查看"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      $event.stopPropagation()
                                                      return _vm.checkHandle(
                                                        $index,
                                                        row
                                                      )
                                                    }
                                                  }
                                                }),
                                                _c("el-button", {
                                                  staticClass:
                                                    "el-icon-switch-button",
                                                  attrs: {
                                                    type: "danger",
                                                    circle: "",
                                                    size: "mini",
                                                    title: "关闭"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      $event.stopPropagation()
                                                      return _vm.closeHandle(
                                                        $index,
                                                        row,
                                                        "close"
                                                      )
                                                    }
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        row.orderStatus == "7"
                                          ? _c(
                                              "div",
                                              [
                                                _c("el-button", {
                                                  staticClass:
                                                    "el-icon-switch-button",
                                                  attrs: {
                                                    type: "danger",
                                                    disabled: "",
                                                    circle: "",
                                                    size: "mini",
                                                    title: "关闭"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  }),
                  1
                ),
                _c(
                  "div",
                  { staticClass: "pagination_wrap fr" },
                  [
                    _c("el-pagination", {
                      attrs: {
                        "current-page": _vm.currentPage,
                        "page-sizes": [10, 50],
                        "page-size": _vm.pageSize,
                        total: _vm.total,
                        layout: "sizes, total, prev, pager, next"
                      },
                      on: {
                        "update:currentPage": function($event) {
                          _vm.currentPage = $event
                        },
                        "update:current-page": function($event) {
                          _vm.currentPage = $event
                        },
                        "size-change": _vm.handleSizeChange,
                        "current-change": _vm.handleCurrentChange
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ])
        : _c("div", { staticClass: "edit_part" }, [
            _c("div", { staticClass: "clearfix mb10" }, [
              _c(
                "div",
                { staticClass: "display_ib mr10 mb10" },
                [
                  _vm._m(0),
                  _c("el-input", {
                    staticClass: "input_single w200 mr10",
                    attrs: { maxlength: "17", placeholder: "请输入" },
                    model: {
                      value: _vm.editOrderData.purchaseOrderId,
                      callback: function($$v) {
                        _vm.$set(_vm.editOrderData, "purchaseOrderId", $$v)
                      },
                      expression: "editOrderData.purchaseOrderId"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "display_ib mr10 mb10" },
                [
                  _vm._m(1),
                  _c(
                    "el-select",
                    {
                      staticClass: "select_single w200 mr10",
                      attrs: { placeholder: "请选择" },
                      on: {
                        change: function($event) {
                          return _vm.orderTypeChange($event, _vm.editOrderData)
                        }
                      },
                      model: {
                        value: _vm.editOrderData.purchaseOrderType,
                        callback: function($$v) {
                          _vm.$set(_vm.editOrderData, "purchaseOrderType", $$v)
                        },
                        expression: "editOrderData.purchaseOrderType"
                      }
                    },
                    [
                      _c("el-option", {
                        key: "",
                        attrs: { label: "请选择", value: "" }
                      }),
                      _vm._l(_vm.orderTypeList, function(item) {
                        return _c("el-option", {
                          key: item.index,
                          attrs: { label: item.name, value: item.index }
                        })
                      })
                    ],
                    2
                  )
                ],
                1
              ),
              _c("div", { staticClass: "display_ib mr10 mb10" }, [
                _vm._m(2),
                _c("span", { staticClass: "tag" }, [
                  _vm._v(_vm._s(_vm.editOrderData.supplierShortName))
                ])
              ]),
              _c(
                "div",
                { staticClass: "display_ib mr20" },
                [
                  _vm._m(3),
                  _c(
                    "el-select",
                    {
                      staticClass: "select_single w200 mr10 mb10",
                      attrs: { placeholder: "请选择" },
                      on: { change: _vm.tradeTermsSelect },
                      model: {
                        value: _vm.editOrderData.tradeTerms,
                        callback: function($$v) {
                          _vm.$set(_vm.editOrderData, "tradeTerms", $$v)
                        },
                        expression: "editOrderData.tradeTerms"
                      }
                    },
                    [
                      _c("el-option", {
                        key: "",
                        attrs: { label: "请选择", value: "" }
                      }),
                      _vm._l(_vm.tradeTermsList, function(item) {
                        return _c("el-option", {
                          key: item.index,
                          attrs: { label: item.name, value: item.index }
                        })
                      })
                    ],
                    2
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "display_ib mr10 mb10" },
                [
                  _vm._m(4),
                  _c(
                    "el-select",
                    {
                      staticClass: "select_single w200",
                      attrs: { placeholder: "请选择" },
                      on: {
                        change: function($event) {
                          return _vm.currencyNameChange(
                            $event,
                            _vm.editOrderData
                          )
                        }
                      },
                      model: {
                        value: _vm.editOrderData.currencyName,
                        callback: function($$v) {
                          _vm.$set(_vm.editOrderData, "currencyName", $$v)
                        },
                        expression: "editOrderData.currencyName"
                      }
                    },
                    [
                      _c("el-option", {
                        key: "",
                        attrs: { label: "请选择", value: "" }
                      }),
                      _vm._l(_vm.currencyList, function(item) {
                        return _c("el-option", {
                          key: item.index,
                          attrs: { label: item.name, value: item.name }
                        })
                      })
                    ],
                    2
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "display_ib mr10 mb10" },
                [
                  _c("span", { staticClass: "tag" }, [
                    _vm._v("确认书工厂季：")
                  ]),
                  _c(
                    "el-select",
                    {
                      staticClass: "w200",
                      attrs: { placeholder: "请选择" },
                      on: {
                        change: function($event) {
                          return _vm.factorySeasonChange(
                            $event,
                            _vm.editOrderData
                          )
                        }
                      },
                      model: {
                        value: _vm.editOrderData.factorySeason,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.editOrderData,
                            "factorySeason",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "editOrderData.factorySeason"
                      }
                    },
                    [
                      _c("el-option", {
                        key: "",
                        attrs: { label: "请选择", value: "" }
                      }),
                      _vm._l(_vm.saleSeasonList, function(item) {
                        return _c("el-option", {
                          key: item.index,
                          attrs: { value: item.name, label: item.name }
                        })
                      })
                    ],
                    2
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "display_ib mr10 mb10" },
                [
                  _c("span", { staticClass: "tag" }, [_vm._v("采购价单：")]),
                  _c(
                    "el-select",
                    {
                      staticClass: "w200",
                      attrs: { placeholder: "请选择" },
                      on: {
                        change: function($event) {
                          return _vm.priceListNameChange(
                            $event,
                            _vm.editOrderData
                          )
                        }
                      },
                      model: {
                        value: _vm.editOrderData.priceListName,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.editOrderData,
                            "priceListName",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "editOrderData.priceListName"
                      }
                    },
                    [
                      _c("el-option", {
                        key: "",
                        attrs: { label: "请选择", value: "" }
                      }),
                      _vm._l(_vm.priceOrderList, function(item) {
                        return _c("el-option", {
                          key: item,
                          attrs: { value: item, label: item }
                        })
                      })
                    ],
                    2
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "display_ib mr10 mb10" },
                [
                  _vm._m(5),
                  _c(
                    "el-select",
                    {
                      staticClass: "w200",
                      attrs: {
                        disabled: !_vm.isEmptyData,
                        placeholder: "请选择"
                      },
                      on: { change: _vm.paymentMethodChange },
                      model: {
                        value: _vm.editOrderData.paymentMethod,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.editOrderData,
                            "paymentMethod",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "editOrderData.paymentMethod"
                      }
                    },
                    [
                      _c("el-option", {
                        key: "",
                        attrs: { label: "请选择", value: "" }
                      }),
                      _vm._l(_vm.paymentMethodList, function(item) {
                        return _c("el-option", {
                          key: item.dictItemValue,
                          attrs: {
                            value: item.dictItemValue,
                            label: item.dictItemName
                          }
                        })
                      })
                    ],
                    2
                  )
                ],
                1
              ),
              _c("div", { staticClass: "display_ib mr10 mb10" }, [
                _c("span", { staticClass: "tag" }, [_vm._v("采购总量：")]),
                _c("span", { staticClass: "tag" }, [
                  _vm._v(_vm._s(_vm.editOrderData.totalQuantity))
                ])
              ]),
              _c("div", { staticClass: "display_ib mr20 mb10" }, [
                _c("span", { staticClass: "tag" }, [_vm._v("采购总金额：")]),
                _c("span", { staticClass: "tag" }, [
                  _vm._v(_vm._s(_vm.editOrderData.totalAmount))
                ])
              ]),
              _vm.editOrderData.paymentMethod == "downPayment"
                ? _c(
                    "div",
                    { staticClass: "display_ib mr10 mb10" },
                    [
                      _vm._m(6),
                      !_vm.isDownPaymentInput
                        ? _c(
                            "el-select",
                            {
                              staticClass: "w200",
                              attrs: { placeholder: "请选择" },
                              on: {
                                change: function($event) {
                                  return _vm.downPaymentChange(
                                    $event,
                                    _vm.editOrderData
                                  )
                                }
                              },
                              model: {
                                value: _vm.editOrderData.downPaymentOrPeriod,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.editOrderData,
                                    "downPaymentOrPeriod",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "editOrderData.downPaymentOrPeriod"
                              }
                            },
                            [
                              _vm._l(_vm.downPaymentList, function(item) {
                                return _c("el-option", {
                                  key: item.dictItemValue,
                                  attrs: {
                                    value: item.dictItemValue,
                                    label: item.dictItemName
                                  }
                                })
                              }),
                              _c("el-option", {
                                key: "",
                                attrs: { label: "手动输入", value: "" }
                              })
                            ],
                            2
                          )
                        : _c(
                            "span",
                            { staticClass: "w200" },
                            [
                              _c("el-input", {
                                staticClass: "w200",
                                attrs: { placeholder: "请输入" },
                                on: {
                                  change: function($event) {
                                    return _vm.downPaymentInputChange(
                                      $event,
                                      _vm.editOrderData
                                    )
                                  }
                                },
                                nativeOn: {
                                  input: function($event) {
                                    return _vm.downPaymentInputHandle($event)
                                  }
                                },
                                model: {
                                  value: _vm.editOrderData.downPaymentOrPeriod,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.editOrderData,
                                      "downPaymentOrPeriod",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "editOrderData.downPaymentOrPeriod"
                                }
                              }),
                              _c("span", { staticClass: "tag ml10 mr10" }, [
                                _vm._v("%")
                              ]),
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "mini", round: "" },
                                  on: { click: _vm.downPaymentReselect }
                                },
                                [_vm._v("重新选择")]
                              )
                            ],
                            1
                          )
                    ],
                    1
                  )
                : _vm._e()
            ]),
            _c(
              "div",
              { staticClass: "edit_wrap hidden" },
              [
                _c("upload-excel-component", {
                  staticClass: "mb20",
                  attrs: {
                    "on-success": _vm.importExcelSuccess,
                    "before-upload": _vm.importExcelBeforeUpload
                  }
                }),
                _c(
                  "div",
                  { staticClass: "height60" },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "mb10 fr",
                        attrs: { size: "medium", type: "primary" },
                        on: { click: _vm.mergeOrderHandle }
                      },
                      [_vm._v("合并订单行")]
                    )
                  ],
                  1
                ),
                _c("editTable", {
                  ref: "clearChild",
                  attrs: {
                    type: "selection",
                    "table-list": _vm.editOrderData.poiList,
                    total:
                      _vm.editOrderData.poiList &&
                      _vm.editOrderData.poiList.length
                        ? _vm.editOrderData.poiList.length
                        : 0,
                    "table-leader-fields-list": _vm.leaderFieldsList,
                    "table-tail-fields-list": _vm.tailFieldsList,
                    "table-fields-list": _vm.editFieldsList
                  },
                  on: {
                    deleteHandle: _vm.deleteEditItemHandle,
                    inputBlur: _vm.inputBlur,
                    unitNumberInputChange: _vm.unitNumberInputChange,
                    unitNumberInputBlur: _vm.unitNumberInputBlur,
                    selectionChange: _vm.editSelectionChange
                  }
                }),
                _c(
                  "div",
                  { staticClass: "edit_btn_wrap mt20 fr" },
                  [
                    _c("el-button", { on: { click: _vm.cancelEditHandle } }, [
                      _vm._v("取消")
                    ]),
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "preventReClick",
                            rawName: "v-preventReClick"
                          }
                        ],
                        attrs: { type: "primary" },
                        on: { click: _vm.saveEditHandle }
                      },
                      [_vm._v("保存")]
                    ),
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "preventReClick",
                            rawName: "v-preventReClick"
                          }
                        ],
                        attrs: { type: "primary" },
                        on: { click: _vm.submitEditHandle }
                      },
                      [_vm._v("提交审批")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]),
      _c(
        "div",
        { staticClass: "popup_part" },
        [
          _c(
            "el-dialog",
            {
              directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
              attrs: {
                title: _vm.popupTitle,
                "close-on-click-modal": false,
                "close-on-press-escape": false,
                visible: _vm.dialogFormVisible
              },
              on: {
                "update:visible": function($event) {
                  _vm.dialogFormVisible = $event
                }
              }
            },
            [
              _c("el-tabs", [
                _vm.editOrderData.orderStatus == 2 ||
                _vm.editOrderData.orderStatus == 3
                  ? _c(
                      "ul",
                      { staticClass: "childlist" },
                      _vm._l(_vm.checkApprovedFieldList, function(item, index) {
                        return _c("li", { key: index, class: item.class }, [
                          _vm._v(_vm._s(item.name))
                        ])
                      }),
                      0
                    )
                  : _vm._e(),
                _vm.editOrderData.orderStatus == 2 ||
                _vm.editOrderData.orderStatus == 3
                  ? _c(
                      "div",
                      { staticClass: "allparentlist" },
                      _vm._l(_vm.editOrderData.poiList, function(
                        childItem,
                        index
                      ) {
                        return _c(
                          "ul",
                          { key: index, staticClass: "childcontentlist" },
                          [
                            _c(
                              "li",
                              {
                                staticClass: "w10p",
                                attrs: {
                                  title: childItem.materialCode
                                    ? childItem.materialCode
                                    : ""
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    childItem.materialCode
                                      ? childItem.materialCode
                                      : ""
                                  )
                                )
                              ]
                            ),
                            _c(
                              "li",
                              {
                                staticClass: "w10p",
                                attrs: {
                                  title: childItem.confirmNumbers
                                    ? childItem.factorySeason
                                      ? childItem.factorySeason
                                      : ""
                                    : ""
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    childItem.confirmNumbers
                                      ? childItem.factorySeason
                                        ? childItem.factorySeason
                                        : ""
                                      : ""
                                  )
                                )
                              ]
                            ),
                            _c(
                              "li",
                              {
                                staticClass: "w10p",
                                attrs: {
                                  title: childItem.supplierShortName
                                    ? childItem.supplierShortName
                                    : ""
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    childItem.supplierShortName
                                      ? childItem.supplierShortName
                                      : ""
                                  )
                                )
                              ]
                            ),
                            _c(
                              "li",
                              {
                                staticClass: "w10p",
                                attrs: {
                                  title: childItem.currencyName
                                    ? childItem.currencyName
                                    : ""
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    childItem.currencyName
                                      ? childItem.currencyName
                                      : ""
                                  )
                                )
                              ]
                            ),
                            _c(
                              "li",
                              {
                                staticClass: "w10p",
                                attrs: {
                                  title: childItem.offeredPrice
                                    ? childItem.offeredPrice
                                    : "0"
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    childItem.offeredPrice
                                      ? childItem.offeredPrice
                                      : "0"
                                  )
                                )
                              ]
                            ),
                            _c(
                              "li",
                              {
                                staticClass: "w10p",
                                attrs: {
                                  title: childItem.offeredQuantity
                                    ? childItem.offeredQuantity
                                    : ""
                                }
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      childItem.offeredQuantity
                                        ? childItem.offeredQuantity
                                        : ""
                                    ) +
                                    "\n                "
                                ),
                                childItem.purchaseMeasurementUnit != null &&
                                childItem.purchaseMeasurementUnit != ""
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          childItem.purchaseMeasurementUnit
                                        )
                                      )
                                    ])
                                  : _c("span", [_vm._v("条")])
                              ]
                            ),
                            _c(
                              "li",
                              {
                                staticClass: "w10p",
                                attrs: {
                                  title: childItem.offeredDate
                                    ? childItem.offeredDate
                                    : ""
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    childItem.offeredDate
                                      ? childItem.offeredDate
                                      : ""
                                  )
                                )
                              ]
                            ),
                            _c(
                              "li",
                              {
                                staticClass: "w10p",
                                attrs: {
                                  title: childItem.confirmNumbers
                                    ? childItem.confirmNumbers
                                    : ""
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    childItem.confirmNumbers
                                      ? childItem.confirmNumbers
                                      : ""
                                  )
                                )
                              ]
                            ),
                            _c(
                              "li",
                              {
                                staticClass: "w10p",
                                attrs: {
                                  title: childItem.confirmTimeRecently
                                    ? childItem.confirmTimeRecently
                                    : ""
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    childItem.confirmTimeRecently
                                      ? childItem.confirmTimeRecently
                                      : ""
                                  )
                                )
                              ]
                            ),
                            _c(
                              "li",
                              {
                                staticClass: "w10p",
                                attrs: {
                                  title: childItem.cargoReadyDateRecently
                                    ? childItem.cargoReadyDateRecently
                                    : ""
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    childItem.cargoReadyDateRecently
                                      ? childItem.cargoReadyDateRecently
                                      : ""
                                  )
                                )
                              ]
                            )
                          ]
                        )
                      }),
                      0
                    )
                  : _vm._e()
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: {
            visible: _vm.revokeDialogVisible,
            "close-on-click-modal": false,
            "before-close": _vm.revokeCancel,
            title: "提示",
            width: "30%"
          },
          on: {
            "update:visible": function($event) {
              _vm.revokeDialogVisible = $event
            }
          }
        },
        [
          _c("div", { staticClass: "isremark" }, [_vm._v("确定撤销？")]),
          _c(
            "div",
            { staticClass: "display_ib mr10 mb10" },
            [
              _c("el-input", {
                staticClass: "input_single mr10",
                attrs: { rows: 3, type: "textarea", placeholder: "撤销原因" },
                model: {
                  value: _vm.cancelRemark,
                  callback: function($$v) {
                    _vm.cancelRemark = $$v
                  },
                  expression: "cancelRemark"
                }
              })
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("el-button", { on: { click: _vm.revokeCancel } }, [
                _vm._v("取 消")
              ]),
              _c(
                "el-button",
                {
                  attrs: { disabled: _vm.isDisabled, type: "textarea" },
                  on: { click: _vm.revokeDeleteHandle }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      ),
      _vm.dialogVisible
        ? _c("Dialog", {
            attrs: {
              dialogDesc: _vm.dialogDesc,
              dialogVisible: _vm.dialogVisible
            },
            on: {
              dialogConfirmHandle: _vm.dialogConfirmHandle,
              dialogCancelHandle: _vm.dialogCancelHandle
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "tag" }, [
      _c("span", { staticClass: "red mr5" }, [_vm._v("*")]),
      _vm._v("采购订单编号：11")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "tag" }, [
      _c("span", { staticClass: "red mr5" }, [_vm._v("*")]),
      _vm._v("采购订单类型：")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "tag" }, [
      _c("span", { staticClass: "red mr5" }, [_vm._v("*")]),
      _vm._v("供应商：")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "tag" }, [
      _c("span", { staticClass: "red mr5" }, [_vm._v("*")]),
      _vm._v("贸易条款：")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "tag" }, [
      _c("span", { staticClass: "red mr5" }, [_vm._v("*")]),
      _vm._v("币种：")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "tag" }, [
      _c("span", { staticClass: "red mr5" }, [_vm._v("*")]),
      _vm._v("付款方式：")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "tag" }, [
      _c("span", { staticClass: "red mr5" }, [_vm._v("*")]),
      _vm._v("预付：")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container purchaserequest" },
    [
      _c("div", { staticClass: "actions_part clearfix" }, [
        _c("div", { staticClass: "actions_wrap" }, [
          _c(
            "div",
            { staticClass: "display_ib mr10 mb10" },
            [
              _c("span", { staticClass: "tag" }, [_vm._v("请求编号：")]),
              _c("el-input", {
                staticClass: "input_single w200 mr10",
                attrs: { placeholder: "请输入" },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.searchHandle($event)
                  }
                },
                model: {
                  value: _vm.purchaseRequestCode,
                  callback: function($$v) {
                    _vm.purchaseRequestCode = $$v
                  },
                  expression: "purchaseRequestCode"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "display_ib mr10 mb10" },
            [
              _c("span", { staticClass: "tag" }, [_vm._v("物料编号：")]),
              _c("el-input", {
                staticClass: "input_single w200 mr10",
                attrs: { placeholder: "请输入" },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.searchHandle($event)
                  }
                },
                model: {
                  value: _vm.materialCode,
                  callback: function($$v) {
                    _vm.materialCode = $$v
                  },
                  expression: "materialCode"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "display_ib mr10 mb10" },
            [
              _c("span", { staticClass: "tag" }, [_vm._v("销售订单编号：")]),
              _c("el-input", {
                staticClass: "input_single w200 mr10",
                attrs: { placeholder: "请输入" },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.searchHandle($event)
                  }
                },
                model: {
                  value: _vm.saleOrderId,
                  callback: function($$v) {
                    _vm.saleOrderId = $$v
                  },
                  expression: "saleOrderId"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "display_ib mr10 mb10" },
            [
              _c("span", { staticClass: "tag" }, [_vm._v("特殊要求：")]),
              _c("el-input", {
                staticClass: "input_single w200 mr10",
                attrs: { placeholder: "请输入" },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.searchHandle($event)
                  }
                },
                model: {
                  value: _vm.specialRequire,
                  callback: function($$v) {
                    _vm.specialRequire = $$v
                  },
                  expression: "specialRequire"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "display_ib mr10 mb10" },
            [
              _c("span", { staticClass: "tag" }, [_vm._v("请求类型：")]),
              _c(
                "el-select",
                {
                  staticClass: "select_single w260 mr10",
                  attrs: { placeholder: "请选择", multiple: "" },
                  on: { change: _vm.purchaseTypeHandle },
                  model: {
                    value: _vm.purchaseType,
                    callback: function($$v) {
                      _vm.purchaseType = $$v
                    },
                    expression: "purchaseType"
                  }
                },
                [
                  _c("el-option", {
                    key: "",
                    attrs: { label: "全部", value: "" },
                    nativeOn: {
                      click: function($event) {
                        return _vm.selectAll($event)
                      }
                    }
                  }),
                  _vm._l(_vm.purchaseTypeList, function(item) {
                    return _c("el-option", {
                      key: item.dictItemValue,
                      attrs: {
                        label: item.dictItemName,
                        value: item.dictItemValue
                      }
                    })
                  })
                ],
                2
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "display_ib mr10 mb10" },
            [
              _c("span", { staticClass: "tag" }, [_vm._v("供应商：")]),
              _c(
                "el-select",
                {
                  staticClass: "select_single w200 mr10",
                  attrs: { filterable: "", placeholder: "请选择" },
                  on: { change: _vm.searchHandle },
                  model: {
                    value: _vm.supplierCode,
                    callback: function($$v) {
                      _vm.supplierCode = $$v
                    },
                    expression: "supplierCode"
                  }
                },
                [
                  _c("el-option", {
                    key: "",
                    attrs: { label: "全部", value: "" }
                  }),
                  _vm._l(_vm.supplierList, function(item) {
                    return _c("el-option", {
                      key: item.supplierId,
                      attrs: {
                        label: item.supplierCode,
                        value: item.supplierId
                      }
                    })
                  })
                ],
                2
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "display_ib mr10 mb10" },
            [
              _c("span", { staticClass: "tag" }, [_vm._v("规格：")]),
              _c(
                "el-select",
                {
                  staticClass: "select_single w200 mr10",
                  attrs: { placeholder: "请选择" },
                  on: { change: _vm.searchHandle },
                  model: {
                    value: _vm.specificationsType,
                    callback: function($$v) {
                      _vm.specificationsType = $$v
                    },
                    expression: "specificationsType"
                  }
                },
                [
                  _c("el-option", {
                    key: "",
                    attrs: { label: "全部", value: "" }
                  }),
                  _vm._l(_vm.specificationsList, function(item) {
                    return _c("el-option", {
                      key: item.value,
                      attrs: { label: item.name, value: item.value }
                    })
                  })
                ],
                2
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "display_ib mr10 mb10" },
            [
              _c("span", { staticClass: "tag" }, [_vm._v("快货：")]),
              _c(
                "el-select",
                {
                  staticClass: "select_single w200 mr10",
                  attrs: { placeholder: "请选择" },
                  on: { change: _vm.searchHandle },
                  model: {
                    value: _vm.mobileGoods,
                    callback: function($$v) {
                      _vm.mobileGoods = $$v
                    },
                    expression: "mobileGoods"
                  }
                },
                [
                  _c("el-option", {
                    key: "",
                    attrs: { label: "全部", value: "" }
                  }),
                  _vm._l(_vm.ifYesOrNoList, function(item) {
                    return _c("el-option", {
                      key: item.index,
                      attrs: { label: item.name, value: item.index }
                    })
                  })
                ],
                2
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "display_ib mr10 mb10" },
            [
              _c("span", { staticClass: "tag" }, [_vm._v("EV产品：")]),
              _c(
                "el-select",
                {
                  staticClass: "select_single w200 mr10",
                  attrs: { placeholder: "请选择" },
                  on: { change: _vm.searchHandle },
                  model: {
                    value: _vm.belongToEv,
                    callback: function($$v) {
                      _vm.belongToEv = $$v
                    },
                    expression: "belongToEv"
                  }
                },
                [
                  _c("el-option", {
                    key: "",
                    attrs: { label: "全部", value: "" }
                  }),
                  _vm._l(_vm.ifYesOrNoList, function(item) {
                    return _c("el-option", {
                      key: item.index,
                      attrs: { label: item.name, value: item.index }
                    })
                  })
                ],
                2
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "display_ib mr10 mb10" },
            [
              _c("span", { staticClass: "tag" }, [_vm._v("采购删除：")]),
              _c(
                "el-select",
                {
                  staticClass: "select_single w200 mr10",
                  attrs: { placeholder: "请选择" },
                  on: { change: _vm.searchHandle },
                  model: {
                    value: _vm.purchaseDeleteStatus,
                    callback: function($$v) {
                      _vm.purchaseDeleteStatus = $$v
                    },
                    expression: "purchaseDeleteStatus"
                  }
                },
                _vm._l(_vm.ifYesOrNoList, function(item) {
                  return _c("el-option", {
                    key: item.index,
                    attrs: { label: item.name, value: item.index }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "display_ib mr10 mb10" },
            [
              _c("span", { staticClass: "tag" }, [_vm._v("创建日期：")]),
              _c("el-date-picker", {
                staticClass: "mr10",
                attrs: {
                  "unlink-panels": "",
                  type: "daterange",
                  format: "yyyy-MM-dd",
                  "value-format": "yyyy-MM-dd",
                  "range-separator": "至",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期"
                },
                on: { change: _vm.dateValChange },
                model: {
                  value: _vm.dateVal,
                  callback: function($$v) {
                    _vm.dateVal = $$v
                  },
                  expression: "dateVal"
                }
              })
            ],
            1
          )
        ]),
        _c(
          "div",
          { staticClass: "actions_btn_wrap down t_right" },
          [
            _c("el-button", {
              attrs: {
                size: "small",
                type: "primary",
                icon: "el-icon-search",
                circle: "",
                title: "搜索"
              },
              on: { click: _vm.searchHandle }
            }),
            _c(
              "el-button",
              {
                attrs: { type: "primary" },
                on: { click: _vm.createOrderHandle }
              },
              [_vm._v("生成采购订单")]
            ),
            _c(
              "el-button",
              {
                attrs: { type: "primary" },
                on: { click: _vm.mergeCreateOrderHandle }
              },
              [_vm._v("合并已有采购订单")]
            )
          ],
          1
        )
      ]),
      _c("div", { staticClass: "table_part clearfix" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.listLoading,
                expression: "listLoading"
              }
            ],
            staticClass: "single_tab"
          },
          [
            _c(
              "el-table",
              {
                ref: "multipleTable",
                staticClass: "table_list",
                attrs: {
                  data: _vm.requestList,
                  "row-key": "id",
                  border: "",
                  fit: "",
                  stripe: "",
                  "expand-row-keys": _vm.expands
                },
                on: {
                  "expand-change": _vm.expandChange,
                  select: _vm.selectHandle,
                  "select-all": _vm.selectAllHandle,
                  "row-click": _vm.rowClick
                }
              },
              [
                _c("el-table-column", { attrs: { type: "selection" } }),
                _c("el-table-column", {
                  attrs: { type: "expand" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c(
                            "el-table",
                            {
                              ref: "childMultipleTable_" + scope.row.id,
                              staticClass: "table_list",
                              attrs: {
                                data: scope.row.priList,
                                "max-height": 500,
                                border: "",
                                fit: ""
                              },
                              on: {
                                select: function(selection, row) {
                                  _vm.childSelectHandle(
                                    selection,
                                    row,
                                    scope.row
                                  )
                                },
                                "select-all": function(selection) {
                                  _vm.childSelectAllHandle(selection, scope.row)
                                }
                              }
                            },
                            [
                              _c("el-table-column", {
                                attrs: { type: "selection" }
                              }),
                              _c("el-table-column", {
                                attrs: { label: "行号" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var row = ref.row
                                        return [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(row.sn) +
                                              "\n                  "
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "物料编号",
                                  "min-width": "180",
                                  "class-name": "marpno_wuliao",
                                  "show-overflow-tooltip": true
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var row = ref.row
                                        return [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(row.materialCode) +
                                              "\n                  "
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              }),
                              _c("el-table-column", {
                                attrs: { label: "客户名称" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var row = ref.row
                                        return [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(row.customerName) +
                                              "\n                  "
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              }),
                              _c("el-table-column", {
                                attrs: { label: "供应商" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var row = ref.row
                                        return [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(row.supplierShortName) +
                                              "\n                  "
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              }),
                              _c("el-table-column", {
                                attrs: { label: "期望交期" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var row = ref.row
                                        return [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(row.requiredDate) +
                                              "\n                  "
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              }),
                              _c("el-table-column", {
                                attrs: { label: "请求数量" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var row = ref.row
                                        return [
                                          row.purchaseMeasurementUnit == "米"
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    (row.requiredPackages
                                                      ? row.requiredPackages
                                                      : 0) +
                                                      "包 " +
                                                      (row.requiredQuantityPerPackage
                                                        ? row.requiredQuantityPerPackage
                                                        : 0) +
                                                      "米/包 " +
                                                      (row.requiredQuantity
                                                        ? row.requiredQuantity
                                                        : 0) +
                                                      "米"
                                                  )
                                                )
                                              ])
                                            : _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    (row.requiredQuantity
                                                      ? row.requiredQuantity
                                                      : 0) +
                                                      (row.purchaseMeasurementUnit
                                                        ? row.purchaseMeasurementUnit
                                                        : "条")
                                                  )
                                                )
                                              ])
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              }),
                              _c("el-table-column", {
                                attrs: { label: "销售订单编号" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var row = ref.row
                                        return [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(row.saleOrderId) +
                                              "\n                  "
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              }),
                              _c("el-table-column", {
                                attrs: { label: "物料工厂季" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var row = ref.row
                                        return [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                row.materialFactorySeason
                                              ) +
                                              "\n                  "
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              }),
                              _c("el-table-column", {
                                attrs: { label: "特殊要求" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var row = ref.row
                                        return [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(row.specialRequire) +
                                              "\n                  "
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              }),
                              _c("el-table-column", {
                                attrs: { label: "原确认书编号" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var row = ref.row
                                        return [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(row.originalConfirmNum) +
                                              "\n                  "
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              }),
                              _c("el-table-column", {
                                attrs: { label: "备注" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var row = ref.row
                                        return [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(row.remark) +
                                              "\n                  "
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              }),
                              _c("el-table-column", {
                                attrs: { label: "快货" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var row = ref.row
                                        return [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                row.mobileGoods === true
                                                  ? "是"
                                                  : "否"
                                              ) +
                                              "\n                  "
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              }),
                              _c("el-table-column", {
                                attrs: { label: "EV产品" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var row = ref.row
                                        return [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                row.belongToEv === true
                                                  ? "是"
                                                  : "否"
                                              ) +
                                              "\n                  "
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              }),
                              _c("el-table-column", {
                                attrs: { label: "采购删除" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var row = ref.row
                                        return [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                row.purchaseDeleteStatus ===
                                                  true
                                                  ? "是"
                                                  : "否"
                                              ) +
                                              "\n                  "
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              })
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: { label: "请求编号" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        return [
                          _vm._v(
                            "\n              " +
                              _vm._s(row.purchaseRequestCode) +
                              "\n            "
                          )
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: { label: "请求类型" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        return [
                          _vm._v(
                            "\n              " +
                              _vm._s(row.purchaseTypeName) +
                              "\n            "
                          )
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: { label: "创建时间" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        return [
                          _vm._v(
                            "\n              " +
                              _vm._s(row.createTime) +
                              "\n            "
                          )
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: { label: "申请人" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        return [
                          _vm._v(
                            "\n              " +
                              _vm._s(row.applicantName) +
                              "\n            "
                          )
                        ]
                      }
                    }
                  ])
                })
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "pagination_wrap fr" },
          [
            _c("el-pagination", {
              attrs: {
                "current-page": _vm.currentPage,
                "page-sizes": [10, 50],
                "page-size": _vm.pageSize,
                layout: "sizes, total, prev, pager, next",
                total: _vm.total
              },
              on: {
                "size-change": _vm.handleSizeChange,
                "current-change": _vm.handleCurrentChange,
                "update:currentPage": function($event) {
                  _vm.currentPage = $event
                },
                "update:current-page": function($event) {
                  _vm.currentPage = $event
                }
              }
            })
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "popup_part popup" },
        [
          _c(
            "el-dialog",
            {
              directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
              attrs: {
                title: _vm.popupTitle,
                "close-on-click-modal": false,
                "close-on-press-escape": false,
                visible: _vm.dialogFormVisible
              },
              on: {
                "update:visible": function($event) {
                  _vm.dialogFormVisible = $event
                }
              }
            },
            [
              _c(
                "el-tabs",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.dialogLoading,
                      expression: "dialogLoading"
                    }
                  ]
                },
                _vm._l(_vm.generateRequestList, function(item, index) {
                  return _c("div", { key: index }, [
                    _c(
                      "ul",
                      { staticClass: "childlist" },
                      _vm._l(_vm.generateFieldNameList, function(item, index) {
                        return _c("li", { key: index, class: item.class }, [
                          _vm._v(_vm._s(item.name))
                        ])
                      }),
                      0
                    ),
                    _c(
                      "div",
                      { staticClass: "allparentlist" },
                      _vm._l(item, function(cItem, cIndex) {
                        return _c(
                          "ul",
                          { key: cIndex, staticClass: "childcontentlist" },
                          [
                            _c(
                              "li",
                              {
                                staticClass: "w13p",
                                attrs: {
                                  title: cItem.materialCode
                                    ? cItem.materialCode
                                    : ""
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    cItem.materialCode ? cItem.materialCode : ""
                                  )
                                )
                              ]
                            ),
                            _c(
                              "li",
                              {
                                staticClass: "w17p",
                                attrs: {
                                  title: cItem.customerName
                                    ? cItem.customerName
                                    : ""
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    cItem.customerName ? cItem.customerName : ""
                                  )
                                )
                              ]
                            ),
                            _c(
                              "li",
                              {
                                staticClass: "w14p",
                                attrs: {
                                  title: cItem.supplierShortName
                                    ? cItem.supplierShortName
                                    : ""
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    cItem.supplierShortName
                                      ? cItem.supplierShortName
                                      : ""
                                  )
                                )
                              ]
                            ),
                            _c(
                              "li",
                              {
                                staticClass: "w13p",
                                attrs: {
                                  title: cItem.requiredDate
                                    ? cItem.requiredDate
                                    : ""
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    cItem.requiredDate ? cItem.requiredDate : ""
                                  )
                                )
                              ]
                            ),
                            _c("li", { staticClass: "w17p" }, [
                              cItem.purchaseMeasurementUnit == "米"
                                ? _c(
                                    "span",
                                    {
                                      attrs: {
                                        title:
                                          (cItem.requiredPackages
                                            ? cItem.requiredPackages
                                            : "0") +
                                          "包 " +
                                          (cItem.requiredQuantityPerPackage
                                            ? cItem.requiredQuantityPerPackage
                                            : "0") +
                                          "米/包 " +
                                          (cItem.requiredQuantity
                                            ? cItem.requiredQuantity
                                            : "0") +
                                          "米"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(
                                            (cItem.requiredPackages
                                              ? cItem.requiredPackages
                                              : "0") + "包 "
                                          ) +
                                          "\n                    " +
                                          _vm._s(
                                            (cItem.requiredQuantityPerPackage
                                              ? cItem.requiredQuantityPerPackage
                                              : "0") + "米/包 "
                                          ) +
                                          "\n                    " +
                                          _vm._s(
                                            (cItem.requiredQuantity
                                              ? cItem.requiredQuantity
                                              : "0") +
                                              cItem.purchaseMeasurementUnit
                                          ) +
                                          "\n                  "
                                      )
                                    ]
                                  )
                                : _c(
                                    "span",
                                    {
                                      attrs: {
                                        title:
                                          (cItem.requiredQuantity
                                            ? cItem.requiredQuantity
                                            : "0") +
                                          (cItem.purchaseMeasurementUnit
                                            ? cItem.purchaseMeasurementUnit
                                            : "条")
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          (cItem.requiredQuantity
                                            ? cItem.requiredQuantity
                                            : "0") +
                                            (cItem.purchaseMeasurementUnit
                                              ? cItem.purchaseMeasurementUnit
                                              : "条")
                                        )
                                      )
                                    ]
                                  )
                            ]),
                            _c(
                              "li",
                              {
                                staticClass: "w13p",
                                attrs: {
                                  title: cItem.materialFactorySeason
                                    ? cItem.materialFactorySeason
                                    : ""
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    cItem.materialFactorySeason
                                      ? cItem.materialFactorySeason
                                      : ""
                                  )
                                )
                              ]
                            ),
                            _c(
                              "li",
                              {
                                staticClass: "w13p",
                                attrs: {
                                  title: cItem.specialRequire
                                    ? cItem.specialRequire
                                    : ""
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    cItem.specialRequire
                                      ? cItem.specialRequire
                                      : ""
                                  )
                                )
                              ]
                            ),
                            _c(
                              "li",
                              {
                                staticClass: "w13p",
                                attrs: {
                                  title: cItem.originalConfirmNum
                                    ? cItem.originalConfirmNum
                                    : ""
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    cItem.originalConfirmNum
                                      ? cItem.originalConfirmNum
                                      : ""
                                  )
                                )
                              ]
                            ),
                            _c(
                              "li",
                              {
                                staticClass: "w13p",
                                attrs: {
                                  title: cItem.remark ? cItem.remark : ""
                                }
                              },
                              [_vm._v(_vm._s(cItem.remark ? cItem.remark : ""))]
                            )
                          ]
                        )
                      }),
                      0
                    )
                  ])
                }),
                0
              ),
              _c("p", [_vm._v("确认要生成这个订单吗？")]),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          _vm.dialogFormVisible = false
                        }
                      }
                    },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    {
                      directives: [
                        { name: "preventReClick", rawName: "v-preventReClick" }
                      ],
                      attrs: { type: "primary" },
                      on: { click: _vm.saveOrderConfirm }
                    },
                    [_vm._v("确 定")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: {
            title: "合并已有采购订单",
            visible: _vm.dialogMergeVisible,
            "close-on-click-modal": false,
            width: "30%"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogMergeVisible = $event
            },
            close: _vm.closeDialog
          }
        },
        [
          _c(
            "div",
            { staticClass: "display_ib mr10 mb10" },
            [
              _c("span", { staticClass: "red mr10" }, [_vm._v("*")]),
              _c("span", { staticClass: "tag" }, [_vm._v("采购订单编号：")]),
              _c("el-input", {
                staticClass: "input_single w200 mr10",
                attrs: { placeholder: "请输入采购订单编码" },
                model: {
                  value: _vm.purchaseOrderCode,
                  callback: function($$v) {
                    _vm.purchaseOrderCode = $$v
                  },
                  expression: "purchaseOrderCode"
                }
              })
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      return _vm.cancelDialogMergeVisible()
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    { name: "preventReClick", rawName: "v-preventReClick" }
                  ],
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.mergeVisibleclick()
                    }
                  }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: {
            title: "提示",
            visible: _vm.dialogSupplierVisible,
            "close-on-click-modal": false,
            width: "30%"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogSupplierVisible = $event
            },
            close: _vm.closeDialog
          }
        },
        [
          _c("span", [_vm._v("该采购申请包含多个供应商，是否继续生成？")]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      return _vm.cancelSupplierVisibleclick()
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    { name: "preventReClick", rawName: "v-preventReClick" }
                  ],
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.supplierVisibleclick()
                    }
                  }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
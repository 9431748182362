/* eslint-disable no-array-constructor */
<template>
  <div class="container purchaseOrder">
    <div v-if="!isShowEdit" class="part">
      <div class="actions_part clearfix">
        <div class="actions_wrap">
          <div class="display_ib mr10 mb10">
            <span class="tag">采购订单编号：</span>
            <el-input v-model="purchaseOrderId" class="input_single w200 mr10" placeholder="请输入" @keyup.enter.native="searchHandle"/>
          </div>
          <div class="display_ib mr10 mb10">
            <span class="tag">采购申请编号：</span>
            <el-input v-model="poIdOrPrCode" class="input_single w200 mr10" placeholder="请输入" @keyup.enter.native="searchHandle"/>
          </div>
          <div class="display_ib mr10 mb10">
            <span class="tag">物料编号：</span>
            <el-input v-model="materialCode" class="input_single w200 mr10" placeholder="请输入" @keyup.enter.native="searchHandle"/>
          </div>
          <div class="display_ib mr10 mb10">
            <span class="tag">采购订单类型：</span>
            <el-select
              v-model="purchaseOrderType"
              class="select_single w200 mr10"
              placeholder="请选择"
              @change="searchHandle">
              <el-option key="" label="全部" value=""/>
              <el-option
                v-for="item in orderTypeList"
                :key="item.index"
                :label="item.name"
                :value="item.index"/>
            </el-select>
          </div>
          <div class="display_ib mr10 mb10">
            <span class="tag">供应商：</span>
            <el-select
              v-model="supplierCode"
              class="select_single w200 mr10"
              filterable
              placeholder="请选择"
              @change="searchHandle">
              <el-option key="" label="全部" value=""/>
              <el-option
                v-for="item in supplierList"
                :key="item.supplierId"
                :label="item.supplierCode"
                :value="item.supplierId"/>
            </el-select>
          </div>
          <div class="display_ib mr10 mb10">
            <span class="tag">创建日期：</span>
            <el-date-picker
              v-model="dateVal"
              unlink-panels
              type="daterange"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="dateValChange"
            />
          </div>
          <div class="display_ib mr10 mb10">
            <span class="tag">申请人：</span>
            <el-input v-model="applicantName" class="input_single w200 mr10" placeholder="请输入" @keyup.enter.native="searchHandle"/>
          </div>
          <div class="display_ib mr10 mb10">
            <span class="tag">销售订单编号：</span>
            <el-input class="input_single w200 mr10" v-model="saleOrderId" placeholder="请输入" @keyup.enter.native="searchHandle"></el-input>
          </div>
        </div>
        <div class="actions_btn_wrap down t_right">
          <el-button type="primary" @click="excelDownload">导出Excel</el-button>
          <el-button
            size="small"
            type="primary"
            icon="el-icon-search"
            circle
            title="搜索"
            @click="searchHandle"
          />
          <!-- <el-button
            size="medium"
            type="primary"
            icon="el-icon-upload"
            circle
            title="上传"
            @click="uploadHandle"
          ></el-button>
          <el-button
            size="small"
            type="primary"
            icon="el-icon-download"
            circle
            title="下载"
            @click="downloadHandle"
          ></el-button> -->
        </div>
      </div>

      <div class="table_part clearfix">
        <el-tabs v-model="activeName" class="subnav_wrap" @tab-click="switchTabHandle">
          <el-tab-pane
            v-loading="tableLoading"
            v-for="item in subNavList"
            :key="item.value"
            :label="item.label"
            :name="item.value">
            <el-table
              ref="multipleTable"
              :data="orderList"
              :expand-row-keys="expands"
              class="table_list"
              row-key="id"
              border
              fit
              stripe
              @row-click="rowClick"
              @expand-change="rowClick">
              <el-table-column type="expand">
                <template slot-scope="scope">
                  <div class="actions_wrap mb20 clearfix">
                    <el-button class="fr" type="primary" @click="childExcelDownload(scope.row)">导出Excel</el-button>
                    <el-button class="fr mr10" type="primary" @click="mergeChildExcelDownload(scope.row)">合并导出Excel</el-button>
                    <el-button v-if="scope.row.orderStatus == '1' || scope.row.orderStatus == '5'" class="fr" type="primary" @click="editHandle(scope.$index, scope.row)">更新导入Excel</el-button>
                  </div>
                  <pl-table
                    :ref="'childMultipleTable_' + scope.row.id"
                    :data="scope.row.poiList"
                    :max-height="700"
                    :use-virtual="true"

                    :row-height="90"
                    class="table_list"
                    style="width: 100%;height:100%"
                    border
                    fit>
                    <el-table-column fixed label="行号">
                      <template slot-scope="{ row }">
                        <p :class="{isThrough:row.cancelStatus}">{{ row.purchaseOrderSn }}</p>
                      </template>
                    </el-table-column>
                    <pl-table-column :show-overflow-tooltip="true" fixed label="物料编号" min-width="180" class-name="marpno_wuliao">
                      <template slot-scope="{ row }">
                        <p :class="{isThrough:row.cancelStatus}"> {{ row.materialCode }}</p>
                      </template>
                    </pl-table-column>
                    <!-- <el-table-column label="销售订单编号">
                      <template slot-scope="{ row }">
                        {{ row.saleOrderId }}
                      </template>
                    </el-table-column> -->
                    <pl-table-column label="采购价格" min-width="100">
                      <template slot-scope="{ row }">
                        <p :class="{isThrough:row.cancelStatus}">{{ row.offeredPrice }}</p>
                      </template>
                    </pl-table-column>
                    <pl-table-column :show-overflow-tooltip="true" label="请求数量" min-width="100" class-name="marpno_wuliao">
                      <template slot-scope="{ row }">
                        <p :class="{isThrough:row.cancelStatus}">{{ (row.requiredQuantity ? row.requiredQuantity : 0) + (row.purchaseMeasurementUnit ? row.purchaseMeasurementUnit : '条') }}</p>
                      </template>
                    </pl-table-column>
                    <pl-table-column :show-overflow-tooltip="true" label="采购数量" min-width="180" class-name="marpno_wuliao">
                      <template slot-scope="{ row }">
                        <span v-if="row.purchaseMeasurementUnit == '米'"> <p :class="{isThrough:row.cancelStatus}">{{ (row.offeredPackages ? row.offeredPackages : 0) + '包 ' + (row.offeredQuantityPerPackage ? row.offeredQuantityPerPackage : 0) + '米/包 ' + (row.offeredQuantity ? row.offeredQuantity : 0) + '米' }}</p></span>
                        <span v-else> <p :class="{isThrough:row.cancelStatus}">{{ (row.offeredQuantity ? row.offeredQuantity : 0) + (row.purchaseMeasurementUnit ? row.purchaseMeasurementUnit : '条') }}</p></span>
                      </template>
                    </pl-table-column>
                    <pl-table-column :show-overflow-tooltip="true" label="期望交期" min-width="180" class-name="marpno_wuliao">
                      <template slot-scope="{ row }">
                        <p :class="{isThrough:row.cancelStatus}">{{ row.requiredDate }}</p>
                      </template>
                    </pl-table-column>
                    <pl-table-column :show-overflow-tooltip="true" label="工厂交期" min-width="180" class-name="marpno_wuliao">
                      <template slot-scope="{ row }">
                        <p :class="{isThrough:row.cancelStatus}">{{ row.offeredDate }}</p>
                      </template>
                    </pl-table-column>
                    <pl-table-column v-if="scope.row.orderStatus == '1'" label="物料工厂季" min-width="180">
                      <template slot-scope="{ row }">
                        <p :class="{isThrough:row.cancelStatus}">{{ row.materialFactorySeason }}</p>
                      </template>
                    </pl-table-column>
                    <pl-table-column label="快货" min-width="100">
                      <template slot-scope="{ row }">
                        <p :class="{isThrough:row.cancelStatus}">{{ row.mobileGoods }}</p>
                      </template>
                    </pl-table-column>
                    <pl-table-column label="EV" min-width="100">
                      <template slot-scope="{ row }">
                        <p :class="{isThrough:row.cancelStatus}">{{ row.belongToEv }}</p>
                      </template>
                    </pl-table-column>
                    <pl-table-column label="采购备注" min-width="180">
                      <template slot-scope="{ row }">
                        <p :class="{isThrough:row.cancelStatus}">{{ row.purchaseRemark }}</p>
                      </template>
                    </pl-table-column>
                    <pl-table-column label="备注" min-width="200">
                      <template slot-scope="{ row }">
                        <p :class="{isThrough:row.cancelStatus}">{{ row.remark }}</p>
                      </template>
                    </pl-table-column>
                    <pl-table-column label="特殊要求" min-width="180">
                      <template slot-scope="{ row }">
                        <p :class="{isThrough:row.cancelStatus}">{{ row.specialRequire }}</p>
                      </template>
                    </pl-table-column>
                    <pl-table-column label="期望LT" min-width="180">
                      <template slot-scope="{ row }">
                        <p :class="{isThrough:row.cancelStatus}">{{ row.offerLt }}</p>
                      </template>
                    </pl-table-column>
                    <pl-table-column label="MTM" min-width="180">
                      <template slot-scope="{ row }">
                        <p :class="{isThrough:row.cancelStatus}">{{ row.listOfCuttingLength }}</p>
                      </template>
                    </pl-table-column>
                    <pl-table-column label="销售订单编号" min-width="180">
                      <template slot-scope="{ row }">
                        <p :class="{isThrough:row.cancelStatus}">{{ row.saleOrderId }}</p>
                      </template>
                    </pl-table-column>
                    <pl-table-column label="请求类型" min-width="180">
                      <template slot-scope="{ row }">
                        <p :class="{isThrough:row.cancelStatus}">{{ row.prTypeName }}</p>
                      </template>
                    </pl-table-column>
                    <pl-table-column label="客户名称" min-width="180">
                      <template slot-scope="{ row }">
                        <p :class="{isThrough:row.cancelStatus}">{{ row.customerName }}</p>
                      </template>
                    </pl-table-column>
                    <pl-table-column label="原确认书编号" min-width="180">
                      <template slot-scope="{ row }">
                        <p :class="{isThrough:row.cancelStatus}">{{ row.originalConfirmNum }}</p>
                      </template>
                    </pl-table-column>
                    <pl-table-column v-if="scope.row.orderStatus == '3'||scope.row.orderStatus == '4'||scope.row.orderStatus == '5'" label="操作" fixed="right">
                      <template slot-scope="{ row, $index }">
                        <!-- <el-button class="el-icon-document-delete" type="danger" circle size="mini" :title="row.cancelStatus === true ? '已取消' : '取消'" :disabled="row.cancelStatus === true" @click.stop="revokeHandle($index, row)"></el-button> -->
                        <el-button :disabled="row.cancelStatus === true" class="el-icon-document-delete" type="danger" circle size="mini" title="撤销" @click.stop="revokeHandle($index, row)"/>
                      </template>
                    </pl-table-column>
                    <pl-table-column v-if="scope.row.orderStatus == '1'" label="操作" fixed="right" width="100">
                      <template slot-scope="{ row, $index }">
                        <div style="display:inline-flex;">
                          <el-button :disabled="row.cancelStatus === true" class="el-icon-delete" type="danger" circle size="mini" title="删除" @click.stop="editDeleteHandle($index, row)"/>
                          <el-button :disabled="row.cancelStatus === true" class="el-icon-document-delete" type="danger" circle size="mini" title="撤销" @click.stop="revokeHandle($index, row)"/>
                        </div>
                      </template>
                    </pl-table-column>
                  </pl-table>
                </template>
              </el-table-column>
              <el-table-column label="订单编号">
                <template slot-scope="{ row }">
                  {{ row.purchaseOrderId }}
                </template>
              </el-table-column>
              <el-table-column label="采购订单类型">
                <template slot-scope="{ row }">
                  {{ row.purchaseOrderTypeName }}
                </template>
              </el-table-column>
              <el-table-column label="供应商">
                <template slot-scope="{ row }">
                  <!-- {{ row.supplierName }} -->
                  {{ row.supplierShortName }}
                </template>
              </el-table-column>
              <!-- <el-table-column label="贸易条款">
                <template slot-scope="{ row }">
                  {{ row.tradeTermsName }}
                </template>
              </el-table-column> -->
              <el-table-column label="币种">
                <template slot-scope="{ row }">
                  {{ row.currencyName }}
                </template>
              </el-table-column>
              <!-- <el-table-column label="客户名">
                <template slot-scope="{ row }">
                  <span v-if="row.poiList[0].prTypeName != '日常备货'">{{ row.customerName }}</span>
                  <span v-else></span>
                </template>
              </el-table-column> -->
              <el-table-column label="付款方式">
                <template slot-scope="{ row }">
                  {{ row.paymentMethodNames }}
                </template>
              </el-table-column>
              <!-- <el-table-column label="单据状态">
                <template slot-scope="{ row }">
                   {{row.ticketStatus==1?'正常':'关闭'}}
                </template>
              </el-table-column> -->
              <el-table-column label="创建时间">
                <template slot-scope="{ row }">
                  {{ row.createTime }}
                </template>
              </el-table-column>
              <el-table-column label="申请人">
                <template slot-scope="{ row }">
                  {{ row.applicantName }}
                </template>
              </el-table-column>
              <el-table-column label="凭证状态">
                <template slot-scope="{ row, $index }">
                  <el-popover
                    placement="right"
                    width="300"
                    trigger="hover"
                    open-delay="1000"
                    @show="checkApprovalProcess(row, $index)">
                    <div>
                      <i class="block">
                        审批人：
                        <em>{{ row.fullName }}</em>
                      </i>
                      <i class="block mt10 mb10">
                        审批时间：
                        <em>{{ row.approvalTime }}</em>
                      </i>
                      <i class="block">
                        审批意见：
                        <em>{{ row.content }}</em>
                      </i>
                    </div>
                    <el-tag slot="reference" :type="row.orderStatus | statusFilter" class="pointer">{{ row.orderStatusName }}</el-tag>
                  </el-popover>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="150">
                <template slot-scope="{ row, $index }">
                  <div v-if="row.orderStatus == '1' || row.orderStatus == '5'">
                    <el-button class="el-icon-edit" type="primary" circle size="mini" title="编辑" @click.stop="editHandle($index, row)"/>
                    <el-button class="el-icon-check" type="success" circle size="mini" title="提交审批" @click.stop="submitHandle($index, row)"/>
                    <el-button class="el-icon-delete" type="danger" circle size="mini" title="删除" @click.stop="deleteHandle($index, row)"/>
                  </div>
                  <div v-if="row.orderStatus == '2'">
                    <el-button class="el-icon-document" type="primary" circle size="mini" title="查看" @click.stop="checkHandle($index, row)"/>
                  </div>
                  <div v-if="row.orderStatus == '3'">
                    <el-button class="el-icon-document" type="primary" circle size="mini" title="查看" @click.stop="checkHandle($index, row)"/>
                    <el-button class="el-icon-switch-button" type="danger" circle size="mini" title="关闭" @click.stop="closeHandle($index, row,'close')"/>
                  </div>
                  <div v-if="row.orderStatus == '7'">
                    <el-button class="el-icon-switch-button" type="danger" disabled circle size="mini" title="关闭"/>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
        </el-tabs>
        <div class="pagination_wrap fr">
          <el-pagination
            :current-page.sync="currentPage"
            :page-sizes="[10, 50]"
            :page-size="pageSize"
            :total="total"
            layout="sizes, total, prev, pager, next"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"/>
        </div>
      </div>
    </div>

    <!-- 编辑页 -->
    <div v-else class="edit_part">
      <div class="clearfix mb10">
        <div class="display_ib mr10 mb10">
          <span class="tag"><span class="red mr5">*</span>采购订单编号：11</span>
          <el-input v-model="editOrderData.purchaseOrderId" class="input_single w200 mr10" maxlength="17" placeholder="请输入"/>
        </div>
        <div class="display_ib mr10 mb10">
          <span class="tag"><span class="red mr5">*</span>采购订单类型：</span>
          <el-select
            v-model="editOrderData.purchaseOrderType"
            class="select_single w200 mr10"
            placeholder="请选择"
            @change="orderTypeChange($event, editOrderData)">
            <el-option key="" label="请选择" value=""/>
            <el-option
              v-for="item in orderTypeList"
              :key="item.index"
              :label="item.name"
              :value="item.index"/>
          </el-select>
        </div>
        <div class="display_ib mr10 mb10">
          <span class="tag"><span class="red mr5">*</span>供应商：</span>
          <span class="tag">{{ editOrderData.supplierShortName }}</span>
          <!-- <span class="tag">{{editOrderData.supplierName}}</span> -->
        </div>
        <div class="display_ib mr20">
          <span class="tag"><span class="red mr5">*</span>贸易条款：</span>
          <el-select
            v-model="editOrderData.tradeTerms"
            class="select_single w200 mr10 mb10"
            placeholder="请选择"
            @change="tradeTermsSelect">
            <el-option key="" label="请选择" value=""/>
            <el-option
              v-for="item in tradeTermsList"
              :key="item.index"
              :label="item.name"
              :value="item.index"/>
          </el-select>
        </div>
        <div class="display_ib mr10 mb10">
          <span class="tag"><span class="red mr5">*</span>币种：</span>
          <el-select
            v-model="editOrderData.currencyName"
            class="select_single w200"
            placeholder="请选择"
            @change="currencyNameChange($event, editOrderData)">
            <el-option key="" label="请选择" value=""/>
            <el-option
              v-for="item in currencyList"
              :key="item.index"
              :label="item.name"
              :value="item.name"/>
          </el-select>
        </div>
        <div class="display_ib mr10 mb10">
          <span class="tag">确认书工厂季：</span>
          <el-select v-model.trim="editOrderData.factorySeason" class="w200" placeholder="请选择" @change="factorySeasonChange($event, editOrderData)">
            <el-option key="" label="请选择" value=""/>
            <el-option v-for="item in saleSeasonList" :key="item.index" :value="item.name" :label="item.name"/>
          </el-select>
        </div>
        <div class="display_ib mr10 mb10">
          <span class="tag">采购价单：</span>
          <el-select v-model.trim="editOrderData.priceListName" class="w200" placeholder="请选择" @change="priceListNameChange($event, editOrderData)">
            <el-option key="" label="请选择" value=""/>
            <el-option v-for="item in priceOrderList" :key="item" :value="item" :label="item"/>
          </el-select>
        </div>
        <div class="display_ib mr10 mb10">
          <span class="tag"><span class="red mr5">*</span>付款方式：</span>
          <el-select v-model.trim="editOrderData.paymentMethod" :disabled="!isEmptyData" class="w200" placeholder="请选择" @change="paymentMethodChange">
            <el-option key="" label="请选择" value=""/>
            <el-option v-for="item in paymentMethodList" :key="item.dictItemValue" :value="item.dictItemValue" :label="item.dictItemName"/>
          </el-select>
        </div>

        <div class="display_ib mr10 mb10">
          <span class="tag">采购总量：</span>
          <span class="tag">{{ editOrderData.totalQuantity }}</span>
        </div>
        <div class="display_ib mr20 mb10">
          <span class="tag">采购总金额：</span>
          <span class="tag">{{ editOrderData.totalAmount }}</span>
        </div>

        <div v-if="editOrderData.paymentMethod == 'downPayment'" class="display_ib mr10 mb10">
          <span class="tag"><span class="red mr5">*</span>预付：</span>
          <el-select v-if="!isDownPaymentInput" v-model.trim="editOrderData.downPaymentOrPeriod" class="w200" placeholder="请选择" @change="downPaymentChange($event, editOrderData)">
            <el-option v-for="item in downPaymentList" :key="item.dictItemValue" :value="item.dictItemValue" :label="item.dictItemName"/>
            <el-option key="" label="手动输入" value=""/>
          </el-select>
          <span v-else class="w200">
            <el-input v-model="editOrderData.downPaymentOrPeriod" class="w200" placeholder="请输入" @change="downPaymentInputChange($event, editOrderData)" @input.native="downPaymentInputHandle"/>
            <span class="tag ml10 mr10">%</span>
            <el-button size="mini" round @click="downPaymentReselect">重新选择</el-button>
          </span>
        </div>
      </div>
      <div class="edit_wrap hidden">
        <upload-excel-component :on-success="importExcelSuccess" :before-upload="importExcelBeforeUpload" class="mb20" />
        <div class="height60">
          <el-button size="medium" type="primary" class="mb10 fr" @click="mergeOrderHandle">合并订单行</el-button>
        </div>
        <!-- 编辑组件 -->
        <!-- <editTable
          :type="'selection'"
          ref="clearChild"
          :tableList="editOrderData.poiList"
          :tableLeaderFieldsList="leaderFieldsList"
          :tableTailFieldsList="tailFieldsList"
          :tableFieldsList="editFieldsList"
          @deleteHandle="deleteEditItemHandle"
          @inputBlur="inputBlur"
          @unitNumberInputChange="unitNumberInputChange"
          @unitNumberInputBlur="unitNumberInputBlur"
           @selectionChange="editSelectionChange">
        </editTable> -->

        <editTable
          ref="clearChild"
          :type="'selection'"
          :table-list="editOrderData.poiList"
          :total="editOrderData.poiList && editOrderData.poiList.length ? editOrderData.poiList.length : 0"
          :table-leader-fields-list="leaderFieldsList"
          :table-tail-fields-list="tailFieldsList"
          :table-fields-list="editFieldsList"
          @deleteHandle="deleteEditItemHandle"
          @inputBlur="inputBlur"
          @unitNumberInputChange="unitNumberInputChange"
          @unitNumberInputBlur="unitNumberInputBlur"
          @selectionChange="editSelectionChange"
        />

        <div class="edit_btn_wrap mt20 fr">
          <el-button @click="cancelEditHandle">取消</el-button>
          <el-button v-preventReClick type="primary" @click="saveEditHandle">保存</el-button>
          <el-button v-preventReClick type="primary" @click="submitEditHandle">提交审批</el-button>
        </div>

      </div>
    </div>

    <!-- 子项详情弹窗 -->
    <div class="popup_part">
      <el-dialog
        v-dialogDrag
        :title="popupTitle"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :visible.sync="dialogFormVisible">
        <el-tabs>
          <!-- <ul class="childlist" v-if="editOrderData.orderStatus == 2">
            <li :class="item.class" v-for="(item, index) in checkFieldList" :key="index">{{item.name}}</li>
          </ul> -->
          <!-- 审批中 -->
          <!-- <div class="allparentlist" v-if="editOrderData.orderStatus == 2">
            <ul
              class="childcontentlist"
              v-for="(childItem, index) in editOrderData.poiList"
              :key="index">
              <li class="w10p" :title="childItem.materialCode ? childItem.materialCode : ''">{{childItem.materialCode ? childItem.materialCode : ''}}</li>
              <li class="w10p" :title="childItem.supplierName ? childItem.supplierName : ''">{{childItem.supplierName ? childItem.supplierName : ''}}</li>
              <li class="w10p" :title="childItem.offeredPrice ? childItem.offeredPrice : ''">{{childItem.offeredPrice ? childItem.offeredPrice : ''}}</li>
              <li class="w10p">
	<span :title="(childItem.offeredQuantity ? childItem.offeredQuantity : '0') + childItem.purchaseMeasurementUnit" v-if="childItem.purchaseMeasurementUnit!=null&&childItem.purchaseMeasurementUnit!=''">
		{{(childItem.offeredQuantity ? childItem.offeredQuantity : '0') + childItem.purchaseMeasurementUnit}}
	</span>
 <span v-else :title="(childItem.offeredQuantity ? childItem.offeredQuantity : '0') + '条'">{{(childItem.offeredQuantity ? childItem.offeredQuantity : '0') +'条'}}</span>
              </li>
              <li class="w10p" :title="childItem.offeredDate ? childItem.offeredDate : ''">{{childItem.offeredDate ? childItem.offeredDate : ''}}</li>
              <li class="w10p" :title="childItem.specialRequire ? childItem.specialRequire : ''">{{childItem.specialRequire ? childItem.specialRequire : ''}}</li>
              <li class="w10p" :title="childItem.auditOption ? childItem.auditOption : ''">{{childItem.auditOption ? childItem.auditOption : ''}}</li>
              <li class="w10p" :title="childItem.cargoReadyDateRecently ? childItem.cargoReadyDateRecently : ''">{{childItem.cargoReadyDateRecently ? childItem.cargoReadyDateRecently : ''}}</li>
            </ul>
          </div> -->
          <!-- 已通过 -->

          <ul v-if="editOrderData.orderStatus == 2 || editOrderData.orderStatus == 3" class="childlist">
            <li v-for="(item, index) in checkApprovedFieldList" :class="item.class" :key="index">{{ item.name }}</li>
          </ul>
          <div v-if="editOrderData.orderStatus == 2 || editOrderData.orderStatus == 3" class="allparentlist">
            <ul
              v-for="(childItem, index) in editOrderData.poiList"
              :key="index"
              class="childcontentlist">
              <li :title="childItem.materialCode ? childItem.materialCode : ''" class="w10p">{{ childItem.materialCode ? childItem.materialCode : '' }}</li>
              <li :title="childItem.confirmNumbers ? (childItem.factorySeason ? childItem.factorySeason : '') : ''" class="w10p">{{ childItem.confirmNumbers ? (childItem.factorySeason ? childItem.factorySeason : '') : '' }}</li>
              <li :title="childItem.supplierShortName ? childItem.supplierShortName : ''" class="w10p">{{ childItem.supplierShortName ? childItem.supplierShortName : '' }}</li>
              <!-- <li class="w10p" :title="childItem.supplierName ? childItem.supplierName : ''">{{childItem.supplierName ? childItem.supplierName : ''}}</li> -->
              <li :title="childItem.currencyName ? childItem.currencyName : ''" class="w10p">{{ childItem.currencyName ? childItem.currencyName : '' }}</li>
              <li :title="childItem.offeredPrice ? childItem.offeredPrice : '0'" class="w10p">{{ childItem.offeredPrice ? childItem.offeredPrice : '0' }}</li>
              <li :title="childItem.offeredQuantity ? childItem.offeredQuantity : ''" class="w10p">
                {{ childItem.offeredQuantity ? childItem.offeredQuantity : '' }}
                <span v-if="childItem.purchaseMeasurementUnit!=null&&childItem.purchaseMeasurementUnit!=''">{{ childItem.purchaseMeasurementUnit }}</span>
                <span v-else>条</span>
              </li>
              <li :title="childItem.offeredDate ? childItem.offeredDate : ''" class="w10p">{{ childItem.offeredDate ? childItem.offeredDate : '' }}</li>
              <li :title="childItem.confirmNumbers ? childItem.confirmNumbers : ''" class="w10p">{{ childItem.confirmNumbers ? childItem.confirmNumbers : '' }}</li>
              <li :title="childItem.confirmTimeRecently ? childItem.confirmTimeRecently : ''" class="w10p">{{ childItem.confirmTimeRecently ? childItem.confirmTimeRecently : '' }}</li>
              <li :title="childItem.cargoReadyDateRecently ? childItem.cargoReadyDateRecently : ''" class="w10p">{{ childItem.cargoReadyDateRecently ? childItem.cargoReadyDateRecently : '' }}</li>
            </ul>
          </div>
        </el-tabs>
      </el-dialog>
    </div>

    <!-- 撤销弹窗 -->
    <el-dialog
      v-dialogDrag
      :visible.sync="revokeDialogVisible"
      :close-on-click-modal="false"
      :before-close="revokeCancel"
      title="提示"
      width="30%">
      <div class="isremark">确定撤销？</div>
      <div class="display_ib mr10 mb10">
        <el-input v-model="cancelRemark" :rows="3" class="input_single mr10" type="textarea" placeholder="撤销原因"/>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="revokeCancel">取 消</el-button>
        <el-button :disabled="isDisabled" type="textarea" @click="revokeDeleteHandle">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 对话框 -->
    <Dialog v-if="dialogVisible" :dialogDesc="dialogDesc" :dialogVisible="dialogVisible" @dialogConfirmHandle="dialogConfirmHandle" @dialogCancelHandle="dialogCancelHandle"/>
  </div>
</template>

<script>
import {
  purchaseOrderList,
  searchPurchaseOrderList,
  deletePurchaseOrder,
  deletePurchaseOrderItem,
  modifyPurchaseOrder,
  approvalProcessInfo,
  constDictList,
  dictList,
  supplierDictList,
  getPriceOrderList,
  batchPriceByMaterialCode,
  revokePurchaseOrderItem,
  closeTransferOrder
} from '@/api/module/purchaseApi.js';
import {
  timeFormat,
  timeFormatGMT,
  verificationQuantity,
  excelDateFormatToDate,
  getTimeRange,
  trimStr
} from '@/utils/index.js';
import {
  getUUID
} from '@/api/publicApi.js';
import UploadExcelComponent from '@/components/UploadExcel/index.vue';
import Table from '@/layouts/components/common/Table.vue';
import Dialog from '@/layouts/components/common/Dialog.vue';
// import editTable from '@/layouts/components/common/EditTableList.vue';
import editTable from '@/layouts/components/common/EditTablePagination.vue';
export default {
  name: 'PurchaseOrder',
  components: {
    Table,
    editTable,
    Dialog,
    UploadExcelComponent
    // saveOldOrderStatus(){
    //   this.oldOrderStatus=this.this.editOrderData.orderStatus;
    // }
  },
  filters: {
    statusFilter(status) {
      const statusMap = {
        '1': '',
        '2': 'warning',
        '3': 'success',
        '5': 'danger',
        '6': 'info',
        '7': 'danger'
      };
      return statusMap[status];
    }
  },
  props: ['subName'],
  data() {
    return {
      // Tab列表
      subNavList: [
        {
          label: '全部采购订单',
          value: ''
        },
        {
          label: '草稿',
          value: 'Draft'
        },
        {
          label: '审批中',
          value: 'Approving'
        },
        {
          label: '已通过',
          value: 'Approved'
        },
        {
          label: '已驳回',
          value: 'Rejected'
        }
        // {
        //   label: '已终止',
        //   value: 'Terminated'
        // }
      ],

      // Tabble组件列表
      multipleSelection: [],
      childMultipleSelection: [],
      expands: [],

      // NEW TBALE
      selectUuidList: [],
     
        // 对话框
      saleOrderId: '',
      dialogVisible: false,  // 对话框是否可见
      dialogDesc: '',
      submitId: '',
      deleteId: '',
      deleteItemId: '',
      revokeId: '',
      closedId: '',
      revokeCode: '',
      poIdOrPrCode: '',
      applicantName: '',
      tableLoading: true,
      type: '',
      orderCode: '',
      requestCode: '',
      dateVal: '',
      beginTime: '',
      endTime: '',
      customerId: '',
      orderStatus: '',
      purchaseOrderId: '',
      materialCode: '',
      supplierCode: '',
      purchaseOrderType: '',
      uuid: '',
      actionName: '',
      orderList: [],
      editOrderData: {},
      total: 0,
      pageSize: 10, // 每页显示条数
      currentPage: 1, // 页数
      activeName: 'All',
      popupTitle: '',
      dialogFormVisible: false,
      revokeDialogVisible: false,
      submitParamsData: {},
      revokeUuid: '',
      selectOrderData: '',
      tradeTermsList: [],
      checkList: [],
      modifyOrderList: {},
      editOrderType: '',
      curEditItem: null,
      isEmptyData: true,
      isDisabled: false,
      parentData: {
        status: '',
        data: [
          {
            name: '',
            field: 'createTime',
            class: 'w8p',
            isShowTit: true
          },
          {
            name: '申请人',
            field: 'applicantName',
            class: 'w6p',
            isShowTit: true
          },
          {
            name: '订单编号',
            field: 'purchaseOrderId',
            class: 'w8p',
            isShowTit: true
          },
          {
            name: '请求类型',
            field: 'prTypeName',
            class: 'w8p',
            isShowTit: true
          },
          {
            name: '供应商',
            // field: 'supplierName',
            field: 'supplierShortName',
            class: 'w8p',
            isShowTit: true
          },
          {
            name: '币种',
            field: 'currencyName',
            class: 'w8p',
            isShowTit: true
          },
          {
            name: '销售订单编号',
            field: 'saleOrderId',
            class: 'w8p',
            isShowTit: true,
            isFilterField: true
          },
          {
            name: '客户名称',
            field: 'customerName',
            class: 'w8p',
            isShowTit: true,
            isFilterField: true
          },
          {
            name: '付款方式',
            field: 'paymentMethodJoin',
            class: 'w8p',
            isShowTit: true
          },
          {
            name: '凭证状态',
            field: '',
            class: '',
            isShowTit: false,
            isPopover: true
          },
          {
            name: '',
            field: '',
            class: 'fr mr15',
            isShowTit: false,
            isUpDown: true
          },
          {
            name: '',
            field: '',
            class: 'fr',
            isShowTit: false,
            isAction: true
          }
        ]
      },
      childData: {
        name: 'poiList',
        data: [
          {
            name: '',
            field: 'materialCode',
            class: 'w10p'
          },
          // {
          //   name: '',
          //   field: 'materialId',
          //   class: 'w10p'
          // },
          {
            name: '',
            field: 'offeredPrice',
            class: 'w5p'
          },
          {
            name: '',
            field: 'requiredQuantity',
            class: 'w5p'
          },
          {
            name: '调拨数量',
            field_1: 'offeredPackages',
            field_2: 'offeredQuantityPerPackage',
            field_3: 'offeredQuantity',
            field: 'offeredQuantity',
            class: 'w15p',
            isQuantityField: true
          },
          {
            name: '',
            field: 'requiredDate',
            class: 'w10p'
          },
          {
            name: '',
            field: 'offeredDate',
            class: 'w10p'
          },
          {
            name: '',
            field: 'specialRequire',
            class: 'w10p'
          },
          {
            name: '',
            field: 'offerLt',
            class: 'w10p'
          },
          {
            name: '',
            field: 'listOfCuttingLength',
            class: 'w10p'
          },
          {
            name: '',
            field: '',
            class: 'w10p pr',
            isAction: true
          }
        ]
      },
      allFieldNameList: [
        {
          name: '物料编号',
          class: 'w9p'
        },
        {
          name: '工厂季',
          class: 'w9p'
        },
        {
          name: '供应商',
          class: 'w11p'
        },
        {
          name: '采购价格',
          class: 'w9p'
        },
        {
          name: '采购数量',
          class: 'w12p'
        },
        {
          name: '工厂交期',
          class: 'w9p'
        },
        {
          name: '确认书号',
          class: 'w11p'
        },
        {
          name: '投产日期',
          class: 'w9p'
        },
        {
          name: '货妥日期',
          class: 'w9p'
        },
        {
          name: '折扣备注',
          class: 'w12p'
        }
      ],
      draftFieldNameList: [
        {
          name: '物料编号',
          class: 'w9p'
        },
        {
          name: '供应商',
          class: 'w11p'
        },
        {
          name: '采购价格',
          class: 'w9p'
        },
        {
          name: '请求数量',
          class: 'w12p'
        },
        {
          name: '采购数量',
          class: 'w12p'
        },
        {
          name: '期望交期',
          class: 'w9p'
        },
        {
          name: '工厂交期',
          class: 'w9p'
        },
        {
          name: '特殊要求',
          class: 'w12p'
        },
        // {
        //   name: 'VBCMTM',
        //   class: 'w11p'
        // },
        {
          name: '期望LT',
          class: 'w9p'
        }
      ],
      editDraftFieldNameList: [
        {
          name: '物料编号',
          class: 'w15p',
          isRules: false
        },
        {
          name: '供应商',
          class: 'w15p',
          isRules: false
        },
        {
          name: '采购价格',
          class: 'w15p',
          isRules: true
        },
        {
          name: '采购数量',
          class: 'w40p',
          isRules: true
        },
        {
          name: '工厂交期',
          class: 'w15p',
          isRules: true
        }
      ],
      approvingFieldNameList: [
        {
          name: '物料编号',
          class: 'w9p'
        },
        {
          name: '供应商',
          class: 'w11p'
        },
        {
          name: '采购价格',
          class: 'w9p'
        },
        {
          name: '采购数量',
          class: 'w12p'
        },
        {
          name: '工厂交期',
          class: 'w9p'
        },
        {
          name: '特殊要求',
          class: 'w12p'
        },
        {
          name: '审批状态',
          class: 'w9p'
        },
        {
          name: '审批意见',
          class: 'w11p'
        }
      ],

      offeredDateOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now();
        }
      },

      isShowList: false,
      dialogdelVisible: false,
      isShowEdit: false,
      isSaveDisabled: false,
      isSubmitDisabled: false,
      priceListName: '',
      orderTypeList: [],
      supplierList: [],
      currencyList: [],
      saleSeasonList: [],
      priceOrderList: [],
      subNavList: [
        {
          label: '全部采购订单',
          value: 'All'
        },
        {
          label: '草稿',
          value: 'Draft'
        },
        {
          label: '审批中',
          value: 'Approving'
        },
        {
          label: '已通过',
          value: 'Approved'
        },
        {
          label: '已驳回',
          value: 'Rejected'
        }
        // {
        //   label: '已终止',
        //   value: 'Terminated'
        // }
      ],
      fieldList: [
        {
          name: '物料编号',
          class: 'w10p'
        },
        // {
        //   name: '物料系统编号',
        //   class: 'w10p'
        // },
        // {
        //   name: '销售订单编号',
        //   class: 'w10p'
        // },
        {
          name: '采购价格',
          class: 'w10p'
        },
        {
          name: '请求数量',
          class: 'w10p'
        },
        {
          name: '采购数量',
          class: 'w15p'
        },
        {
          name: '期望交期',
          class: 'w10p'
        },
        {
          name: '工厂交期',
          class: 'w10p'
        },
        {
          name: '特殊要求',
          class: 'w10p'
        },
        {
          name: '期望LT',
          class: 'w5p'
        },
        {
          name: 'MTM',
          class: 'w5p'
        }
        // {
        //   name: '',
        //   class: 'w10p'
        // }
      ],
      parentFieldsList: [ // Table父列表表头
        {
          name: '订单编号',
          class: 'w15p'
        },
        {
          name: '请求类型',
          class: 'w10p'
        },
        {
          name: '供应商',
          class: 'w8p'
        },
        {
          name: '贸易条款',
          class: 'w8p'
        },
        {
          name: '币种',
          class: 'w5p'
        },
        {
          name: '客户名',
          class: 'w8p'
        },
        {
          name: '付款方式',
          class: 'w8p'
        },
        {
          name: '创建时间',
          class: 'w10p'
        },
        {
          name: '申请人',
          class: 'w6p'
        },
        {
          name: '审批',
          class: ''
        }
      ],
      editFieldList: [
        {
          name: '物料编号',
          class: 'w10p'
        },
        {
          name: '采购数量',
          class: 'w30p'
        },
        {
          name: '价单价格',
          class: 'w10p'
        },
        {
          name: '采购价格',
          class: 'w15p'
        },
        {
          name: '系统折扣',
          class: 'w10p'
        },
        {
          name: '工厂交期',
          class: 'w15p'
        }
      ],
      checkFieldList: [
        {
          name: '物料编号',
          class: 'w10p'
        },
        {
          name: '供应商',
          class: 'w10p'
        },
        {
          name: '采购价格/欧元',
          class: 'w10p'
        },
        {
          name: '采购数量',
          class: 'w10p'
        },
        {
          name: '工厂交期',
          class: 'w10p'
        },
        {
          name: '特殊要求',
          class: 'w10p'
        },
        {
          name: '审批意见',
          class: 'w10p'
        },
        {
          name: '货妥日期',
          class: 'w10p'
        }
      ],
      checkApprovedFieldList: [
        {
          name: '物料编号',
          class: 'w10p'
        },
        {
          name: '确认书工厂季',
          class: 'w10p'
        },
        {
          name: '供应商',
          class: 'w10p'
        },
        {
          name: '币种',
          class: 'w10p'
        },
        {
          name: '采购价格',
          class: 'w10p'
        },
        {
          name: '采购数量',
          class: 'w10p'
        },
        {
          name: '工厂交期',
          class: 'w10p'
        },
        {
          name: '确认书号',
          class: 'w10p'
        },
        {
          name: '投产日期',
          class: 'w10p'
        },
        {
          name: '货妥日期',
          class: 'w10p'
        }
      ],

      inputData: '',
      cancelRemark: '',
      typeList: [
        {
          typeid: 'BusinessAttire',
          typename: '职业装'
        },
        {
          typeid: 'ZeroShear',
          typename: '零剪'
        }
      ],

      paymentMethodList: [],
      paymentMethodListClone: [],
      payMethod: '',
      periodList: [],
      period: '',
      isPeriodInput: false,
      isDownPaymentInput: false,
      downPayment: '',
      downPaymentList: [],
      showMethod: '',
      isEdit: false,
      editPartLoading: false,
      // 编辑组件数据
      editFieldsList: [],
      originalEditFieldsList: [
        {
          name: '采购数量',
          field: 'offeredQuantity',
          field_1: 'offeredPackages',
          field_2: 'offeredQuantityPerPackage',
          field_3: 'offeredQuantity',
          field_unit: 'purchaseMeasurementUnit',
          class: 'w23p',
          width: '400'
        },
        {
          name: '价单价格',
          field: 'priceListPrice',
          // field_unit: 'purchaseMeasurementUnit',
          class: 'w16p',
          width: '150',
          isQuantityField: true
        },
        {
          name: '采购价格',
          field: 'offeredPrice',
          class: 'w13p',
          width: '150'
        },
        {
          name: '系统折扣',
          field: 'systemDiscount',
          class: 'w13p',
          width: '150'
        },
        {
          name: '工厂交期',
          field: 'offeredDate',
          class: 'w18p',
          width: '180'
        },
        {
          name: '物料工厂季',
          field: 'materialFactorySeason',
          class: 'w13p',
          width: '150'
        },
        {
          name: '采购备注',
          field: 'purchaseRemark',
          class: 'w13p',
          width: '150'
        },
        {
          name: '备注',
          field: 'remark',
          class: 'w13p',
          width: '150'
        },
        {
          name: '特殊要求',
          field: 'specialRequire',
          class: 'w13p',
          width: '150'
        },
        {
          name: '快货',
          field: 'mobileGoods',
          class: 'w13p',
          width: '150'
        },
        {
          name: '原确认书编号',
          field: 'originalConfirmNum',
          class: 'w13p',
          width: '150'
        },
        {
          name: 'EV产品',
          field: 'belongToEv',
          class: 'w13p',
          width: '150'
        },
        {
          name: 'MTM',
          field: 'listOfCuttingLength',
          class: 'w13p',
          width: '150',
          isQuantityField: true
        }
      ],
      standardEditFieldsList: [
        {
          name: '采购数量',
          field: 'offeredQuantity',
          field_1: 'offeredPackages',
          field_2: 'offeredQuantityPerPackage',
          field_3: 'offeredQuantity',
          field_unit: 'purchaseMeasurementUnit',
          class: 'w23p',
          width: '400'
        },
        {
          name: '价单价格',
          field: 'priceListPrice',
          // field_unit: 'purchaseMeasurementUnit',
          class: 'w16p',
          width: '150',
          isQuantityField: true
        },
        {
          name: '采购价格',
          field: 'offeredPrice',
          class: 'w13p',
          width: '150'
        },
        {
          name: '系统折扣',
          field: 'systemDiscount',
          class: 'w13p',
          width: '150'
        },
        {
          name: '工厂交期',
          field: 'offeredDate',
          class: 'w18p',
          width: '180'
        },
        {
          name: '期望交期',
          field: 'requiredDate',
          class: 'w18p',
          width: '180'
        },
        {
          name: '物料工厂季',
          field: 'materialFactorySeason',
          class: 'w13p',
          width: '150'
        },
        {
          name: '采购备注',
          field: 'purchaseRemark',
          class: 'w13p',
          width: '150'
        },
        {
          name: '备注',
          field: 'remark',
          class: 'w13p',
          width: '150'
        },
        {
          name: '特殊要求',
          field: 'specialRequire',
          class: 'w13p',
          width: '150'
        },
        {
          name: '快货',
          field: 'mobileGoods',
          class: 'w13p',
          width: '150'
        },
        {
          name: 'EV产品',
          field: 'belongToEv',
          class: 'w13p',
          width: '150'
        },
        {
          name: 'MTM',
          field: 'listOfCuttingLength',
          class: 'w13p',
          width: '150',
          isQuantityField: true
        }
      ],
      leaderFieldsList: [
        {
          name: '行号',
          field: 'purchaseOrderSn',
          class: 'w10p',
          width: '50'
        },
        {
          name: '物料编号',
          field: 'materialCode',
          class: 'w10p',
          width: '180',
          isText: true
        }
      ],
      tailFieldsList: [
        {
          name: '操作',
          field: '',
          class: '',
          width: '100',
          isAction: true,
          hasDelete: true
        }
      ],
      // 编辑字段呈现方式集合（默认添加模式）
      editModeSet: {},
      textSet: {
        purchaseOrderSn: true,
        materialCode: true,
        priceListPrice: true,
        systemDiscount: true,
        materialFactorySeason: true,
        specialRequire: true,
        purchaseRemark: true,
        requiredDate: true,
        // remark:true,
        mobileGoods: true,
        belongToEv: true,
        listOfCuttingLength: true,
        originalConfirmNum: true
      },
      inputSet: {
        // offeredPrice: {
        //   type: 'number'
        // },
        remark: true,
        offeredPrice: true,
        inputData: {
          maxlength: '8',
          placeholder: ''
        }
      },
      selectSet: {
        selectData: {
          selectListName: '',
          value: '',
          label: ''
        }
      },
      datePickerSet: {
        offeredDate: true
      },
      unitSet: {
        offeredPackages: true
      },
      oldOrderStatus: '',

      childHeaderList: ['Ref', 'PO编号', 'PO行号', '关联PR编号', '关联的PR行号', '物料编号', '采购备注', '请求数量', '请求规格', '采购数量包数', '采购数量规格', '采购数量', '采购价格', '期望交期', '工厂交期', '备注', '特殊要求', '期望LT', 'BUNCH', '原确认书编号', 'EV', 'MTM', 'SCABAL工厂季', 'SCABAL织标数量'],
      childFieldsList: ['refCode', 'purchaseOrderId', 'purchaseOrderSn', 'prCode', 'priSn', 'materialCode', 'purchaseRemark', 'requiredQuantity', 'requiredQuantityPerPackage', 'offeredPackages', 'offeredQuantityPerPackage', 'offeredQuantity', 'offeredPrice', 'requiredDate', 'offeredDate', 'remark', 'specialRequire', 'offerLt', 'mobileGoods', 'originalConfirmNum', 'belongToEv', 'listOfCuttingLength', 'scabalSaleSeason', 'scabalWovenLabel']

    };
  },
  computed: {
    checkFieldNameList() {
      switch (this.activeName) {
        case 'Draft':
          return this.draftFieldNameList;
        case 'Approving':
          return this.approvingFieldNameList;
        case 'Official':
          return this.allFieldNameList;
        default:
          break;
      }
    }
  },
  watch: {
    subName(value, oldValue) {
      // console.log('subName: ', value, oldValue);
      this.activeName = value;
    }
  },
  beforeCreate() {

  },
  created() {
    this.activeName = this.subName ? this.subName : 'All';
    if (this.activeName == 'Draft') {
      this.orderStatus = 1;
      this.getPurchaseOrderList();
    }
  },
  mounted() {
    this.editFieldsList = this.standardEditFieldsList;
    // this.orderStatus = '1';
    this.getPurchaseOrderType();
    this.getSupplierDictList();
    this.getCurrencyList();
    this.getSaleSeasonList();
    // this.getPurchaseOrderList();
    this.fun_date();
    this.getPaymentMethodList();
    this.getPeriodList();
    this.getDownPaymentList();
    this.getTradeTermsList();
  },
  activated() {
    // this.activeName = this.subName;
  },
  methods: {
    async importExcelSuccess({ results, header }) {
      // 上传Excel成功
      console.log('导入数据：', results, header, this.curEditItem);
      if (results && results.length) {
        const editList = [];
        for (let i = 0; i < results.length; i++) {
          const item = results[i];
          const editData = {};
          Object.keys(item).map((key) => {
            console.log('导入Excel表数据：', key, item[key]); // key=>属性名  obj[key]=>属性值
            if (key == 'PO编号') {
              editData.purchaseOrderId = item[key];
            } else if (key == 'PO行号') {
              editData.purchaseOrderSn = item[key];
            } else if (key == '关联PR编号') {
              editData.prCode = item[key];
            } else if (key == '关联的PR行号') {
              editData.priSn = item[key];
            } else if (key == '物料编号') {
              editData.materialCode = item[key];
            } else if (key == '采购备注') {
              editData.purchaseRemark = item[key];
            } else if (key == '请求数量') {
              editData.requiredQuantity = item[key];
            } else if (key == '请求规格') {
              editData.requiredQuantityPerPackage = item[key] ? item[key] : 1;
            } else if (key == '采购数量包数') {
              editData.offeredPackages = item[key];
            } else if (key == '采购数量规格') {
              editData.offeredQuantityPerPackage = item[key];
            } else if (key == '采购数量') {
              editData.offeredQuantity = item[key];
            } else if (key == '采购价格') {
              editData.offeredPrice = item[key];
            } else if (key == '期望交期') {
              editData.requiredDate = item[key].toString().indexOf('-') !== -1 ? item[key] : excelDateFormatToDate(item[key]);
            } else if (key == '工厂交期') {
              editData.offeredDate = item[key].toString().indexOf('-') !== -1 ? item[key] : excelDateFormatToDate(item[key]);
            } else if (key == '备注') {
              editData.remark = item[key];
            } else if (key == '期望LT') {
              editData.offerLt = item[key];
            } else if (key == 'BUNCH') {
              editData.mobileGoods = item[key];
            } else if (key == '原确认书编号') {
              editData.originalConfirmNum = item[key];
            } else if (key == 'EV') {
              editData.belongToEv = item[key];
            } else if (key == 'MTM') {
              editData.listOfCuttingLength = item[key];
            } else if (key == 'SCABAL工厂季') {
              editData.scabalSaleSeason = item[key];
            } else if (key == 'SCABAL织标数量') {
              editData.scabalWovenLabel = item[key];
            }
          });
          editList.push(editData);
        }

        for (let i = 0; i < editList.length; i++) {
          const item = editList[i];
          for (let j = 0; j < this.curEditItem.poiList.length; j++) {
            let poiItem = this.curEditItem.poiList[j];
            if (item.purchaseOrderSn === poiItem.purchaseOrderSn) {
              poiItem = Object.assign(poiItem, item);
              if (poiItem.purchaseMeasurementUnit === '米') {
                poiItem.offeredQuantity = poiItem.offeredPackages * poiItem.offeredQuantityPerPackage;
              }
            }
          }
        }
        this.editOrderData.poiList = this.curEditItem.poiList;
      }
    },
    importExcelBeforeUpload(file) {
      // 上传Excel
      let isType = false;
      const fileName = file.name;
      const fileType = fileName.substr(fileName.lastIndexOf('.') + 1);
      const isXLSX = fileType === 'xlsx';
      const isLt1M = file.size / 1024 / 1024 < 1;
      if (!isLt1M) {
        this.$message({
          message: '请不要上传大于1M的文件！',
          type: 'error'
        });
        return false;
      }
      if (!isXLSX) {
        this.$message.error('仅支持上传.xlsx后缀文件！');
      }
      if (isXLSX) {
        this.editPartLoading = true;
        isType = true;
      }
      return isType;
    },
    // 固定展示为两周前的时间范围
    fun_date() {
      this.dateVal = getTimeRange(-90);
      this.searchHandle();
    },
    rowClick(row, event, column) {
      // 单击事件
      /*eslint no-extend-native: ["error", { "exceptions": ["Array"] }]*/
      Array.prototype.remove = function(val) {
        const index = this.indexOf(val);
        if (index > -1) {
          this.splice(index, 1);
        }
      };
      if (this.expands.indexOf(row.id) < 0) {
        this.expands.push(row.id);
      } else {
        this.expands.remove(row.id);
      }
    },
    excelDownload() {
      const headerList = [];
      const fieldsList = ['purchaseOrderId', 'prTypeName', 'supplierShortName', 'tradeTermsName', 'currencyName', 'customerName', 'paymentMethodName', 'createTime', 'applicantName', 'orderStatusName'];
      this.parentFieldsList.forEach(hItem => {
        headerList.push(hItem.name);
      });
      import('@/vendor/Export2Excel').then(excel => {
        const list = this.orderList;
        const data = this.formatJson(fieldsList, list);
        excel.export_json_to_excel({
          header: headerList,
          data,
          filename: '采购订单',
          autoWidth: true,
          bookType: 'xlsx'
        });
      });
    },
    childExcelDownload(item) {
      const headerList = this.childHeaderList;
      const fieldsList = this.childFieldsList;
      import('@/vendor/Export2Excel').then(excel => {
        for (const i in item.poiList) {
          const key = item.poiList[i];
          key.refCode = key.purchaseOrderId + '/' + key.purchaseOrderSn;
          // key.compressPurchaseOrderId=item.compressPurchaseOrderId;
          this.$forceUpdate();
        }
        const list = item.poiList;
        const data = this.formatJson(fieldsList, list);
        excel.export_json_to_excel({
          header: headerList,
          data,
          filename: item.purchaseOrderId,
          autoWidth: true,
          bookType: 'xlsx'
        });
      });
    },
    mergeChildExcelDownload(item) {
      // 合并导出
      // console.log('合并导出：', item);
      const itemCopy = JSON.parse(JSON.stringify(item));
      const newPoiList = [];
      const temp = {};
      // for (let i in itemCopy.poiList) {
      for (let i = 0; i < itemCopy.poiList.length; i++) {
        const key = itemCopy.poiList[i].materialCode + itemCopy.poiList[i].offeredQuantityPerPackage + itemCopy.poiList[i].requiredDate;
        if (temp[key]) {
          // 存在当前物料编号 & (采购规格 & 期望交期相同)
          // const ref = new Array(temp[key].purchaseOrderId, temp[key].purchaseOrderSn ? (temp[key].purchaseOrderSn + ',' + itemCopy.poiList[i].purchaseOrderSn) : temp[key].purchaseOrderSn);
          const ref = [temp[key].purchaseOrderId, temp[key].purchaseOrderSn ? (temp[key].purchaseOrderSn + ',' + itemCopy.poiList[i].purchaseOrderSn) : temp[key].purchaseOrderSn];
          temp[key].refCode = ref.join('/');// Ref
          // temp[key].compressPurchaseOrderId = itemCopy.compressPurchaseOrderId;  //加密订单号
          temp[key].purchaseOrderId = temp[key].purchaseOrderId; // PO编号
          temp[key].purchaseOrderSn = temp[key].purchaseOrderSn ? (temp[key].purchaseOrderSn + ',' + itemCopy.poiList[i].purchaseOrderSn) : temp[key].purchaseOrderSn; // PO行号
          temp[key].prCode = temp[key].prCode + ',' + itemCopy.poiList[i].prCode; // 关联PR编号
          // temp[key].priSn = temp[key].priSn;  // 关联的PR行号
          temp[key].priSn = temp[key].priSn + ',' + itemCopy.poiList[i].priSn; // 关联的PR行号
          temp[key].materialCode = temp[key].materialCode; // 物料编号
          temp[key].purchaseRemark = temp[key].purchaseRemark ? (temp[key].purchaseRemark + '；' + itemCopy.poiList[i].purchaseRemark) : temp[key].purchaseRemark; // 采购备注
          temp[key].requiredQuantity = temp[key].requiredQuantity + itemCopy.poiList[i].requiredQuantity; // 请求数量
          temp[key].requiredQuantityPerPackage = temp[key].requiredQuantityPerPackage; // 请求规格
          temp[key].offeredQuantity = temp[key].offeredQuantity + itemCopy.poiList[i].offeredQuantity; // 采购数量
          temp[key].offeredPrice = temp[key].offeredPrice; // 采购价格
          temp[key].requiredDate = temp[key].requiredDate; // 期望交期
          temp[key].offeredDate = temp[key].offeredDate; // 工厂交期
          temp[key].remark = temp[key].remark; // 备注
          temp[key].offerLt = temp[key].offerLt; // 期望LT
          temp[key].mobileGoods = temp[key].mobileGoods; // BUNCH
          temp[key].belongToEv = temp[key].belongToEv; // EV
          temp[key].originalConfirmNum = temp[key].originalConfirmNum;// 原确认书编号
          temp[key].listOfCuttingLength = temp[key].listOfCuttingLength + itemCopy.poiList[i].listOfCuttingLength; // MTM
          temp[key].scabalSaleSeason = temp[key].scabalSaleSeason; // SCABAL工厂季（不是叫料订单不填）
          temp[key].scabalWovenLabel = temp[key].scabalWovenLabel; // SCABAL织标数量（不是叫料订单不填）
          temp[key].offeredQuantityPerPackage = temp[key].offeredQuantityPerPackage; // 采购规格
        } else {
          temp[key] = {};
          // const ref = new Array(itemCopy.poiList[i].purchaseOrderId, itemCopy.poiList[i].purchaseOrderSn);
          const ref = [itemCopy.poiList[i].purchaseOrderId, itemCopy.poiList[i].purchaseOrderSn];
          temp[key].refCode = ref.join('/');// Ref
          // temp[key].compressPurchaseOrderId = itemCopy.compressPurchaseOrderId;  //加密订单号
          temp[key].purchaseOrderId = itemCopy.poiList[i].purchaseOrderId; // PO编号
          temp[key].purchaseOrderSn = itemCopy.poiList[i].purchaseOrderSn; // PO行号
          temp[key].prCode = itemCopy.poiList[i].prCode; // 关联PR编号
          temp[key].priSn = itemCopy.poiList[i].priSn; // 关联的PR行号
          temp[key].materialCode = itemCopy.poiList[i].materialCode; // 物料编号
          temp[key].purchaseRemark = itemCopy.poiList[i].purchaseRemark; // 采购备注
          temp[key].requiredQuantity = itemCopy.poiList[i].requiredQuantity; // 请求数量
          temp[key].requiredQuantityPerPackage = itemCopy.poiList[i].requiredQuantityPerPackage; // 请求规格
          temp[key].offeredQuantity = itemCopy.poiList[i].offeredQuantity; // 采购数量
          temp[key].offeredPrice = itemCopy.poiList[i].offeredPrice; // 采购价格
          temp[key].requiredDate = itemCopy.poiList[i].requiredDate; // 期望交期
          temp[key].offeredDate = itemCopy.poiList[i].offeredDate; // 工厂交期
          temp[key].remark = itemCopy.poiList[i].remark; // 备注
          temp[key].offerLt = itemCopy.poiList[i].offerLt; // 期望LT
          temp[key].mobileGoods = itemCopy.poiList[i].mobileGoods; // BUNCH
          temp[key].belongToEv = itemCopy.poiList[i].belongToEv; // EV
          temp[key].originalConfirmNum = temp[key].originalConfirmNum;// 原确认书编号
          temp[key].listOfCuttingLength = itemCopy.poiList[i].listOfCuttingLength; // MTM
          temp[key].scabalSaleSeason = itemCopy.poiList[i].scabalSaleSeason; // SCABAL工厂季
          temp[key].scabalWovenLabel = itemCopy.poiList[i].scabalWovenLabel; // SCABAL织标数量
          temp[key].offeredQuantityPerPackage = itemCopy.poiList[i].offeredQuantityPerPackage; // 请求规格
        }
      }
      for (const k in temp) {
        newPoiList.push(temp[k]);
      }
      itemCopy.poiList = newPoiList;
      this.childExcelDownload(itemCopy);
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]));
    },
    checkApprovalProcess(item, index) {
      approvalProcessInfo(item.uuid).then(res => {
        console.log('审批流程：', res);
        if (res.code === '0') {
          const data = res.data[res.data.length - 1];
          if (data == undefined) {
            item.fullName = '';
            item.approvalTime = '';
            item.content = '';
          } else {
            item.fullName = data.fullName;
            item.approvalTime = data.approvalTime;
            item.content = data.content;
          }
          this.$set(this.orderList, index, item);
          this.$forceUpdate();
        }
      });
    },
    // periodInputHandle({ target }) {
    //   this.verificationVal = target.value = target.value.replace(/[^0-9]/g, '');
    // },
    downPaymentInputHandle({ target }) {
      target.value = target.value.replace(/[^0-9]/g, '');
      if (target.value > 100) {
        this.editOrderData.downPaymentOrPeriod = 100;
      } else if (target.value < 0) {
        this.editOrderData.downPaymentOrPeriod = '';
      }
    },
    orderTypeChange(val, item) {
      console.log('采购订单类型改变：', val, item);
      if (val === 'InternalPurchase') {
        this.editOrderData.supplierShortName = process.env.VUE_APP_PARTNER_CODE;
        item.supplierCode = process.env.VUE_APP_PARTNER_ID;
        item.supplierId = process.env.VUE_APP_PARTNER_ID;
        item.supplierName = process.env.VUE_APP_PARTNER;
        item.supplierShortName = process.env.VUE_APP_PARTNER_CODE;
        for (let i = 0; i < item.poiList.length; i++) {
          const cItem = item.poiList[i];
          cItem.supplierCode = process.env.VUE_APP_PARTNER_ID;
          cItem.supplierId = process.env.VUE_APP_PARTNER_ID;
          cItem.supplierName = process.env.VUE_APP_PARTNER;
          cItem.supplierShortName = process.env.VUE_APP_PARTNER_CODE;
        }
      } else {
        this.editOrderData.supplierShortName = this.curEditItem.supplierShortName;
        item.supplierCode = this.curEditItem.supplierCode;
        item.supplierId = this.curEditItem.supplierId;
        item.supplierName = this.curEditItem.supplierName;
        item.supplierShortName = this.curEditItem.supplierShortName;
        for (let i = 0; i < item.poiList.length; i++) {
          const cItem = item.poiList[i];
          cItem.supplierCode = this.curEditItem.supplierCode;
          cItem.supplierId = this.curEditItem.supplierId;
          cItem.supplierName = this.curEditItem.supplierName;
          cItem.supplierShortName = this.curEditItem.supplierShortName;
        }
      }
      const orderTypeData = this.orderTypeList.find(x => {
        return x.index === val;
      });
      item.purchaseOrderTypeName = orderTypeData ? orderTypeData.name : '';
      // 差异单 原材料 付款方式无 不能选
      if (item.purchaseOrderTypeName && (item.purchaseOrderTypeName.includes('差异单') || item.purchaseOrderTypeName.includes('原材料(坯布纱线)'))) {
        this.isEmptyData = false;
        item.paymentMethod = 'None';
        item.paymentMethodName = '无';
      } else {
        this.isEmptyData = true;
      }
      // 原材料
      if (item.purchaseOrderTypeName && item.purchaseOrderTypeName.includes('差异单')) {
        // 差异单
        for (var i = 0; i < item.poiList.length; i++) {
          const data = item.poiList[i];
          data.offeredPrice = 0.00;
        }
        this.editFieldsList = this.originalEditFieldsList;
        this.paymentMethodList = this.paymentMethodList.filter(x => x.dictItemName !== '预付');
      } else {
        this.editFieldsList = this.standardEditFieldsList;
        this.paymentMethodList = this.paymentMethodListClone;
      }
      this.$forceUpdate();
    },
    async getTradeTermsList() {
      // 贸易条款列表
      // constDictList('TradeTerms').then(res => {
      //   console.log('贸易条款列表数据：', res);
      //   if (res.code === '0') {
      //     this.tradeTermsList = res.data;
      //   }
      // });
      let res = await constDictList('TradeTerms')
      console.log('贸易条款列表数据：', res);
      this.tradeTermsList = res.data;
    },
    currencyNameChange(val, item) {
      console.log('币种转换：', val, item, this.currencyList);
      // this.editOrderData.currencyName = this.currencyTypeHandle(val);
      const currencyData = this.currencyList.find(cItem => {
        return cItem.name == val;
      });
      item.currency = currencyData.index;
      item.priceListName = '';
      this.getPriceOrderList();
      if (item.priceListName) {
        this.priceListHandle(item);
      } else {
        for (var i = 0; i < item.poiList.length; i++) {
          const data = item.poiList[i];
          if ((item.purchaseOrderType === 'InternalPurchase') && (!isNaN(data.remark))) {
            data.offeredPrice = data.remark;
          } else {
            data.priceListPrice = 0.00;
            data.offeredPrice = 0.00;
            data.systemDiscount = '100%';
          }
        }
      }
    },
    factorySeasonChange(val, item) {
      // this.editOrderData.currencyName = this.currencyTypeHandle(val);
      // item.priceListName = '';
      this.getPriceOrderList('', '', val);
      if (item.priceListName) {
        this.priceListHandle(item);
      } else {
        for (var i = 0; i < item.poiList.length; i++) {
          const data = item.poiList[i];
          // 如果是内部往来订单 并且备注中为数字 价单/采购(价格)=备注
          if ((item.purchaseOrderType === 'InternalPurchase') && (!isNaN(data.remark))) {
            data.offeredPrice = data.remark;
          } else {
            data.priceListPrice = 0.00;
            data.offeredPrice = 0.00;
            data.systemDiscount = '100%';
          }
        }
      }
    },
    priceListNameChange(val, item) {
      if (val) {
        this.priceListHandle(item);
      } else {
        for (var i = 0; i < item.poiList.length; i++) {
          const data = item.poiList[i];
          if ((item.purchaseOrderType === 'InternalPurchase') && (!isNaN(data.remark))) {
            data.offeredPrice = data.remark;
          } else {
            data.priceListPrice = 0.00;
            data.offeredPrice = 0.00;
            data.systemDiscount = '100%';
          }
        }
      }
    },
    // periodChange(val, item) {
    //   if (!val) {
    //     this.isPeriodInput = true;
    //   }
    //   this.editOrderData.downPaymentOrPeriod = val;
    // },
    // periodInputChange(val) {
    //   this.editOrderData.downPaymentOrPeriod = val;
    // },
    downPaymentChange(val, item) {
      // 预付下拉框更改
      if (!val) {
        this.isDownPaymentInput = true;
      }
      this.editOrderData.downPaymentOrPeriod = val;
    },
    downPaymentInputChange(val) {
      // 预付输入框更改
      this.editOrderData.downPaymentOrPeriod = val;
    },
    paymentMethodChange(val) {
      // 付款方式更改
      console.log('付款方式值更改：', val);
      this.editOrderData.downPaymentOrPeriod = null;
      const paymentMethodData = this.paymentMethodList.find(item => {
        return item.dictItemValue == val;
      });
      this.editOrderData.paymentMethodName = paymentMethodData ? paymentMethodData.dictItemName : '';
      this.isDownPaymentInput = false;
      this.isPeriodInput = false;
    },
    downPaymentReselect() {
      // 重新选择预付
      this.editOrderData.downPaymentOrPeriod = null;
      this.isDownPaymentInput = false;
    },
    // periodReselect() {
    //   // 重新选择账期
    //   this.editOrderData.downPaymentOrPeriod = null;
    //   this.isPeriodInput = false;
    // },
    async priceListHandle(item, type) {
      console.log('采购价单：', item);
      // let meters = '';
      const batchPriceList = [];
      const temp = {};
      if (item.supplierShortName && item.supplierShortName == 'SSCABAL') {
        // 世佳宝
        for (let i = 0; i < item.poiList.length; i++) {
          const poItem = item.poiList[i];
          const params = {};
          params.materialCode = poItem.materialCode;
          params.priceListName = item.priceListName;
          params.meter = poItem.offeredQuantity;
          batchPriceList.push(params);
        }
      } else {
        // for (let i in item.poiList) {
        for (let i = 0; i < item.poiList.length; i++) {
          const key = item.poiList[i].materialCode;
          if (temp[key]) {
            temp[key].materialCode = temp[key].materialCode;
            temp[key].priceListName = temp[key].priceListName;
            temp[key].meter = temp[key].meter + item.poiList[i].offeredQuantity;
          } else {
            temp[key] = {};
            temp[key].materialCode = item.poiList[i].materialCode;
            temp[key].priceListName = item.priceListName;
            temp[key].meter = item.poiList[i].offeredQuantity;
          }
        }
        for (const k in temp) {
          batchPriceList.push(temp[k]);
        }
      }
      batchPriceByMaterialCode(batchPriceList).then(res => {
        console.log('采购价单批量获取价单价格：', res);
        if (res.code === '0') {
          const defaultCount = 0;
          res.data.map(x => {
            item.poiList.map(p => {
              if (item.supplierShortName == 'SSCABAL') {
                // 世佳宝
                if (p.materialCode === x.materialCode && p.offeredQuantity == x.meter) {
                  p.priceListPrice = x.price ? (parseFloat(x.price)).toFixed(2) : defaultCount.toFixed(2);
                  p.offeredPrice = (type == 'change' && p.offeredPrice) ? p.offeredPrice : (x.price ? (parseFloat(x.price)).toFixed(2) : defaultCount.toFixed(2));
                  if (!parseFloat(p.priceListPrice) || !parseFloat(p.offeredPrice)) {
                    p.systemDiscount = '100%';
                  } else {
                    p.systemDiscount = parseFloat((parseFloat(p.offeredPrice) / parseFloat(p.priceListPrice) * 100).toFixed(2)) + '%';
                  }
                  this.$forceUpdate();
                }
              } else {
                if (p.materialCode === x.materialCode) {
                  p.priceListPrice = x.price ? (parseFloat(x.price)).toFixed(2) : defaultCount.toFixed(2);
                  p.offeredPrice = (type == 'change' && p.offeredPrice) ? p.offeredPrice : (x.price ? (parseFloat(x.price)).toFixed(2) : defaultCount.toFixed(2));
                  if (!parseFloat(p.priceListPrice) || !parseFloat(p.offeredPrice)) {
                    p.systemDiscount = '100%';
                  } else {
                    p.systemDiscount = parseFloat((parseFloat(p.offeredPrice) / parseFloat(p.priceListPrice) * 100).toFixed(2)) + '%';
                  }
                  this.$forceUpdate();
                }
              }
            });
          });
        }
      });
    },
    deleteHandle(index, item) {
      // 删除订单
      this.dialogVisible = true;
      this.dialogType = 'delete';
      this.dialogDesc = '确定删除？';
      this.deleteId = item.id;
    },
    editDeleteHandle(index, item) {
      console.log(index, item);
      this.dialogVisible = true;
      this.dialogType = 'deleteItem';
      this.dialogDesc = '确定删除？';
      this.deleteItemId = item.id;
    },
    editSelectionChange(val) {
      console.log('选择编辑项：', val);
      this.selectOrderData = val;
    },
    mergeOrderHandle() {
      // 合并订单行
      if (this.editOrderData.prTypeName == '采购申请差异单') {
        this.$message({
          type: 'error',
          message: '采购订单差异单不能合并订单行！'
        });
        return false;
      }
      if (this.selectOrderData.length != 0) {
        if (this.selectOrderData.length < 2) {
          this.$message({
            type: 'error',
            message: '单行不能合并!'
          });
          return false;
        }
        let tag = '';
        const cloneMergeSelectOrder = [];
        const remarkItem = [];
        const specialRequireItem = [];
        let saleOrderItem = [];
        const priUuidListItem = [];
        var data = [];
        const cloneSelectOrder = JSON.parse(JSON.stringify(this.selectOrderData));
        const otherNoSelectOrder = this.filterOrderData(this.selectOrderData, this.editOrderData.poiList);
        cloneSelectOrder.map((el) => {
          if (el.purchaseMeasurementUnit == '米') {
            if (el.offeredPackages != 1) {
              tag = 2;
            }
          }
          cloneMergeSelectOrder.findIndex(ol => {
            if (el.materialCode != ol.materialCode) {
              tag = 1;
            }
            if (el.offeredDate != ol.offeredDate) {
              tag = 3;
            }
            if (el.requiredDate != ol.requiredDate) {
              tag = 4;
            }
          });
          if (el.remark) {
            remarkItem.push(el.remark);
          }
          if (el.specialRequire) {
            specialRequireItem.push(el.specialRequire);
          }
          if (el.saleOrderId) {
            saleOrderItem.push(el.saleOrderId);
          }
          priUuidListItem.push(el.priUuidList);
          el.offeredPrice = 0;
          el.offeredPackages = 1;
          getUUID().then(res => {
            if (res.code === '0') {
              el.uuid = res.data;
            }
          });
          cloneMergeSelectOrder.push(el);
        });
        if (tag == 1) {
          this.$message({
            type: 'error',
            message: '同一个物料编号才能合并!'
          });
          this.$refs.clearChild.parentHandleclick('取消');
          this.selectOrderData = [];
          return false;
        }
        if (tag == 2) {
          this.$message({
            type: 'error',
            message: '采购数量包数为1才能合并!'
          });
          this.$refs.clearChild.parentHandleclick('取消');
          this.selectOrderData = [];
          return false;
        }
        if (tag == 3) {
          this.$message({
            type: 'error',
            message: '同一个工厂交期才能合并!'
          });
          this.$refs.clearChild.parentHandleclick('取消');
          this.selectOrderData = [];
          return false;
        }
        if (tag == 4) {
          this.$message({
            type: 'error',
            message: '同一个期望交期才能合并!'
          });
          this.$refs.clearChild.parentHandleclick('取消');
          this.selectOrderData = [];
          return false;
        }
        priUuidListItem.map(function(value, index, array) {
          data = data.concat(value);
        });
        saleOrderItem = [...new Set(saleOrderItem)];
        const arrayDuplication = this.mergeList(cloneMergeSelectOrder, data, remarkItem, specialRequireItem, saleOrderItem);
        if (otherNoSelectOrder.length != 0) {
          this.editOrderData.poiList = otherNoSelectOrder.concat(arrayDuplication);
        } else {
          this.editOrderData.poiList = arrayDuplication;
        }
        const getPriceList = JSON.parse(JSON.stringify(this.editOrderData));
        getPriceList.poiList = arrayDuplication;// 只改变合并的采购价格
        this.$forceUpdate();
        if (this.editOrderData.priceListName) {
          // this.priceListNameChange(this.editOrderData.priceListName,getPriceList)
          // this.priceListNameChange(this.editOrderData.priceListName,this.editOrderData)
          this.mergePiceHandle(getPriceList);// 获取价单价格
        }
        this.realTimeComputingHandle('totalAmount');
        this.$nextTick(() => {
          this.$refs.clearChild.targetingLastPageHandle();
        });
      } else {
        this.$message({
          type: 'error',
          message: '请选择合并的订单行!'
        });
      }
    },
    mergePiceHandle(item) {
      const batchPriceList = [];
      const temp = {};
      for (let i = 0; i < item.poiList.length; i++) {
        console.log('item.poiList[i]', item.poiList[i]);
        const key = item.poiList[i].materialCode;
        if (temp[key]) {
          temp[key].materialCode = temp[key].materialCode;
          temp[key].priceListName = temp[key].priceListName;
          temp[key].meter = temp[key].meter + item.poiList[i].offeredQuantity;
        } else {
          temp[key] = {};
          temp[key].materialCode = item.poiList[i].materialCode;
          temp[key].priceListName = item.priceListName;
          temp[key].meter = item.poiList[i].offeredQuantity;
        }
      }
      for (const k in temp) {
        batchPriceList.push(temp[k]);
      }
      batchPriceByMaterialCode(batchPriceList).then(res => {
        // console.log('采购价单批量获取价单价格：', res);
        if (res.code === '0') {
          const defaultCount = 0;
          res.data.map(x => {
            item.poiList.map((p, i) => {
              if (p.materialCode === x.materialCode) {
                p.priceListPrice = x.price ? (parseFloat(x.price)).toFixed(2) : defaultCount.toFixed(2);
                p.offeredPrice = x.price ? (parseFloat(x.price)).toFixed(2) : defaultCount.toFixed(2);
                if (!parseFloat(p.priceListPrice) || !parseFloat(p.offeredPrice)) {
                  p.systemDiscount = '100%';
                } else {
                  p.systemDiscount = parseFloat((parseFloat(p.offeredPrice) / parseFloat(p.priceListPrice) * 100).toFixed(2)) + '%';
                }
                this.$forceUpdate();
              }
            });
          });
        }
      });
    },
    mergeList(val, data, remarkItem, specialRequireItem, saleOrderItem) {
      console.log('val,data,remarkItem,specialRequireItem,saleOrderItem', val, data, remarkItem, specialRequireItem, saleOrderItem);
      // 合并订单后条件（米/包数相加，备注及特殊要求拼接，priUuidList数组合并，采购价格为0，合并后更新一次价单价格接口）ps:采购申请数量与采购订单数量统一
      for (var i = 0, len = val.length; i < len; i++) {
        for (var j = i + 1, len = val.length; j < len; j++) {
          if (val[i].purchaseMeasurementUnit != '米') {
            val[i].offeredQuantity = Number(val[j].offeredQuantity) + Number(val[i].offeredQuantity);
          } else {
            val[i].offeredQuantityPerPackage = Number(val[j].offeredQuantityPerPackage) + Number(val[i].offeredQuantityPerPackage);
            val[i].requiredQuantityPerPackage = Number(val[j].requiredQuantity) + Number(val[i].requiredQuantity);
            val[i].offeredQuantity = Number(val[i].offeredPackages) * Number(val[i].offeredQuantityPerPackage);
          }
          val[i].requiredQuantity = Number(val[j].requiredQuantity) + Number(val[i].requiredQuantity);
          val[i].remark = remarkItem.toString();
          val[i].specialRequire = specialRequireItem.toString();
          val[i].saleOrderId = saleOrderItem.toString();
          val[i].quantity = val[i].offeredQuantity;
          val[i].purchaseOrderSn = '';// 合并过的行号为空,下次编辑由后端带出重新更新的行号
          val[i].priUuidList = data;
          val.splice(j, 1);
          j--;
          len--;
          this.$forceUpdate();
        }
      }
      return val;
    },
    filterOrderData(selectval, allorderval) {
      // 过滤合并外的数组(去除相同项)
      const data = allorderval.filter(item => !selectval.some(ele => ele.id === item.id));
      return data;
    },
    async editHandle(index, item) {
      // 编辑订单
      // console.log('编辑订单详情：', item);
      const checkBoolean = [];
      item.poiList.forEach(i => {
        i.offeredPrice = i.offeredPrice === null ? 0.00 : i.offeredPrice;
        i.singleTotalOfferedPrice = parseFloat(i.offeredPrice) * i.offeredQuantity;
        if (i.cancelStatus) {
          if (item.poiList.findIndex(target => target.cancelStatus === true) != -1) {
            checkBoolean.push(i.cancelStatus);
          }
        }
      });
      // 计算总数量
      item.totalQuantity = item.poiList.reduce((pre, cur) => {
        return pre + cur.offeredQuantity;
      }, 0);
      // 计算总金额
      item.totalAmount = item.poiList.reduce((pre, cur) => {
        console.log('pre,cur', pre, cur);
        return parseFloat(pre) + parseFloat(cur.singleTotalOfferedPrice);
      }, 0);
      item.totalQuantity = item.totalQuantity.toFixed(2);
      item.totalAmount = item.totalAmount.toFixed(2);
      if (checkBoolean.length == item.poiList.length) {
        this.$message({
          type: 'error',
          message: '采购订单子项都为撤销状态下不能编辑！'
        });
        return false;
      }
      this.curEditItem = JSON.parse(JSON.stringify(item));
      // 内部往来订单
      if (item.purchaseOrderType === 'InternalPurchase') {
        this.editOrderData.supplierShortName = process.env.VUE_APP_PARTNER_CODE;
        item.supplierCode = process.env.VUE_APP_PARTNER_ID;
        item.supplierId = process.env.VUE_APP_PARTNER_ID;
        item.supplierName = process.env.VUE_APP_PARTNER;
        item.supplierShortName = process.env.VUE_APP_PARTNER_CODE;
        item.tradeTerms = 'CIF';
        for (let t = 0; t < item.poiList.length; t++) {
          const cItem = item.poiList[t];
          cItem.supplierCode = process.env.VUE_APP_PARTNER_ID;
          cItem.supplierId = process.env.VUE_APP_PARTNER_ID;
          cItem.supplierName = process.env.VUE_APP_PARTNER;
          cItem.supplierShortName = process.env.VUE_APP_PARTNER_CODE;
          if (!isNaN(cItem.remark)) {
            cItem.offeredPrice = cItem.remark;
          }
        }
      }
      for (let j = item.poiList.length - 1; j >= 0; j--) {
        // 编辑时过滤撤销状态的数据
        if (item.poiList[j].cancelStatus == true) {
          item.poiList.splice(j, 1);
        }
      }
      if (!item.currency || !item.currencyName) {
        const currencyData = this.currencyList.find(x => {
          return x.name === '欧元';
        });
        item.currency = currencyData ? currencyData.index : '';
        item.currencyName = currencyData ? currencyData.name : '';
      }

      // 点击编辑的时候给 如果paymentMethod有值 将他中英转化
      // if(this.editOrderData.paymentMethod!='' && this.editOrderData.paymentMethod!=null){
      //   this.editOrderData.paymentMethod=='downPayment'?this.editOrderData.paymentMethod='预付':this.editOrderData.paymentMethod='账期'
      // }
      this.getPriceOrderList(item.supplierCode ? item.supplierCode : '', item.currency ? item.currency : '', item.factorySeason ? item.factorySeason : '');
      this.isShowEdit = true;
      // this.getSearchOrderList(item.id);
      if (item.priceListName && item.currencyName) {
        // this.priceListHandle(item);
        // let meters = '';

        this.priceListHandle(item);

        // let temp = {};
        // for (let i in item.poiList) {
        //   let key = item.poiList[i].materialCode;
        //   if (temp[key]) {
        //     temp[key].materialCode = temp[key].materialCode;
        //     temp[key].meter = temp[key].meter + item.poiList[i].offeredQuantity;
        //   } else {
        //     temp[key] = {};
        //     temp[key].materialCode = item.poiList[i].materialCode;
        //     temp[key].meter = item.poiList[i].offeredQuantity;
        //   }
        // }
        // for (var i = 0; i < item.poiList.length; i++) {
        //   let data = item.poiList[i];
        //   // meters = parseFloat(data.offeredPackages) * parseFloat(data.offeredQuantityPerPackage) ? parseFloat(data.offeredPackages) * parseFloat(data.offeredQuantityPerPackage) : 0;
        //   await priceByMaterialCode(item.priceListName, data.materialCode, temp[data.materialCode].meter).then(res => {
        //     console.log('采购价单带出价单价格：', res);
        //     if (res.code === '0') {
        //       let defaultCount = 0;
        //       data.priceListPrice = res.data ? (parseFloat(res.data)).toFixed(2) : defaultCount.toFixed(2);
        //       // data.offeredPrice = res.data ? res.data : 0;
        //       if (!parseFloat(data.priceListPrice) || !parseFloat(data.offeredPrice)) {
        //         data.systemDiscount = '100%';
        //       } else {
        //         data.systemDiscount = parseFloat((parseFloat(data.offeredPrice) / parseFloat(data.priceListPrice) * 100).toFixed(2)) + '%';
        //       }
        //     }
        //   });
        // }
      } else {
        const defaultCount = 0;
        for (var d = 0; d < item.poiList.length; d++) {
          const data = item.poiList[d];
          data.priceListPrice = defaultCount.toFixed(2);
          // data.offeredPrice = 0;
          data.systemDiscount = '100%';
        }
      }
      // 采购订单类型为差异单 原材料 付款方式无 不能选
      if (item.purchaseOrderTypeName && (item.purchaseOrderTypeName.includes('差异单') || item.purchaseOrderTypeName.includes('原材料(坯布纱线)'))) {
        this.isEmptyData = false;
        item.paymentMethod = 'None';
        item.paymentMethodName = '无';
      } else {
        this.isEmptyData = true;
      }
      if (item.prTypeName && item.prTypeName.includes('差异单')) {
        // 差异单
        this.editFieldsList = this.originalEditFieldsList;
        this.$forceUpdate();
      } else {
        this.editFieldsList = this.standardEditFieldsList;
        this.$forceUpdate();
      }
      // 差异单时允许采购数量为负数
      if (item.prTypeName && item.prTypeName.includes('差异单')) {
        for (var r = 0; r < item.poiList.length; r++) {
          const data = item.poiList[r];
          if (!data.offeredPrice) {
            data.offeredPrice = 0.00;
          }
          if (!data.offeredPackages) {
            if (data.offeredPackages < 0) {
              data.offeredPackages = -(data.offeredPackages);
            }
          }
          // data.editModeSet.unitSet.offeredPackages = {
          //   min: '-9999999999'
          // };
          data.editModeSet.unitSet.offeredQuantityPerPackage = {
            disabled: true
          };
          if (data.purchaseMeasurementUnit == '米') {
            data.offeredQuantity = data.offeredPackages * data.offeredQuantityPerPackage;
          } else {
            data.offeredQuantity = data.offeredQuantity;
          }

          this.$forceUpdate();
        }
      }
      this.editOrderData = JSON.parse(JSON.stringify(item));
    },
    submitHandle(index, item) {
      console.log('列表提交数据：', item);
      const checkBoolean = [];
      item.poiList.forEach(i => {
        if (i.cancelStatus) {
          if (item.poiList.findIndex(target => target.cancelStatus === true) != -1) {
            checkBoolean.push(i.cancelStatus);
          }
        }
      });
      if (checkBoolean.length == item.poiList.length) {
        this.$message({
          type: 'error',
          message: '采购订单子项都为撤销状态下不能提交审批！'
        });
        return false;
      }
      this.modifyOrderList = item;
      this.editOrderData = item; // 提交审批数据
      this.submitParamsData = {
        uuid: item.uuid,
        orderStatus: 2,
        submitType: '',
        applicant: this.$store.state.userName,
        applicantName: this.$store.state.realName,
        applicantDepartment: this.$store.state.currentDepartment.id,
        applicantDepartmentName: this.$store.state.currentDepartment.name
      };
      if (item.orderStatus == 1) {
        this.submitParamsData.submitType = 2;
      }
      if (item.orderStatus == 5) {
        this.submitParamsData.submitType = 4;
      }
      this.dialogVisible = true;
      this.dialogType = 'submit';
      this.dialogDesc = '确定提交审批？';
    },
    deleteEditItemHandle(index, item) {
      const editOrderDataCopy = JSON.parse(JSON.stringify(this.editOrderData));
      editOrderDataCopy.poiList.splice(index, 1);
      this.editOrderData = editOrderDataCopy;
    },
    cancelEditHandle() {
      this.isShowEdit = false;
      this.selectOrderData = [];
      this.getPurchaseOrderList();
    },
    dateValChange(val) {
      // 创建时间变化
      // this.beginTime = val[0];
      // this.endTime = val[1];
      this.searchHandle();
    },
    saveEditHandle() {
      // 编辑保存
      const self = this;
      // this.editOrderData.orderStatus=this.oldOrderStatus;
      // const reg = /^[\d|\.]*$/;
      const editList = this.editOrderData.poiList;
      self.editOrderData.submitType = '';
      if (!this.editOrderData.purchaseOrderType) {
        this.$message({
          type: 'error',
          message: '请选择采购订单类型！'
        });
        return false;
      }
      if (!this.editOrderData.purchaseOrderId) {
        this.$message({
          type: 'error',
          message: '请输入采购订单编号！'
        });
        return false;
      }
      if (!this.editOrderData.tradeTerms) {
        this.$message({
          type: 'error',
          message: '请选择贸易条款！'
        });
        return false;
      }
      if (!this.editOrderData.currencyName) {
        this.$message({
          type: 'error',
          message: '请选择币种！'
        });
        return false;
      }
      if (!this.editOrderData.paymentMethod) {
        this.$message({
          type: 'error',
          message: '请选择付款方式！'
        });
        return false;
      }
      if (this.editOrderData.paymentMethod == 'downPayment' && !this.editOrderData.downPaymentOrPeriod) {
        this.$message({
          type: 'error',
          message: '请选择' + this.editOrderData.paymentMethodName + '！'
        });
        return false;
      }

      // if (reg.test(this.editOrderData.downPaymentOrPeriod)) {
      //   // 验证账期/预付格式
      //   self.editOrderData.paymentMethod=='预付'?self.editOrderData.downPaymentOrPeriod=self.editOrderData.downPaymentOrPeriod+"%":self.editOrderData.downPaymentOrPeriod+"天"
      // }
      // //self.editOrderData.paymentMethod=='预付'?self.editOrderData.paymentMethod="downPayment":self.editOrderData.paymentMethod="period"
      // var changeeditOrderData={...self.editOrderData}
      // changeeditOrderData.paymentMethod=='预付'?changeeditOrderData.paymentMethod="downPayment":changeeditOrderData.paymentMethod="period"

      for (var i = 0; i < editList.length; i++) {
        if (isNaN(parseInt(editList[i].offeredPrice))) {
          this.$message({
            type: 'error',
            message: '请输入采购价格！'
          });
          return false;
        }
        if (parseInt(editList[i].offeredPrice) < 0) {
          this.$message({
            type: 'error',
            message: '采购价格不能小于0！'
          });
          return false;
        }
        if (!verificationQuantity(this, editList[i].offeredPrice, 6, 2, '采购价格')) {
          return false;
        }
        // if (!this.verificationHandle(editList[i].offeredPrice, '采购价格')) {
        //   editList[i].offeredPrice = 0;
        //   return false;
        // }
        if (editList[i].purchaseMeasurementUnit == '米') {
          if (!editList[i].offeredPackages || !editList[i].offeredQuantityPerPackage) {
            this.$message({ type: 'error', message: '请输入采购数量！' }); return false;
          }
        } else {
          if (!editList[i].offeredQuantity) {
            this.$message({ type: 'error', message: '请输入采购数量！' }); return false;
          }
        }
        if (editList[i].purchaseMeasurementUnit == '米') {
          editList[i].offeredQuantity = parseFloat(editList[i].offeredPackages) * parseFloat(editList[i].offeredQuantityPerPackage);
        }
        if (editList[i].offeredQuantity.toString().indexOf('.') !== -1) {
          const arr = editList[i].offeredQuantity.toString().split('.');
          if (arr[0].length > 8) {
            this.$message({
              message: '采购数量米数仅支持整数位8位，请重新输入！',
              type: 'error'
            });
            return false;
          }
          if (arr[1].length > 2) {
            this.$message({
              message: '采购数量米数仅支持小数位2位，请重新输入！',
              type: 'error'
            });
            return false;
          }
        } else if (editList[i].offeredQuantity.toString().length > 8) {
          this.$message({
            message: '采购数量米数仅支持整数位8位，请重新输入！',
            type: 'error'
          });
          return false;
        }
        if (!editList[i].offeredDate) {
          this.$message({
            type: 'error',
            message: '请输入工厂交期！'
          });
          return false;
        }
        if (editList[i].offeredDate && !(editList[i].offeredDate).toString().includes('-')) {
          editList[i].offeredDate = timeFormatGMT(editList[i].offeredDate);
        }
        editList[i].priceListPrice = editList[i].priceListPrice ? editList[i].priceListPrice : 0;
      }
      if (this.editOrderData.orderStatus == 1) {
        self.editOrderData.submitType = 1;
      }
      if (this.editOrderData.orderStatus == 5) {
        self.editOrderData.submitType = 3;
      }
      self.editOrderData.applicant = this.$store.state.userName;
      self.editOrderData.applicantName = this.$store.state.realName;
      self.editOrderData.applicantDepartment = this.$store.state.currentDepartment.id;
      self.editOrderData.applicantDepartmentName = this.$store.state.currentDepartment.name;
      this.isSaveDisabled = true;
      // this.editPartLoading = true;
      modifyPurchaseOrder(this.editOrderData).then(res => {
        // console.log('修改保存订单：', res);
        if (res.code === '0') {
          self.getPurchaseOrderList();
          self.$message({
            type: 'success',
            message: '保存成功！'
          });
          this.isShowEdit = false;
          this.isDownPaymentInput = false;
          this.isPeriodInput = false;
        }
        this.isSaveDisabled = false;
        // this.editPartLoading = false;
      });
    },
    submitEditHandle(type) {
      const self = this;
      const editList = this.editOrderData.poiList;
      const status = JSON.parse(JSON.stringify(this.editOrderData.orderStatus));
      console.log('提交审批订单状态：', status);
      // self.editOrderData.submitType = '';
      // this.editOrderData.orderStatus=this.oldOrderStatus;
      if (!this.editOrderData.purchaseOrderType) {
        this.$message({
          type: 'error',
          message: '请选择采购订单类型！'
        });
        return false;
      }
      if (!this.editOrderData.purchaseOrderId) {
        this.$message({
          type: 'error',
          message: '请输入采购订单编号！'
        });
        return false;
      }
      if (!this.editOrderData.tradeTerms) {
        this.$message({
          type: 'error',
          message: '请选择贸易条款！'
        });
        return false;
      }
      if (!this.editOrderData.currencyName) {
        this.$message({
          type: 'error',
          message: '请选择币种！'
        });
        return false;
      }
      if (!this.editOrderData.paymentMethod) {
        this.$message({
          type: 'error',
          message: '请选择付款方式！'
        });
        return false;
      }
      if (this.editOrderData.paymentMethod == 'downPayment' && !this.editOrderData.downPaymentOrPeriod) {
        this.$message({
          type: 'error',
          message: '请选择' + this.editOrderData.paymentMethodName + '！'
        });
        return false;
      }
      for (var i = 0; i < editList.length; i++) {
        if (isNaN(parseInt(editList[i].offeredPrice))) {
          this.$message({
            type: 'error',
            message: '请输入采购价格！'
          });
          return false;
        }
        if (parseInt(editList[i].offeredPrice) < 0) {
          this.$message({
            type: 'error',
            message: '采购价格不能小于0！'
          });
          return false;
        }
        if (!verificationQuantity(this, editList[i].offeredPrice, 6, 2, '采购价格')) {
          return false;
        }
        // if (!this.verificationHandle(editList[i].offeredPrice, '采购价格')) {
        //   editList[i].offeredPrice = 0;
        //   return false;
        // }
        if (editList[i].purchaseMeasurementUnit == '米') {
          if (!editList[i].offeredPackages || !editList[i].offeredQuantityPerPackage) {
            this.$message({ type: 'error', message: '请输入采购数量！' }); return false;
          }
        } else {
          if (!editList[i].offeredQuantity) {
            this.$message({ type: 'error', message: '请输入采购数量！' }); return false;
          }
        }
        if (editList[i].purchaseMeasurementUnit == '米') {
          editList[i].offeredQuantity = parseFloat(editList[i].offeredPackages) * parseFloat(editList[i].offeredQuantityPerPackage);
        }
        if (editList[i].offeredQuantity.toString().indexOf('.') !== -1) {
          const arr = editList[i].offeredQuantity.toString().split('.');
          if (arr[0].length > 8) {
            this.$message({
              message: '采购数量米数仅支持整数位8位，请重新输入！',
              type: 'error'
            });
            return false;
          }
          if (arr[1].length > 2) {
            this.$message({
              message: '采购数量米数仅支持小数位2位，请重新输入！',
              type: 'error'
            });
            return false;
          }
        } else if (editList[i].offeredQuantity.toString().length > 8) {
          this.$message({
            message: '采购数量米数仅支持整数位8位，请重新输入！',
            type: 'error'
          });
          return false;
        }
        if (!editList[i].offeredDate) {
          this.$message({
            type: 'error',
            message: '请输入工厂交期！'
          });
          return false;
        }
        if (editList[i].offeredDate && !(editList[i].offeredDate).toString().includes('-')) {
          editList[i].offeredDate = timeFormatGMT(editList[i].offeredDate);
        }
        editList[i].priceListPrice = editList[i].priceListPrice ? editList[i].priceListPrice : 0;
      }
      if (this.editOrderData.orderStatus == 1) {
        self.editOrderData.submitType = 2;
      }
      if (this.editOrderData.orderStatus == 5) {
        self.editOrderData.submitType = 4;
        self.editOrderData.orderStatus = 2;
      }
      self.editOrderData.applicant = this.$store.state.userName;
      self.editOrderData.applicantName = this.$store.state.realName;
      self.editOrderData.applicantDepartment = this.$store.state.currentDepartment.id;
      self.editOrderData.applicantDepartmentName = this.$store.state.currentDepartment.name;
      this.isSubmitDisabled = true;
      // this.editPartLoading = true;
      // var reg = /^[\d|\.]*$/;
      // if(reg.test(self.editOrderData.downPaymentOrPeriod)){
      //   self.editOrderData.paymentMethod=='预付'?self.editOrderData.downPaymentOrPeriod=self.editOrderData.downPaymentOrPeriod+"%":self.editOrderData.downPaymentOrPeriod+"天"
      // }
      // //self.editOrderData.paymentMethod=='预付'?self.editOrderData.paymentMethod="downPayment":self.editOrderData.paymentMethod="period"
      // var changeeditOrderData={...self.editOrderData}
      // changeeditOrderData.paymentMethod=='预付'?changeeditOrderData.paymentMethod="downPayment":changeeditOrderData.paymentMethod="period"
      modifyPurchaseOrder(this.editOrderData).then(res => {
        // console.log('修改保存订单：', res);
        if (res.code === '0') {
          this.orderStatus = 2;
          this.currentPage = 1;
          self.getPurchaseOrderList();
          this.activeName = 'Approving';
          self.$message({
            type: 'success',
            message: '提交审批成功！'
          });
          this.isShowEdit = false;
          this.isDownPaymentInput = false;
          this.isPeriodInput = false;
          if (type === 'line') {
            this.dialogVisible = false;
          }
        } else {
          this.editOrderData.orderStatus = status;
        }
        this.isSubmitDisabled = false;
        // this.editPartLoading = false;
        this.showMethod = '';
      });
    },
    actionsTypeHandle(orderStatus) {
      switch (orderStatus) {
        case 1:
          this.actionName = '草稿';
          break;
        case 2:
          this.actionName = '审批中';
          break;
        case 3:
          this.actionName = '已通过';
          break;
        case 4:
          this.actionName = '工厂确认';
          break;
        case 5:
          this.actionName = '已驳回';
          break;
        case 6:
          this.actionName = '已终止';
          break;
        case 7:
          this.actionName = '已关闭';
          break;
        default:
          break;
      }
      return this.actionName;
    },
    searchHandle() {
      // 搜索
      this.currentPage = 1;
      if (this.dateVal) {
        this.beginTime = this.dateVal[0];
        this.endTime = this.dateVal[1];
      } else {
        this.beginTime = '';
        this.endTime = '';
      }
      this.getPurchaseOrderList();
    },
    uploadHandle() {
      // 上传
    },
    downloadHandle() {
      // 下载
    },
    showChildList(index, item) {
      item.isShowList = !item.isShowList;
      this.$forceUpdate();
    },
    switchTabHandle(tab) {
      // tab切换
      console.log('tab切换：', tab);
      // this.tableLoading = true;
      this.currentPage = 1;
      this.orderList = [];
      switch (tab.name) {
        case 'All':
          // 全部
          this.orderStatus = '';
          break;
        case 'Draft':
          // 草稿
          this.orderStatus = 1;
          break;
        case 'Approving':
          // 审批中
          this.orderStatus = 2;
          break;
        case 'Approved':
          // 已通过/已审批
          this.orderStatus = 3;
          break;
        case 'Rejected':
          // 已驳回
          this.orderStatus = 5;
          break;
        case 'Terminated':
          // 已终止
          this.orderStatus = 6;
          break;
        default:
          this.orderStatus = '';
          break;
      }
      this.getPurchaseOrderList();
    },
    async checkHandle(index, item) {
      // 查看订单
      this.editOrderData = item;
      this.popupTitle = '查看订单信息';
      this.dialogFormVisible = true;
    },
    revokeHandle(index, item) {
      // 列表撤销（已通过）
      this.revokeDialogVisible = true;
      this.revokeUuid = item.uuid;
    },
    revokeCancel() {
      this.revokeDialogVisible = false;
      this.cancelRemark = '';
    },
    revokeDeleteHandle() {
      // 列表撤销确认
      this.isDisabled = true;
      revokePurchaseOrderItem(this.revokeUuid, this.cancelRemark).then(res => {
        // console.log('撤销项：', res);
        if (res.code === '0') {
          this.$message({
            type: 'success',
            message: '撤销成功！'
          });
          this.getPurchaseOrderList();
        }
        this.isDisabled = false;
        this.cancelRemark = '';
        this.revokeDialogVisible = false;
      });
    },
    dialogCancelHandle() {
      this.dialogVisible = false;
    },
    closeHandle(index, item) {
      // 关闭采购订单
      this.dialogVisible = true;
      this.dialogType = 'closed';
      this.closedId = item.purchaseOrderId;
      // this.revokeCode = item.factoryAllocationCode;
      this.dialogDesc = '确定关闭？';
    },
    dialogConfirmHandle() {
      // 对话框确认
      switch (this.dialogType) {
        case 'closed':
          closeTransferOrder(this.closedId).then(res => {
            // console.log('列表撤销确认：', res);
            if (res.code === '0') {
              this.dialogVisible = false;
              this.$message({
                type: 'success',
                message: '关闭成功！'
              });
              this.getPurchaseOrderList();
            }
            this.dialogVisible = false;
          });
          break;
        case 'delete':
          deletePurchaseOrder(this.deleteId).then(res => {
            if (res.code === '0') {
              this.getPurchaseOrderList();
              this.$message({
                type: 'success',
                message: '删除成功!'
              });
            }
            this.dialogVisible = false;
          });
          break;
        case 'deleteItem':
          deletePurchaseOrderItem(this.deleteItemId).then(res => {
            if (res.code === '0') {
              this.getPurchaseOrderList();
              this.$message({
                type: 'success',
                message: '删除成功!'
              });
            }
            this.dialogVisible = false;
          });
          break;
        case 'submit':
          this.submitEditHandle('line');
          break;

        default:
          break;
      }
    },
    // priceResetHandle(val, item) {
    //   let editListCopy = JSON.parse(JSON.stringify(this.editOrderData.poiList));
    //   let temp = {};
    //   for (let i in editListCopy) {
    //     let key = editListCopy[i].materialCode;
    //     if (temp[key]) {
    //       temp[key].materialCode = temp[key].materialCode;
    //       temp[key].meter = temp[key].meter + editListCopy[i].offeredQuantity;
    //     } else {
    //       temp[key] = {};
    //       temp[key].materialCode = editListCopy[i].materialCode;
    //       temp[key].meter = editListCopy[i].offeredQuantity;
    //     }
    //   }

    //   for (var i = 0; i < editListCopy.length; i++) {
    //     let data = editListCopy[i];
    //     if (data.uuid == item.uuid) {
    //       if (val && val.toString().length > 9) {
    //         this.$message({
    //           message: '超长字符，请重新输入！',
    //           type: 'error'
    //         });
    //         data.offeredPackages = '0';
    //       }
    //       data.offeredQuantity = parseFloat(data.offeredPackages) * parseFloat(data.offeredQuantityPerPackage) ? parseFloat(data.offeredPackages) * parseFloat(data.offeredQuantityPerPackage) : 0;
    //       // let meters = parseFloat(data.offeredPackages) * parseFloat(data.offeredQuantityPerPackage) ? parseFloat(data.offeredPackages) * parseFloat(data.offeredQuantityPerPackage) : 0;
    //       if (this.editOrderData.priceListName) {
    //         priceByMaterialCode(this.editOrderData.priceListName, data.materialCode, temp[data.materialCode].meter).then(res => {
    //           console.log('根据采购数量带出价单价格：', res);
    //           if (res.code === '0') {
    //             let defaultCount = 0;
    //             data.priceListPrice = res.data ? (parseFloat(res.data)).toFixed(2) : defaultCount.toFixed(2);
    //             data.offeredPrice = res.data ? (parseFloat(res.data)).toFixed(2) : defaultCount.toFixed(2);
    //             if (!parseFloat(data.priceListPrice) || !parseFloat(data.offeredPrice)) {
    //               data.systemDiscount = '100%';
    //             } else {
    //               data.systemDiscount = parseFloat((parseFloat(data.offeredPrice) / parseFloat(data.priceListPrice) * 100).toFixed(2)) + '%';
    //             }
    //           }
    //         });
    //       }
    //     }
    //   }
    //   this.editOrderData.poiList = editListCopy;
    //   this.$forceUpdate();
    // },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getPurchaseOrderList();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getPurchaseOrderList();
    },
    getPurchaseOrderList() {
      // 采购订单列表
      this.tableLoading = true;
      const data = {
        beginTime: this.beginTime,
        endTime: this.endTime,
        customerId: this.customerId,
        orderStatus: this.orderStatus,
        poIdOrPrCode: this.poIdOrPrCode,
        purchaseOrderId: this.purchaseOrderId,
        materialCode: this.materialCode,
        uuid: this.uuid,
        sort: 'desc',
        purchaseOrderType: this.purchaseOrderType,
        supplierCode: this.supplierCode,
        applicantName:this.applicantName,
        saleOrderId:this.saleOrderId
      };
      purchaseOrderList(this.currentPage, this.pageSize, data).then(res => {
        console.log('采购订单列表：', res);
        if (res.code === '0') {
          this.orderList = res.data.records;
          this.total = res.data.total;
          for (let i = 0; i < this.orderList.length; i++) {
            const item = this.orderList[i];
            this.orderTypeList.forEach(val => {
              if (item.purchaseOrderType == val.index) {
                item.purchaseOrderTypeName = val.name;
              }
            });
            item.createTime = item.createTime ? timeFormat(item.createTime) : '';
            item.orderStatusName = this.actionsTypeHandle(item.orderStatus);
            // 预付值转换
            const downPaymentData = this.downPaymentList.find(dpItem => {
              return dpItem.dictItemValue == item.downPaymentOrPeriod;
            });
            item.downPaymentOrPeriodName = downPaymentData ? downPaymentData.dictItemName : '';

            // 付款方式转化
            switch (item.paymentMethod) {
              case 'downPayment':
                item.paymentMethodNames = '预付';
                break;
              case 'period':
                item.paymentMethodNames = '账期';
                break;
              case 'None':
                item.paymentMethodNames = '无';
                break;
              default:
                item.paymentMethodNames = '无';
                break;
            }
            item.prTypeName = item.poiList.length ? item.poiList[0].prTypeName : '';

            for (var a = 0; a < this.paymentMethodList.length; a++) {
              const adata = this.paymentMethodList[a];
              if (item.paymentMethod == adata.dictItemValue) {
                item.paymentMethodName = adata.dictItemName;
              }
            }
            for (var z = 0; z < this.tradeTermsList.length; z++) {
              const z_item = this.tradeTermsList[z];
              if (item.tradeTerms == z_item.index) {
                item.tradeTermsName = z_item.name;
              }
            }
            // item.paymentMethodJoin=item.paymentMethodName+item.downPaymentOrPeriod
            // item.currencyName = item.currency ? this.currencyTypeHandle(item.currency) : '';

            //           if(this.orderStatus==1){
            //         	    item.actions = {
            //                hasEdit: true,
            //                hasSubmit: true,
            //                hasDelete: true
            //              };
            //	           }else if(this.orderStatus==2||item.orderStatus == 3){
            //	           	  item.actions = {
            //	                  hasCheck: true
            //	              };
            //	           }else{
            //	           	 item.actions = {};
            //	           }
            //	          item.isReserveNoCustom = (item.prTypeName!='日常备货'? true : false);
            //           if (item.isReserveNoCustom) {
            //             item.isHideFew = true;
            //           }

            if (item.poiList && item.poiList.length) {
              for (let j = 0; j < item.poiList.length; j++) {
                const data = item.poiList[j];
                // data.remarkZ = 'null织标数量:"1"';
                data.requiredQuantityPerPackage = data.requiredQuantityPerPackage ? data.requiredQuantityPerPackage : 1; // 请求规格默认设置为1
                data.offeredDate = data.offeredDate ? data.offeredDate : data.requiredDate;
                data.offeredPackages = data.offeredPackages ? data.offeredPackages : data.requiredPackages; // 包
                data.offeredQuantityPerPackage = data.offeredQuantityPerPackage ? data.offeredQuantityPerPackage : data.requiredQuantityPerPackage; // 规格
                data.offeredQuantity = data.offeredQuantity ? data.offeredQuantity : data.requiredQuantity; // 米
                item.supplierShortName = item.poiList[0].supplierShortName ? item.poiList[0].supplierShortName : item.supplierShortName; // 供应商简称
                // 采购订单类型为内部往来订单时提取备注中的数字赋给采购价格   （采购价格无值）        （备注为数字格式）
                if ((item.purchaseOrderType === 'InternalPurchase') && (data.offeredPrice === null) && (!isNaN(data.remark))) {
                  data.offeredPrice = data.remark;
                }
                // 系统折扣=采购价格/价单价格
                if (!parseFloat(data.offeredPrice) || !parseFloat(data.priceListPrice)) {
                  data.systemDiscount = '100%';
                } else {
                  data.systemDiscount = parseFloat((parseFloat(data.offeredPrice) / parseFloat(data.priceListPrice) * 100).toFixed(2)) + '%';
                }
                if (data.mobileGoods !== null) {
                  data.mobileGoods == false ? data.mobileGoods = '否' : data.mobileGoods = '是';
                }
                if (data.belongToEv !== null) {
                  data.belongToEv == false ? data.belongToEv = '否' : data.belongToEv = '是';
                }
                // if (data.remark && data.remark.indexOf('织标数量') !== -1) {
                //   if (data.remark.match(reg)) {
                //     data.scabalWovenLabel = Number(data.remark.match(reg)[1]);
                //   } else {
                //     data.scabalWovenLabel = data.remark.split(':')[1];
                //   }
                // } else {
                //   data.scabalWovenLabel = '';
                // }

                // 织标数量截取备注中的织标数量
                if (data.remark && data.remark.indexOf('织标数量') !== -1) {
                  var str = data.remark;
                  var index = str.lastIndexOf('织标数量:');
                  data.scabalWovenLabel = str.substring(index + 5, str.length);
                } else {
                  data.scabalWovenLabel = '';
                }
                data.scabalSaleSeason = data.materialFactorySeason;
                // 去除关联PR编号首尾空格
                data.prCode = data.prCode ? trimStr(data.prCode) : '';
                // data.purchaseRemark = '';
                // data.scabalSaleSeason = '';
                // data.scabalWovenLabel = data.remarkZ.match(reg) ? Number(data.remarkZ.match(reg)[1]) : '';
                this.updateEditModeSet('edit', data);
              }
            }
          }
          this.tableLoading = false;
        } else {
          this.tableLoading = false;
        }
      });
      setTimeout(() => {
        this.tableLoading = false;
      }, 10000);
    },
    async getSearchOrderList(id) {
      await searchPurchaseOrderList(id).then(res => {
        // console.log('当前操作订单：', res);
        if (res.code === '0') {
          res.data.isShowList = true;
          res.data.createTime = timeFormat(res.data.createTime);
          this.editOrderData = res.data;
          this.editOrderData.poiList.forEach(item => {
            // console.log('工厂交期数据：', item.offeredDate);
            if (item.offeredDate) {
              item.offeredDate = timeFormatGMT(item.offeredDate);
            } else {
              item.offeredDate = '';
            }
          });
        }
      });
    },
    async getPurchaseOrderType() {
      // 采购订单类型列表
      // constDictList('PurchaseOrderType').then(res => {
      //   console.log('采购订单类型：', res);
      //   if (res.code === '0') {
      //     this.orderTypeList = res.data;
      //   }
      // });
      let res = await constDictList('PurchaseOrderType')
      console.log("PurchaseOrderTyperes",res);
      this.orderTypeList = res.data;
    },
    getSupplierDictList() {
      // 供应商列表
      supplierDictList().then(res => {
        // console.log('供应商列表：', res);
        if (res.code === '0') {
          this.supplierList = res.data;
        }
      });
    },
    async getCurrencyList() {
      // 币种列表
      // constDictList('Currency').then(res => {
      //   console.log('币种列表：', res);
      //   if (res.code === '0') {
      //     this.currencyList = res.data;
      
      //   }
      // });
      let res = await constDictList('Currency')
      this.currencyList = res.data;
    },
    async getPaymentMethodList() {
      // 付款方式列表
      // dictList('paymentMethod').then(res => {
      //   console.log('付款方式列表：', res);
      //   this.paymentMethodList = res.data;
      //   this.paymentMethodListClone = res.data;
      // });
      let res = await dictList('paymentMethod')
      console.log('付款方式列表：', res);
      this.paymentMethodList = res.data;
      this.paymentMethodListClone = res.data;
    },
    async getPeriodList() {
      // 账期列表
      // dictList('period').then(res => {
      //   // console.log('账期列表：', res);
      //   this.periodList = res.data;
      // });
      let res = await dictList('period')
      console.log('账期列表：', res);
      this.periodList = res.data;
    },
   async getDownPaymentList() {
      // 预付列表
      // dictList('downPayment').then(res => {
      //   // console.log('预付列表：', res);
      //   this.downPaymentList = res.data;
      // });
      let res = await dictList('downPayment')
      this.downPaymentList = res.data;
    },
    async getSaleSeasonList() {
      // 工厂季列表
      // constDictList('SaleSeasou').then(res => {
      //   // console.log('工厂季列表：', res);
      //   if (res.code === '0') {
      //     this.saleSeasonList = res.data;
      //   }
      // });
       let res = await constDictList('SaleSeasou')
      this.saleSeasonList = res.data;
    },
    getPriceOrderList(supplier, currency, season) {
      // 采购价单列表
      let supplierId = null;
      if (!supplier) {
        if (this.editOrderData.supplierCode) {
          supplierId = this.editOrderData.supplierCode;
        } else {
          const supplierData = this.supplierList.find(item => {
            return item.supplierName == this.editOrderData.supplierName;
          });
          supplierId = supplierData ? supplierData.supplierId : '';
        }
      } else {
        supplierId = supplier;
      }
      const currencyCode = currency || this.editOrderData.currency;
      // let factorySeason = season ? season : this.editOrderData.factorySeason;
      const factorySeason = '';
      getPriceOrderList(supplierId, currencyCode || '', factorySeason || '').then(res => {
        // console.log('采购价单列表：', res);
        if (res.code === '0') {
          this.priceOrderList = res.data;
        }
      });
    },
    // 编辑组件函数
    updateEditModeSet(type, data, status) {
      // 编辑字段呈现方式集合
      const editModeSet = JSON.parse(JSON.stringify(this.editModeSet));
      const textSet = JSON.parse(JSON.stringify(this.textSet));
      const inputSet = JSON.parse(JSON.stringify(this.inputSet));
      const datePickerSet = JSON.parse(JSON.stringify(this.datePickerSet));
      const unitSet = JSON.parse(JSON.stringify(this.unitSet));
      if (type == 'edit') {
        switch (status) {
          case 'Draft':

            break;
          case 'Approved':

            break;
          default:
            break;
        }
      }
      editModeSet.textSet = textSet;
      editModeSet.inputSet = inputSet;
      editModeSet.datePickerSet = datePickerSet;
      editModeSet.unitSet = unitSet;
      data.editModeSet = editModeSet;
    },
    numberInputChange(val, item, fieldItem) {
      switch (fieldItem.field) {
        case 'offeredQuantity':
          // 采购数量
          console.log('采购数量值改变：', val, item, fieldItem);
          // this.priceResetHandle(val, item);
          this.priceListHandle(this.editOrderData);
          break;

        default:
          break;
      }
    },
    unitNumberInputChange(val, item, fieldItem, step) {
      let field = '';
      if (step == 'first') {
        field = fieldItem.field_1;
      } else if (step == 'second') {
        field = fieldItem.field_2;
      }
      switch (field) {
        case 'offeredPackages':
          // 采购数量包数
          console.log('采购数量包数值改变：', val, item, fieldItem, step, this.editOrderData);
          item.offeredQuantity = val * item.offeredQuantityPerPackage;
          item.singleTotalOfferedPrice = parseFloat(item.offeredPrice) * item.offeredQuantity;
          this.realTimeComputingHandle('totalQuantity');
          if (item.singleTotalOfferedPrice) {
            this.realTimeComputingHandle('totalAmount');
          }
          this.priceListHandle(this.editOrderData, 'change');
          this.$forceUpdate();
          break;
        case 'offeredQuantityPerPackage':
          // 采购数量规格
          console.log('采购数量规格值改变：', val, item, fieldItem, step, this.editOrderData);
          item.offeredQuantity = item.offeredPackages * val;
          item.singleTotalOfferedPrice = parseFloat(item.offeredPrice) * item.offeredQuantity;
          this.realTimeComputingHandle('totalQuantity');
          if (item.singleTotalOfferedPrice) {
            this.realTimeComputingHandle('totalAmount');
          }
          this.priceListHandle(this.editOrderData, 'change');
          this.$forceUpdate();
          break;

        default:
          break;
      }
    },
    realTimeComputingHandle(tag) {
      // 实时计算总数量与总金额
      switch (tag) {
        case 'totalQuantity': {
          const totalOfferedQuantities = this.editOrderData.poiList.reduce((pre, cur) => {
            return pre + cur.offeredQuantity;
          }, 0);
          this.editOrderData.totalQuantity = totalOfferedQuantities.toFixed(2);
          break;
        }
        case 'totalAmount': {
          const totalAmountRmb = this.editOrderData.poiList.reduce((pre, cur) => {
            console.log('pre,cur', pre, cur);
            return parseFloat(pre) + parseFloat(cur.singleTotalOfferedPrice);
          }, 0);
          this.editOrderData.totalAmount = totalAmountRmb.toFixed(2);
          break;
        }
        default:
          break;
      }
    },
    inputBlur(event, item, fieldItem) {
      switch (fieldItem.field) {
        case 'offeredPrice': {
          // 采购价格
          console.log('采购价格值失焦：', event, item, fieldItem);
          const priceReg = /(^[1-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/;
          if (!priceReg.test(item.offeredPrice)) {
            this.$message({
              type: 'error',
              message: '采购价格仅支持最多保留两位小数数字格式！'
            });
            item.offeredPrice = 0;
            return false;
          }
          const defaultCount = 0;
          const offeredPrice = item.offeredPrice ? (parseFloat(item.offeredPrice)).toFixed(2) : defaultCount.toFixed(2);
          const priceListPrice = item.priceListPrice ? (parseFloat(item.priceListPrice)).toFixed(2) : defaultCount.toFixed(2);
          if (!parseFloat(priceListPrice) || !parseFloat(offeredPrice)) {
            item.systemDiscount = '100%';
          } else {
            item.systemDiscount = parseFloat((parseFloat(item.offeredPrice) / parseFloat(item.priceListPrice) * 100).toFixed(2)) + '%';
          }
          item.singleTotalOfferedPrice = parseFloat(item.offeredPrice) * item.offeredQuantity;
          this.realTimeComputingHandle('totalAmount');
          this.$forceUpdate();
          break;
        }
        default:
          break;
      }
    }
    // unitNumberInputBlur(event, item, fieldItem, step) {
    //   let val = event.target.value;
    //   let field = '';
    //   if (step == 'frist') {
    //     field = fieldItem.field_1;
    //   } else if (step == 'second') {
    //     field = fieldItem.field_2;
    //   }
    //   switch (field) {
    //     case 'offeredPackages':
    //       // 采购数量包数
    //       console.log('采购数量包数值失焦：', event, item, fieldItem, step);
    //       // this.priceResetHandle(val, item);
    //       this.priceListHandle(item);
    //       break;
    //     case 'offeredQuantityPerPackage':
    //       // 采购数量规格
    //       console.log('采购数量规格值失焦：', event, item, fieldItem, step);
    //       // this.priceResetHandle(val, item);
    //       this.priceListHandle(item);
    //       break;

    //     default:
    //       break;
    //   }
    // },

  }
};
</script>

<style lang="scss" scoped>
@import "../../../styles/index.scss";
* {
  box-sizing: border-box;
}
.purchaseOrder {
  .isremark{
    padding-bottom:10px;
  }
  .isThrough{
    text-decoration:line-through;
  }
}
</style>
<style lang="scss">
 .purchaseOrder {
    .plTableBox .el-table td, .plTableBox .el-table th{
    text-align: center !important;
}

 }

</style>

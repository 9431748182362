var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container goodsmanagement" },
    [
      _c("div", { staticClass: "actions_part clearfix" }, [
        _c("div", { staticClass: "actions_wrap" }, [
          _c(
            "div",
            { staticClass: "display_ib mr10 mb10" },
            [
              _c("span", { staticClass: "tag" }, [_vm._v("订单编号：")]),
              _c("el-input", {
                staticClass: "input_single w200 mr10",
                attrs: { placeholder: "请输入" },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.searchHandle($event)
                  }
                },
                model: {
                  value: _vm.purchaseOrderId,
                  callback: function($$v) {
                    _vm.purchaseOrderId =
                      typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "purchaseOrderId"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "display_ib mr10 mb10" },
            [
              _c("span", { staticClass: "tag" }, [_vm._v("确认书号：")]),
              _c("el-input", {
                staticClass: "input_single w200 mr10",
                attrs: { placeholder: "请输入" },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.searchHandle($event)
                  }
                },
                model: {
                  value: _vm.confirmNumber,
                  callback: function($$v) {
                    _vm.confirmNumber =
                      typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "confirmNumber"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "display_ib mr10 mb10" },
            [
              _c("span", { staticClass: "tag" }, [_vm._v("物料编号：")]),
              _c("el-input", {
                staticClass: "input_single w200 mr10",
                attrs: { placeholder: "请输入" },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.searchHandle($event)
                  }
                },
                model: {
                  value: _vm.materialCode,
                  callback: function($$v) {
                    _vm.materialCode =
                      typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "materialCode"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "display_ib mr10 mb10" },
            [
              _c("span", { staticClass: "tag" }, [_vm._v("采购订单类型：")]),
              _c(
                "el-select",
                {
                  staticClass: "select_single w200 mr10",
                  attrs: { placeholder: "请选择" },
                  on: { change: _vm.searchHandle },
                  model: {
                    value: _vm.purchaseOrderType,
                    callback: function($$v) {
                      _vm.purchaseOrderType = $$v
                    },
                    expression: "purchaseOrderType"
                  }
                },
                [
                  _c("el-option", {
                    key: "",
                    attrs: { label: "全部", value: "" }
                  }),
                  _vm._l(_vm.orderTypeList, function(item) {
                    return _c("el-option", {
                      key: item.index,
                      attrs: { label: item.name, value: item.index }
                    })
                  })
                ],
                2
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inline_block mr10 mb10" },
            [
              _c("span", { staticClass: "tag" }, [_vm._v("供应商：")]),
              _c(
                "el-select",
                {
                  staticClass: "select_single w200 mr10",
                  attrs: { filterable: "", placeholder: "请选择" },
                  on: { change: _vm.searchHandle },
                  model: {
                    value: _vm.supplierCode,
                    callback: function($$v) {
                      _vm.supplierCode = $$v
                    },
                    expression: "supplierCode"
                  }
                },
                [
                  _c("el-option", {
                    key: "",
                    attrs: { label: "全部", value: "" }
                  }),
                  _vm._l(_vm.supplierList, function(item) {
                    return _c("el-option", {
                      key: item.supplierId,
                      attrs: {
                        label: item.supplierCode,
                        value: item.supplierId
                      }
                    })
                  })
                ],
                2
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "display_ib mr10 mb10" },
            [
              _c("span", { staticClass: "tag" }, [_vm._v("工厂交期：")]),
              _c("el-date-picker", {
                staticClass: "mr10",
                attrs: {
                  "unlink-panels": "",
                  type: "daterange",
                  format: "yyyy-MM-dd",
                  "value-format": "yyyy-MM-dd",
                  "range-separator": "至",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期"
                },
                model: {
                  value: _vm.dateVal,
                  callback: function($$v) {
                    _vm.dateVal = $$v
                  },
                  expression: "dateVal"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "display_ib mr10 mb10" },
            [
              _c("span", { staticClass: "tag" }, [_vm._v("货妥日期：")]),
              _c("el-date-picker", {
                staticClass: "mr10",
                attrs: {
                  "unlink-panels": "",
                  type: "daterange",
                  format: "yyyy-MM-dd",
                  "value-format": "yyyy-MM-dd",
                  "range-separator": "至",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期"
                },
                model: {
                  value: _vm.dateValGoodsDuly,
                  callback: function($$v) {
                    _vm.dateValGoodsDuly = $$v
                  },
                  expression: "dateValGoodsDuly"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "display_ib mr10 mb10" },
            [
              _c("span", { staticClass: "tag" }, [_vm._v("申请人：")]),
              _c("el-input", {
                staticClass: "input_single w200 mr10",
                attrs: { placeholder: "请输入" },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.searchHandle($event)
                  }
                },
                model: {
                  value: _vm.applicantName,
                  callback: function($$v) {
                    _vm.applicantName =
                      typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "applicantName"
                }
              })
            ],
            1
          )
        ]),
        _c(
          "div",
          { staticClass: "actions_btn_wrap down t_right" },
          [
            _c(
              "a",
              {
                staticClass: "link inline_block lh36 ml10 mr10",
                attrs: { type: "primary" },
                on: { click: _vm.VBCExcelTemplate }
              },
              [_vm._v("VBC货妥导入模板")]
            ),
            _c(
              "el-button",
              { attrs: { type: "success" }, on: { click: _vm.VBCExcelImport } },
              [_vm._v("导入货妥（VBC版本）")]
            ),
            _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.excelDownload } },
              [_vm._v("导出Excel")]
            ),
            _c("el-button", {
              attrs: {
                size: "small",
                type: "primary",
                icon: "el-icon-search",
                circle: "",
                title: "搜索"
              },
              on: { click: _vm.searchHandle }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "table_part clearfix" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.listLoading,
                expression: "listLoading"
              }
            ],
            staticClass: "single_tab"
          },
          [
            _c(
              "el-table",
              {
                ref: "multipleTable",
                staticClass: "table_list",
                attrs: {
                  data: _vm.orderList,
                  "row-key": "purchaseOrderId",
                  "expand-row-keys": _vm.expands,
                  border: "",
                  fit: "",
                  stripe: ""
                },
                on: { "row-click": _vm.rowClick, "expand-change": _vm.rowClick }
              },
              [
                _c("el-table-column", {
                  attrs: { type: "expand" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c(
                            "div",
                            { staticClass: "actions_wrap mb20 clearfix" },
                            [
                              _c(
                                "el-button",
                                {
                                  staticClass: "fr ml10",
                                  attrs: { type: "primary" },
                                  on: {
                                    click: function($event) {
                                      return _vm.childExcelDownload(scope.row)
                                    }
                                  }
                                },
                                [_vm._v("导出Excel")]
                              ),
                              _c(
                                "el-button",
                                {
                                  staticClass: "fr mr10",
                                  attrs: { type: "primary" },
                                  on: { click: _vm.excelImport }
                                },
                                [_vm._v("导入货妥（非VBC版本）")]
                              ),
                              _c(
                                "el-button",
                                {
                                  staticClass: "fr",
                                  attrs: { type: "primary" },
                                  on: {
                                    click: function($event) {
                                      return _vm.childBatchDeletion(scope.row)
                                    }
                                  }
                                },
                                [_vm._v("货妥批量删除")]
                              )
                            ],
                            1
                          ),
                          _c(
                            "pl-table",
                            {
                              ref: "childMultipleTable_" + scope.row.id,
                              staticClass: "table_list scrollHeight",
                              staticStyle: { width: "100%" },
                              attrs: {
                                data: scope.row.fciList,
                                "row-key": "id",
                                "expand-row-keys": _vm.childExpands,
                                "max-height": 700,
                                "use-virtual": true,
                                border: "",
                                stripe: "",
                                fit: ""
                              },
                              on: {
                                "expand-change": _vm.expandChange,
                                "row-click": _vm.childRowClick
                              }
                            },
                            [
                              _c("el-table-column", {
                                attrs: { type: "expand" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(scope) {
                                        return [
                                          _c(
                                            "el-table",
                                            {
                                              staticClass: "table_list",
                                              attrs: {
                                                data: scope.row.goodsList,
                                                border: "",
                                                fit: ""
                                              }
                                            },
                                            [
                                              _c("el-table-column", {
                                                attrs: { label: "货妥数量" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var row = ref.row
                                                        return [
                                                          row.purchaseMeasurementUnit ==
                                                          "米"
                                                            ? _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    (row.readyBagQuantity
                                                                      ? row.readyBagQuantity
                                                                      : 0) +
                                                                      "包 " +
                                                                      (row.packSpecification
                                                                        ? row.packSpecification
                                                                        : 0) +
                                                                      "米/包 " +
                                                                      (row.readyMeter
                                                                        ? row.readyMeter
                                                                        : 0) +
                                                                      "米"
                                                                  )
                                                                )
                                                              ])
                                                            : _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    (row.readyMeter
                                                                      ? row.readyMeter
                                                                      : 0) +
                                                                      (row.purchaseMeasurementUnit
                                                                        ? row.purchaseMeasurementUnit
                                                                        : "条")
                                                                  )
                                                                )
                                                              ])
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              }),
                                              _c("el-table-column", {
                                                attrs: { label: "已调拨数量" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var row = ref.row
                                                        return [
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                (row.allocationMeter
                                                                  ? row.allocationMeter
                                                                  : 0) +
                                                                  (row.purchaseMeasurementUnit
                                                                    ? row.purchaseMeasurementUnit
                                                                    : "条")
                                                              )
                                                            )
                                                          ])
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              }),
                                              _c("el-table-column", {
                                                attrs: { label: "货妥时间" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var row = ref.row
                                                        return [
                                                          _vm._v(
                                                            "\n                          " +
                                                              _vm._s(
                                                                row.readyDate
                                                              ) +
                                                              "\n                        "
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              }),
                                              _c("el-table-column", {
                                                attrs: { label: "是否可发运" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var row = ref.row
                                                        return [
                                                          _vm._v(
                                                            "\n                          " +
                                                              _vm._s(
                                                                row.ableShipments
                                                                  ? "是"
                                                                  : "否"
                                                              ) +
                                                              "\n                        "
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              }),
                              _c("el-table-column", {
                                attrs: { label: "PO行号" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var row = ref.row
                                        return [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(row.purchaseOrderSn) +
                                              "\n                  "
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "物料编号",
                                  "min-width": "180",
                                  "class-name": "marpno_wuliao",
                                  "show-overflow-tooltip": true
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var row = ref.row
                                        return [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(row.materialCode) +
                                              "\n                  "
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              }),
                              _c("el-table-column", {
                                attrs: { label: "工厂季" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var row = ref.row
                                        return [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(row.factorySeason) +
                                              "\n                  "
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              }),
                              _c("el-table-column", {
                                attrs: { label: "供应商" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var row = ref.row
                                        return [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(row.supplierShortName) +
                                              "\n                  "
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              }),
                              _c("el-table-column", {
                                attrs: { label: "确认书号" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var row = ref.row
                                        return [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(row.confirmNumber) +
                                              "\n                  "
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              }),
                              _c("el-table-column", {
                                attrs: { label: "已确认订单数量" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var row = ref.row
                                        return [
                                          row.purchaseMeasurementUnit == "米"
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    (row.confirmPackages
                                                      ? row.confirmPackages
                                                      : 0) +
                                                      "包 " +
                                                      (row.confirmQuantityPerPackage
                                                        ? row.confirmQuantityPerPackage
                                                        : 0) +
                                                      "米/包 " +
                                                      (row.confirmQuantity
                                                        ? row.confirmQuantity
                                                        : 0) +
                                                      "米"
                                                  )
                                                )
                                              ])
                                            : _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    (row.confirmQuantity
                                                      ? row.confirmQuantity
                                                      : 0) +
                                                      (row.purchaseMeasurementUnit
                                                        ? row.purchaseMeasurementUnit
                                                        : "条")
                                                  )
                                                )
                                              ])
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              }),
                              _c("el-table-column", {
                                attrs: { label: "货妥总量" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var row = ref.row
                                        return [
                                          row.purchaseMeasurementUnit == "米"
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    (row.cargoReadyPackagesTotal
                                                      ? row.cargoReadyPackagesTotal
                                                      : 0) +
                                                      "包 " +
                                                      (row.cargoReadyQuantityPerPackage
                                                        ? row.cargoReadyQuantityPerPackage
                                                        : 0) +
                                                      "米/包 " +
                                                      (row.cargoReadyQuantityTotal
                                                        ? row.cargoReadyQuantityTotal
                                                        : 0) +
                                                      "米"
                                                  )
                                                )
                                              ])
                                            : _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    (row.cargoReadyQuantityTotal
                                                      ? row.cargoReadyQuantityTotal
                                                      : 0) +
                                                      (row.purchaseMeasurementUnit
                                                        ? row.purchaseMeasurementUnit
                                                        : "条")
                                                  )
                                                )
                                              ])
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              }),
                              _c("el-table-column", {
                                attrs: { label: "已调拨数量" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var row = ref.row
                                        return [
                                          row.purchaseMeasurementUnit == "米"
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    (row.allocationMeter
                                                      ? row.allocationMeter
                                                      : 0) + "米"
                                                  )
                                                )
                                              ])
                                            : _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    (row.allocationMeter
                                                      ? row.allocationMeter
                                                      : 0) +
                                                      (row.purchaseMeasurementUnit
                                                        ? row.purchaseMeasurementUnit
                                                        : "条")
                                                  )
                                                )
                                              ])
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              }),
                              _c("el-table-column", {
                                attrs: { label: "工厂交期" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var row = ref.row
                                        return [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(row.offeredDate) +
                                              "\n                  "
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              }),
                              _c("el-table-column", {
                                attrs: { label: "首次货妥时间" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var row = ref.row
                                        return [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                row.cargoReadyDateRecently
                                              ) +
                                              "\n                  "
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              }),
                              _c("el-table-column", {
                                attrs: { label: "采购备注" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var row = ref.row
                                        return [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(row.purchaseRemark) +
                                              "\n                  "
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              }),
                              _c("el-table-column", {
                                attrs: { label: "操作" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var row = ref.row
                                        var $index = ref.$index
                                        return [
                                          _c("el-button", {
                                            staticClass: "el-icon-plus",
                                            attrs: {
                                              type: "primary",
                                              circle: "",
                                              size: "mini",
                                              title: "新增子项"
                                            },
                                            on: {
                                              click: function($event) {
                                                $event.stopPropagation()
                                                return _vm.addHandle(
                                                  $index,
                                                  row
                                                )
                                              }
                                            }
                                          })
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              })
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: { label: "订单编号" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        return [
                          _vm._v(
                            "\n              " +
                              _vm._s(row.purchaseOrderId) +
                              "\n            "
                          )
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: { label: "采购订单类型" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        return [
                          _vm._v(
                            "\n              " +
                              _vm._s(row.purchaseOrderTypeName) +
                              "\n            "
                          )
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: { label: "创建时间" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        return [
                          _vm._v(
                            "\n              " +
                              _vm._s(row.createTime) +
                              "\n            "
                          )
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: { label: "申请人" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        return [
                          _vm._v(
                            "\n              " +
                              _vm._s(row.applicantName) +
                              "\n            "
                          )
                        ]
                      }
                    }
                  ])
                })
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "pagination_wrap fr" },
          [
            _c("el-pagination", {
              attrs: {
                "current-page": _vm.currentPage,
                "page-sizes": [10, 50],
                "page-size": _vm.pageSize,
                layout: "sizes, total, prev, pager, next",
                total: _vm.total
              },
              on: {
                "size-change": _vm.handleSizeChange,
                "current-change": _vm.handleCurrentChange,
                "update:currentPage": function($event) {
                  _vm.currentPage = $event
                },
                "update:current-page": function($event) {
                  _vm.currentPage = $event
                }
              }
            })
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "add_popup_part" },
        [
          _c(
            "el-dialog",
            {
              directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
              attrs: {
                title: _vm.popupTitle,
                "close-on-click-modal": false,
                "close-on-press-escape": false,
                visible: _vm.dialogFormVisible
              },
              on: {
                "update:visible": function($event) {
                  _vm.dialogFormVisible = $event
                }
              }
            },
            [
              _c("el-tabs", [
                _c(
                  "ul",
                  { staticClass: "childlist" },
                  _vm._l(_vm.goodFieldNameList, function(item, index) {
                    return _c("li", { key: index, class: item.class }, [
                      _vm._v(_vm._s(item.name))
                    ])
                  }),
                  0
                ),
                _c(
                  "div",
                  { staticClass: "allparentlist" },
                  _vm._l(_vm.goodsList, function(item, index) {
                    return _c(
                      "ul",
                      { key: index, staticClass: "childcontentlist" },
                      [
                        _c(
                          "li",
                          {
                            staticClass: "w13p",
                            attrs: { title: item.materialCode }
                          },
                          [
                            _vm._v(
                              _vm._s(item.materialCode ? item.materialCode : "")
                            )
                          ]
                        ),
                        _c(
                          "li",
                          {
                            staticClass: "w14p",
                            attrs: { title: item.supplierShortName }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                item.supplierShortName
                                  ? item.supplierShortName
                                  : ""
                              )
                            )
                          ]
                        ),
                        _c(
                          "li",
                          {
                            staticClass: "w14p",
                            attrs: { title: item.offeredDate }
                          },
                          [
                            _vm._v(
                              _vm._s(item.offeredDate ? item.offeredDate : "")
                            )
                          ]
                        ),
                        _c(
                          "li",
                          {
                            staticClass: "w14p",
                            attrs: { title: item.readyDate }
                          },
                          [_vm._v(_vm._s(item.readyDate ? item.readyDate : ""))]
                        ),
                        _c("li", { staticClass: "w18p" }, [
                          item.purchaseMeasurementUnit === "米"
                            ? _c(
                                "div",
                                {
                                  attrs: {
                                    title:
                                      (item.readyBagQuantity
                                        ? item.readyBagQuantity
                                        : "0") +
                                      "包 " +
                                      (item.packSpecification
                                        ? item.packSpecification
                                        : "0") +
                                      "米/包 " +
                                      (item.readyMeter
                                        ? item.readyMeter
                                        : "0") +
                                      "米"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              \t\t" +
                                      _vm._s(
                                        (item.readyBagQuantity
                                          ? item.readyBagQuantity
                                          : "0") + "包 "
                                      ) +
                                      "\n\t              \t" +
                                      _vm._s(
                                        (item.packSpecification
                                          ? item.packSpecification
                                          : "0") + "米/包 "
                                      ) +
                                      "\n\t              \t" +
                                      _vm._s(
                                        (item.readyMeter
                                          ? item.readyMeter
                                          : "0") + "米"
                                      ) +
                                      "\n              \t"
                                  )
                                ]
                              )
                            : _c("div", [
                                item.purchaseMeasurementUnit != null &&
                                item.purchaseMeasurementUnit != ""
                                  ? _c(
                                      "span",
                                      {
                                        attrs: {
                                          title:
                                            (item.readyMeter
                                              ? item.readyMeter
                                              : "0") +
                                            item.purchaseMeasurementUnit
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            (item.readyMeter
                                              ? item.readyMeter
                                              : "0") +
                                              item.purchaseMeasurementUnit
                                          )
                                        )
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        attrs: {
                                          title:
                                            (item.readyMeter
                                              ? item.readyMeter
                                              : "0") + "条"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            (item.readyMeter
                                              ? item.readyMeter
                                              : "0") + "条"
                                          )
                                        )
                                      ]
                                    )
                              ])
                        ]),
                        _c(
                          "li",
                          { staticClass: "w19p" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: item.ableShipments,
                                callback: function($$v) {
                                  _vm.$set(item, "ableShipments", $$v)
                                },
                                expression: "item.ableShipments"
                              }
                            })
                          ],
                          1
                        ),
                        _c("li", { staticClass: "w6p fr" }, [
                          _c("i", {
                            staticClass: "el-icon-delete",
                            attrs: { type: "primary", title: "删除" },
                            on: {
                              click: function($event) {
                                return _vm.deleteItem(item.id)
                              }
                            }
                          })
                        ])
                      ]
                    )
                  }),
                  0
                ),
                _c(
                  "p",
                  {
                    staticClass: "add_goods_btn",
                    on: { click: _vm.addGoodsItemHandle }
                  },
                  [
                    _c("i", {
                      staticClass: "el-icon-circle-plus",
                      staticStyle: {
                        "margin-right": "10px",
                        "vertical-align": "middle"
                      },
                      attrs: { type: "primary", title: "添加货妥" }
                    }),
                    _vm._v("添加货妥\n          ")
                  ]
                ),
                _c("div", { staticClass: "add_goods_warp" }, [
                  _c(
                    "ul",
                    { staticClass: "childlist" },
                    _vm._l(_vm.addGoodFieldNameList, function(item, index) {
                      return _c("li", { key: index, class: item.class }, [
                        item.isRules
                          ? _c("span", { staticClass: "red mr5" }, [
                              _vm._v("*")
                            ])
                          : _vm._e(),
                        _vm._v(
                          "\n                " +
                            _vm._s(item.name) +
                            "\n              "
                        )
                      ])
                    }),
                    0
                  ),
                  _vm.allAddData.cargoReadyListDtoList.length
                    ? _c(
                        "div",
                        { staticClass: "allparentlist" },
                        _vm._l(_vm.allAddData.cargoReadyListDtoList, function(
                          item,
                          index
                        ) {
                          return _c(
                            "ul",
                            { key: index, staticClass: "childcontentlist" },
                            [
                              _c("li", { staticClass: "w50p t_center" }, [
                                item.purchaseMeasurementUnit == "米"
                                  ? _c(
                                      "div",
                                      [
                                        _c("el-input-number", {
                                          staticClass: "w100",
                                          attrs: {
                                            size: "small",
                                            precision: "2",
                                            "controls-position": "right"
                                          },
                                          on: { change: _vm.packagesChange },
                                          model: {
                                            value: item.readyBagQuantity,
                                            callback: function($$v) {
                                              _vm.$set(
                                                item,
                                                "readyBagQuantity",
                                                $$v
                                              )
                                            },
                                            expression: "item.readyBagQuantity"
                                          }
                                        }),
                                        _c(
                                          "span",
                                          { staticClass: "w40 display_ib" },
                                          [_vm._v("包")]
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "display_ib" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                item.confirmQuantityPerPackage
                                              ) + "米/包"
                                            )
                                          ]
                                        ),
                                        _vm._v(" \n\t                  "),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "meters display_ib",
                                            attrs: {
                                              title:
                                                parseFloat(
                                                  item.readyBagQuantity
                                                ) *
                                                parseFloat(
                                                  item.confirmQuantityPerPackage
                                                )
                                                  ? parseFloat(
                                                      item.readyBagQuantity
                                                    ) *
                                                    parseFloat(
                                                      item.confirmQuantityPerPackage
                                                    )
                                                  : 0
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                parseFloat(
                                                  item.readyBagQuantity
                                                ) *
                                                  parseFloat(
                                                    item.confirmQuantityPerPackage
                                                  )
                                                  ? parseFloat(
                                                      item.readyBagQuantity
                                                    ) *
                                                      parseFloat(
                                                        item.confirmQuantityPerPackage
                                                      )
                                                  : 0
                                              )
                                            )
                                          ]
                                        ),
                                        _vm._v("米\n                \t")
                                      ],
                                      1
                                    )
                                  : _c(
                                      "div",
                                      [
                                        _c("el-input-number", {
                                          staticClass: "w100",
                                          attrs: {
                                            size: "small",
                                            precision:
                                              item.purchaseMeasurementUnit ==
                                              "公斤"
                                                ? 2
                                                : 0,
                                            "controls-position": "right",
                                            min: 0
                                          },
                                          on: { change: _vm.packagesChange },
                                          model: {
                                            value: item.readyMeter,
                                            callback: function($$v) {
                                              _vm.$set(item, "readyMeter", $$v)
                                            },
                                            expression: "item.readyMeter"
                                          }
                                        }),
                                        _c(
                                          "span",
                                          { staticClass: "w40 display_ib" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                item.purchaseMeasurementUnit
                                                  ? item.purchaseMeasurementUnit
                                                  : "条"
                                              )
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                              ]),
                              _c(
                                "li",
                                { staticClass: "w20p t_center" },
                                [
                                  _c("el-date-picker", {
                                    staticClass: "w200",
                                    attrs: {
                                      size: "small",
                                      "value-format": "yyyy-MM-dd",
                                      type: "date",
                                      placeholder: "选择日期"
                                    },
                                    model: {
                                      value: item.readyDate,
                                      callback: function($$v) {
                                        _vm.$set(item, "readyDate", $$v)
                                      },
                                      expression: "item.readyDate"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "li",
                                { staticClass: "w20p" },
                                [
                                  _c("el-checkbox", {
                                    model: {
                                      value: item.ableShipments,
                                      callback: function($$v) {
                                        _vm.$set(item, "ableShipments", $$v)
                                      },
                                      expression: "item.ableShipments"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "li",
                                {
                                  staticClass: "w10p fr",
                                  on: {
                                    click: function($event) {
                                      return _vm.deleteGoodItem(item.uuid)
                                    }
                                  }
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-remove v_middle mr10",
                                    attrs: { type: "primary", title: "" }
                                  })
                                ]
                              )
                            ]
                          )
                        }),
                        0
                      )
                    : _c("div", { staticClass: "empty_wrap" }, [
                        _c("img", {
                          staticClass: "empty_img",
                          attrs: {
                            src: require("../../../assets/image/add.png")
                          }
                        }),
                        _c("p", [_vm._v("信息空空如也去添加")])
                      ])
                ])
              ]),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        { name: "preventReClick", rawName: "v-preventReClick" }
                      ],
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.submitAddHandle("submit")
                        }
                      }
                    },
                    [_vm._v("提交货妥")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "import_popup_part" },
        [
          _c(
            "el-dialog",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.editPartLoading,
                  expression: "editPartLoading"
                },
                { name: "dialogDrag", rawName: "v-dialogDrag" }
              ],
              attrs: {
                title: "导入货妥",
                "close-on-click-modal": false,
                "close-on-press-escape": false,
                visible: _vm.importDialogFormVisible
              },
              on: {
                "update:visible": function($event) {
                  _vm.importDialogFormVisible = $event
                }
              }
            },
            [
              _vm.importType == "vbc"
                ? _c("upload-excel-component", {
                    attrs: {
                      "on-success": _vm.importVBCExcelSuccess,
                      "before-upload": _vm.importExcelBeforeUpload
                    }
                  })
                : _c("upload-excel-component", {
                    attrs: {
                      "on-success": _vm.importExcelSuccess,
                      "before-upload": _vm.importExcelBeforeUpload
                    }
                  }),
              _vm._l(_vm.importCargoReadyList, function(item, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "table_block clearfix mt30 mb30" },
                  [
                    _c("span", { staticClass: "tag inline_block blod mb10" }, [
                      _vm._v("订单编号：" + _vm._s(item.purchaseOrderId))
                    ]),
                    _c(
                      "el-table",
                      {
                        ref: "multipleTable",
                        refInFor: true,
                        staticClass: "table_list",
                        attrs: { data: item.poiList, border: "", fit: "" }
                      },
                      [
                        _c("el-table-column", {
                          attrs: { type: "expand" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c(
                                      "el-table",
                                      {
                                        ref: "multipleTable",
                                        refInFor: true,
                                        staticClass: "table_list",
                                        attrs: {
                                          data: scope.row.readyList,
                                          border: "",
                                          fit: "",
                                          "row-class-name":
                                            _vm.tableRowClassName
                                        }
                                      },
                                      [
                                        _c("el-table-column", {
                                          attrs: { label: "确认书号" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function(ref) {
                                                  var row = ref.row
                                                  return [
                                                    _vm._v(
                                                      "\n                      " +
                                                        _vm._s(
                                                          row.confirmNumber
                                                        ) +
                                                        "\n                    "
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        }),
                                        _c("el-table-column", {
                                          attrs: { label: "货妥数量" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function(ref) {
                                                  var row = ref.row
                                                  return [
                                                    row.purchaseMeasurementUnit ===
                                                    "米"
                                                      ? _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              (row.readyBagQuantity
                                                                ? row.readyBagQuantity
                                                                : "0") +
                                                                "包 " +
                                                                (row.confirmQuantityPerPackage
                                                                  ? row.confirmQuantityPerPackage
                                                                  : "0") +
                                                                "米/包 " +
                                                                (parseFloat(
                                                                  row.readyBagQuantity
                                                                ) *
                                                                parseFloat(
                                                                  row.confirmQuantityPerPackage
                                                                )
                                                                  ? parseFloat(
                                                                      row.readyBagQuantity
                                                                    ) *
                                                                    parseFloat(
                                                                      row.confirmQuantityPerPackage
                                                                    )
                                                                  : "0") +
                                                                "米"
                                                            )
                                                          )
                                                        ])
                                                      : _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              (row.readyMeter
                                                                ? row.readyMeter
                                                                : "0") +
                                                                (row.purchaseMeasurementUnit
                                                                  ? row.purchaseMeasurementUnit
                                                                  : "条")
                                                            )
                                                          )
                                                        ])
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        }),
                                        _c("el-table-column", {
                                          attrs: { label: "货妥时间" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function(ref) {
                                                  var row = ref.row
                                                  return [
                                                    _vm._v(
                                                      "\n                      " +
                                                        _vm._s(row.readyDate) +
                                                        "\n                    "
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        }),
                                        _c("el-table-column", {
                                          attrs: { label: "是否可发运" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function(ref) {
                                                  var row = ref.row
                                                  return [
                                                    _vm._v(
                                                      "\n                      " +
                                                        _vm._s(
                                                          row.ableShipments
                                                            ? "是"
                                                            : "否"
                                                        ) +
                                                        "\n                    "
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        }),
                        _c("el-table-column", {
                          attrs: { label: "PO行号" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var row = ref.row
                                  return [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(row.purchaseOrderSn) +
                                        "\n              "
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "物料编号",
                            "min-width": "180",
                            "class-name": "marpno_wuliao",
                            "show-overflow-tooltip": true
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var row = ref.row
                                  return [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(row.materialCode) +
                                        "\n              "
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        }),
                        _c("el-table-column", {
                          attrs: { label: "工厂交期" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var row = ref.row
                                  return [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(row.offeredDate) +
                                        "\n              "
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        }),
                        _c("el-table-column", {
                          attrs: { label: "操作" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var row = ref.row
                                  var $index = ref.$index
                                  return [
                                    _c("el-button", {
                                      staticClass: "el-icon-plus",
                                      attrs: {
                                        type: "success",
                                        circle: "",
                                        size: "mini",
                                        title: "新增货妥"
                                      },
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          return _vm.submitAddHandle(
                                            "import",
                                            $index,
                                            row
                                          )
                                        }
                                      }
                                    })
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              }),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        { name: "preventReClick", rawName: "v-preventReClick" }
                      ],
                      attrs: {
                        type: "success",
                        disabled: _vm.importSubmitDisabled
                      },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.importSubmitHandle($event)
                        }
                      }
                    },
                    [_vm._v("批量新增货妥")]
                  )
                ],
                1
              )
            ],
            2
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: {
            title: "提示",
            visible: _vm.dialogdelVisible,
            "close-on-click-modal": false,
            width: "30%"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogdelVisible = $event
            }
          }
        },
        [
          _c("span", [_vm._v("确定删除该批货妥记录 ?")]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogdelVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    { name: "preventReClick", rawName: "v-preventReClick" }
                  ],
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.delVisibleclick()
                    }
                  }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }